import React, { Component } from "react";
import "./HourlyChargingReservation.css";
import Timer from "react-compound-timer";
import PriceInfoList from "../layout/list/PriceInfoList";
import Toolbar from "../layout/toolbar/Toolbar";
import { Redirect } from "react-router-dom";
import ConfirmEndSessionPopup from "../layout/popup/ConfirmEndSessionPopup";
import ConfirmChargingGunPopup from "../layout/popup/ConfirmChargingGunPlugInPopup";
import { connect } from "react-redux";
import { compose } from "redux";
import { endSessionAction, resetEndSessionResultStateAction, changeEVChargingStateAction, resetChangeEVChargingStateResultStateAction } from "../../store/actions/ReservationPostActions";
import {monitorBarrierStatusAction, monitorEVChargingStatusAction} from "../../store/actions/RealtimeDatabaseActions"
import Colors from "../../constants/Colors";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import LoadingPopup from "../layout/popup/LoadingPopup";

import { reactLocalStorage } from "reactjs-localstorage";
import StorageKeys from "../../constants/LocalStorageKeys";

class HourlyChargingReservation extends Component {
  state = {
    isConfirmEndSessionPopupShown: false,
    isEndSessionLoadingPopupShown: false,
    isBackButtonClicked: false,
    isErrorPopupShown: false,
    errorMessage:'',

    isMonitorBarrierStateTriggered:false,
    isMonitorEVChargingStateTriggered:false,
    isConfirmChargingGunPlugInPopupShown: false,
    isEVChargingStarted: false,
    isEVChargingStateFirebaseRetrieved: false,

    isStartChargingLoadingPopupShown:false,
    isStopChargingLoadingPopupShown:false,
    
    evChargingButtonText : "Start Charging",
    evChargingButtonColor : Colors.green,
  };

  componentDidMount = () =>{
    this.setState({isMonitorEVChargingStateTriggered:true});
  }

  handleConfirmEndSessionButtonClicked = (reservationId) => {
    this.setState({ isConfirmEndSessionPopupShown: false });
    this.setState({ isEndSessionLoadingPopupShown: true });
    this.setState({isPostResultGranted:false});
    //post to server
    this.props.endSession(reservationId);
  };

  handleEVChargingButtonClicked = (reservationId) => {

    console.log('this.state.isEVChargingStarted : ' + this.state.isEVChargingStarted);
    if(this.state.isEVChargingStarted){
      this.setState({isStopChargingLoadingPopupShown: true});
      this.props.changeEVChargingState(reservationId, "false");
    }else{
     
      this.setState({isConfirmChargingGunPlugInPopupShown : true});
    }
 
  }

  handleChargingGunPlugInConfirmButtonClicked = (reservationId) => {
    this.props.changeEVChargingState(reservationId, "true");
    this.setState({isStartChargingLoadingPopupShown : true});
    this.setState({isConfirmChargingGunPlugInPopupShown: false});
    
  }


  displayStartChargingButton = () =>{

    this.setState({isEVChargingStarted: false});
    this.setState({evChargingButtonText : 'Start Charging'});
    this.setState({evChargingButtonColor :  Colors.green});
    
    
  }
  
  displayStopChargingButton = () => {
    this.setState({isEVChargingStarted: true});
    this.setState({evChargingButtonText : 'Stop Charging'});
    this.setState({evChargingButtonColor :  Colors.red});
   
  }

  render() {
    //from Redirect
    const currentReservationId = this.props.location.state.ticketInfo.ReservationId;
    var reservationInfo = [];
    
     //retrieve ticket list from local storage
     var ticketArray = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);

     ticketArray &&
     ticketArray.map((t) => {

      if(t.ReservationId === currentReservationId){
        reservationInfo = t;
      }

     });



    //from reducer
    const { endSessionSuccessResult, endSessionErrorResult, changeEVChargingStateSuccessResult, changeEVChargingStateErrorResult, barrierState, evChargingState } = this.props;

    let toolbar = (
      <Toolbar
        title="Hourly Charging"
        leftNavItemType="back-button"
        toolbarBackButtonOnClick={() =>
          this.setState({ isBackButtonClicked: true })
        }
      />
    );

    var activationTime = Number(reservationInfo.ActivationTimestamp);
    //console.log("activationTime : " + activationTime);
    var startingTime = Date.now() - activationTime;
    //console.log("startingTime : " + startingTime);
    let countUpTimer = (
      <Timer initialTime={startingTime} direction="forward">
        {() => (
          <React.Fragment>
            <Timer.Days />d {<span>&nbsp;</span>}
            <Timer.Hours />h {<span>&nbsp;</span>}
            <Timer.Minutes />m {<span>&nbsp;</span>}
            <Timer.Seconds />s {<span>&nbsp;</span>}
          </React.Fragment>
        )}
      </Timer>
    );

    let routeToRequestParkingPage;
    let routeToHourlyTicketSummaryPage;

    if (this.state.isBackButtonClicked) {
      this.setState({ isBackButtonClicked: false });

      //route to request parking page
      routeToRequestParkingPage = (
        <Redirect
          to={{
            pathname: "/RequestParking",
          }}
        />
      );
    }

    let endSessionLoadingPopup = (
      <LoadingPopup
        show={this.state.isEndSessionLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Ending session ... "
      />
    );

    let startChargingLoadingPopup = (
      <LoadingPopup
        show={this.state.isStartChargingLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Start charging ... "
      />
    );

    let stopChargingLoadingPopup = (
      <LoadingPopup
        show={this.state.isStopChargingLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Stop charging ... "
      />
    );

   
    let errorPopup = (
      <OneButtonPopup
        show={this.state.isErrorPopupShown}
        title="Error"
        message={this.state.errorMessage}
        buttonText="Okay, got it."
        onButtonClick={() => {
          this.setState({ isErrorPopupShown: false });
        }}
      />
    );

   
    //end session post result
    if (endSessionSuccessResult) {
      if (endSessionSuccessResult.Status === "Success") {

        const ticketArray = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);
        ticketArray &&
        ticketArray.map((t) => {
         
          if(t.ReservationId === reservationInfo.ReservationId){
            //go to hourly summary page
        routeToHourlyTicketSummaryPage = (
          <Redirect
          to={{
            pathname: "/HourlyTicketParkingSummary",
            reservationInfo: t
          }}
        />
        );
          }
        });

        
      } else {
        console.log('endSessionSuccessResult error code : ' + endSessionSuccessResult.ErrorCode);
        this.setState({ isEndSessionLoadingPopupShown: false });
        //show error popup
        this.setState({ isErrorPopupShown: true });
        this.setState({errorMessage:endSessionSuccessResult.Message});
        this.setState({isMonitorBarrierStateTriggered:true});
      }

      this.props.resetEndSessionState();
    }

    if(endSessionErrorResult){
      this.setState({ isEndSessionLoadingPopupShown: false });
       //show error popup
       this.setState({ isErrorPopupShown: true });
       this.setState({errorMessage:endSessionErrorResult});

       this.props.resetEndSessionState();
    }


    //change ev charging state post result
    if(changeEVChargingStateSuccessResult){
      
        console.log('changeEVChargingStateSuccessResult.Status : ' + changeEVChargingStateSuccessResult.Status);
        this.setState({isStartChargingLoadingPopupShown : false});
        this.setState({isStopChargingLoadingPopupShown:false});

        if(changeEVChargingStateSuccessResult.Status === 'Success'){

        

         if(this.state.isEVChargingStarted){
          
          this.displayStartChargingButton();
         }else{
         
          this.displayStopChargingButton();


         }
        }else{
          //do nothing, remain the same
        }

        this.props.resetChangeEVChargingState();
    
    }

    if(changeEVChargingStateErrorResult){

      this.setState({isStartChargingLoadingPopupShown : false});
      this.setState({isStopChargingLoadingPopupShown:false});

      if(this.state.isEVChargingStarted){
        this.displayStopChargingButton();
     }else{
      this.displayStartChargingButton();
     }

      this.props.resetChangeEVChargingState();
    }


    if(this.state.isMonitorBarrierStateTriggered){
      this.setState({isMonitorBarrierStateTriggered:false});
      if(reservationInfo.ReferenceLocationId !== "na"){
        this.props.monitorBarrierStatus(reservationInfo.ReferenceLocationId, reservationInfo.BayId, reservationInfo.Level);
      }else{
        this.props.monitorBarrierStatus(reservationInfo.MallId, reservationInfo.BayId, reservationInfo.Level);
      }
      
    }

    if(this.state.isMonitorEVChargingStateTriggered){
      console.log('isMonitorEVChargingStateTriggered');
      this.setState({isMonitorEVChargingStateTriggered:false});
      this.props.monitorEVChargingStatus(reservationInfo.MallId, reservationInfo.BayId, reservationInfo.Level);
    }

    let confirmEndSessionPopupShown = (
      <ConfirmEndSessionPopup
        show={this.state.isConfirmEndSessionPopupShown}
        onConfirmButtonClicked={() =>
          this.handleConfirmEndSessionButtonClicked(
            reservationInfo.ReservationId
          )
        }
        onCancelButtonClicked={() =>
          this.setState({ isConfirmEndSessionPopupShown: false })
        }
      />
    );

    let confirmChargingGunPlugInPopupShown = (
      <ConfirmChargingGunPopup
      show={this.state.isConfirmChargingGunPlugInPopupShown}
      onConfirmButtonClicked={() =>
        this.handleChargingGunPlugInConfirmButtonClicked(reservationInfo.ReservationId)
      }
      onCancelButtonClicked={() =>
        this.setState({ isConfirmChargingGunPlugInPopupShown: false })
      }
    />
    );


    //firebase --- barrier status
    if(barrierState){
      console.log('barrier state : ' + barrierState);
      if(barrierState === 'up'){

        this.props.endSession(reservationInfo.ReservationId);

      }
    }

    //firebase --- ev charging status
    if(evChargingState && !this.state.isEVChargingStateFirebaseRetrieved){
     
      console.log('evChargingState : ' , evChargingState);

      this.setState({isEVChargingStateFirebaseRetrieved:true});

      if(evChargingState === "true"){
        //show stop charging button
        this.displayStopChargingButton();
      }else{
        //show start charging button
       this.displayStartChargingButton();
      }
    }

    

    return (
      <div className="hourly_charging">
        {toolbar}
        {routeToRequestParkingPage}
        {confirmEndSessionPopupShown}
        {endSessionLoadingPopup}
        {errorPopup}
        {routeToHourlyTicketSummaryPage}
        {confirmChargingGunPlugInPopupShown}
        {startChargingLoadingPopup}
        {stopChargingLoadingPopup}

        <div className="hourly_charging--area_name">
          {reservationInfo.AreaName}
        </div>
        <div className="hourly_charging--zone_name">
          {reservationInfo.LocationName}
        </div>

        <div className="hourly_charging--bay_time_container">
          <div className="hourly_charging--bay_time_container--bay_signage">
            <div
              className="hourly_charging--bay_time_container--bay_signage--image"
              style={{ backgroundImage: `url(${reservationInfo.BayImage})` }}
            ></div>
          </div>

          <div className="hourly_charging--bay_time_container--time_elapsed">
            <div className="hourly_charging--bay_time_container--time_elapsed--label">
              Time Elapsed:
            </div>
            <div className="hourly_charging--bay_time_container--time_elapsed--timer">
              {countUpTimer}
            </div>
          </div>
        </div>

        <div className="hourly_charging--price_table">
          <div className="hourly_charging--price_table--title">
            Charges Info :
          </div>

          <PriceInfoList
            isExpandableMode={false}
            reservationInfo={reservationInfo}
            selectedZoneId={reservationInfo.MallId}
          />
        </div>

        {(reservationInfo.LocationServiceType === 'EVCharging')&&
          (<div className="hourly_charging--ev_charging_button"
          style={{backgroundColor:this.state.evChargingButtonColor}}
          onClick={() => this.handleEVChargingButtonClicked(reservationInfo.ReservationId)}>
      <div className="hourly_charging--ev_charging_button--label">
        {this.state.evChargingButtonText}
      </div>
    </div>)
        }
        

        <div
          className="hourly_charging--end_session_button"
          onClick={() => this.setState({ isConfirmEndSessionPopupShown: true })}
        >
          <div className="hourly_charging--end_session_button--label">
            End Session
          </div>
        </div>




      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    endSessionSuccessResult: state.reservationPost.endSessionSuccessResult,

    endSessionErrorResult: state.reservationPost.endSessionErrorResult,

    changeEVChargingStateSuccessResult: state.reservationPost.changeEVChargingStateSuccessResult,

    changeEVChargingStateErrorResult: state.reservationPost.changeEVChargingStateErrorResult,

    barrierState: state.realtimeDatabase.barrierState,

    evChargingState: state.realtimeDatabase.evChargingState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    monitorBarrierStatus: (zoneId, bayId, level) => dispatch(monitorBarrierStatusAction(zoneId, bayId, level)),

    monitorEVChargingStatus: (zoneId, bayId, level) => dispatch(monitorEVChargingStatusAction(zoneId, bayId, level)),

    endSession: (reservationId) => dispatch(endSessionAction(reservationId)),

    resetEndSessionState: () => dispatch(resetEndSessionResultStateAction()),

    changeEVChargingState: (reservationId, chargingState) => dispatch(changeEVChargingStateAction(reservationId, chargingState)), 

    resetChangeEVChargingState: () =>dispatch(resetChangeEVChargingStateResultStateAction())
  };


};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HourlyChargingReservation
);
