import React, { Component } from "react";
import "./Help.css";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import Toolbar from "../layout/toolbar/Toolbar";
import Backdrop from "../layout/backdrop/Backdrop";

class Help extends Component {

    state = {
       
        isSideDrawerOpened: false,
    
      };
    


      handleBackdropClicked = () => {
        this.setState({ isSideDrawerOpened: false });
      };
    
      handleDrawerToggleClicked = () => {
        //better practice - function form
        //pass a function to set state
        this.setState((prevState) => {
          return { isSideDrawerOpened: !prevState.sideDrawerOpen };
        });
      };

      handleCallButtonClicked = () =>{
        window.open('tel:+60162991468');
      }

      handleFaqButtonClicked = () =>{
        window.open('https://parkeasy.co/faq','_blank');
    }

    handleEmailButtonClicked = () =>{
        window.open('mailto:feedback@parkeasy.co');
    }


  render() {

    let backdrop;

    let toolbar = (
      <Toolbar
        title="Help"
        leftNavItemType="toggle-button"
        drawerClickHandler={this.handleDrawerToggleClicked}
      />
    );

    if (this.state.isSideDrawerOpened) {
        backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
      }

    return (
      <div className="help">
      <SideDrawer show={this.state.isSideDrawerOpened} />
     
      {toolbar}
      {backdrop}

        <div className="help--image" />
        <div className="help--label">Need help urgently?</div>

        <div className="help--call_button" onClick={this.handleCallButtonClicked}>
          <div className="help--call_button--label ">Call us</div>
        </div>

        <div className="help--label">Or,</div>

        <div className="help--faq_button" onClick={this.handleFaqButtonClicked}>
          <div className="help--faq_button--label ">Read our FAQ</div>
        </div>

        <div className="help--email_button" onClick={this.handleEmailButtonClicked}>
          <div className="help--email_button--label ">
            Email us at, feedback@parkeasy.co
          </div>
        </div>

      </div>
    );
  }
}
export default Help;
