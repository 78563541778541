import React, { Component } from 'react';
import './InsufficientCreditsPopup.css';

class InsufficientCreditsPopup extends Component {

  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {

    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    } else {
      mainContainerLeft = "38%";
      mainContainerRight = "38%";
      mainContainerTop = "20%";
      mainContainerBottom = "20%";
    }

    if (this.props.show) {
    return (
      <div className='insufficient_credits_popup'>
        <div className='insufficient_credits_popup--main_container'
        style={{
          left: mainContainerLeft,
          right: mainContainerRight,
          top: mainContainerTop,
          bottom: mainContainerBottom,
        }}
        >

          <div className='insufficient_credits_popup--title'>
            <div className='insufficient_credits_popup--title--label'>
            Oh no!
            </div>
          </div>

          <div className='insufficient_credits_popup--image'/>

          <div className='insufficient_credits_popup--info'>
            You don't have enough credits to make a reservation. Please add credits and try again?
          </div>

          <div className='insufficient_credits_popup--buttons_container--add_credits' onClick={this.props.onAddCreditsButtonClicked}>
            <div className='insufficient_credits_popup--buttons_container--add_credits--label'>
              Add credits now
            </div>
          </div>

        <div className='insufficient_credits_popup--buttons_container--dismiss' onClick={this.props.onDismissButtonClicked}>
          <div className='insufficient_credits_popup--buttons_container--dismiss--label'>
            No thanks
          </div>
        </div>

        </div>
      </div>
    );
    }else{
      return null;
    }
  }
}

export default InsufficientCreditsPopup;