import React, { Component } from "react";
import "./AboutUs.css";
import Toolbar from "../layout/toolbar/Toolbar";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import Backdrop from "../layout/backdrop/Backdrop";

class AboutUs extends Component {
  state = {
    isSideDrawerOpened: false,
  };

  handleCompanyWebsiteHyperlinkOnClicked = () => {
    var url = "https://www.parkeasy.co";
    var win = window.open(url, "_blank");
    win.focus();
  };

  handleTermsHyperlinkOnClicked = () => {
    var url = "https://www.parkeasy.co/terms";
    var win = window.open(url, "_blank");
    win.focus();
  };

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.sideDrawerOpen };
    });
  };

  handleBackdropClicked = () => {
    this.setState({ isSideDrawerOpened: false });
  };

  render() {
    let backdrop;

    let toolbar = (
      <Toolbar
        title="About Us"
        leftNavItemType="toggle-button"
        drawerClickHandler={this.handleDrawerToggleClicked}
      />
    );

    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

    return (
      <div className="about_us">
        {toolbar}
        {backdrop}
        <SideDrawer show={this.state.isSideDrawerOpened} />
        <div className="about_us--space" />

        <div className="about_us--logo" />

        <div className="about_us--space" />

        <div className="about_us--version_no">v1.0.6</div>

        <div className="about_us--space" />

        <div className="about_us--content">
          We all know that life is too short to be spent on parking. ParkEasy
          lets you park quickly, so that you won't need to freakin' waste half
          your life searching for parking.
        </div>

        <div className="about_us--space" />

        <div className="about_us--content">
          We are currently operating in Kuala Lumpur, with plans to expand
          regionally. We promise to help make your #ParkingAwesome of course.
        </div>

        <div className="about_us--space" />

        <div className="about_us--content">Check us out:</div>

        <div
          className="about_us--hyperlink"
          onClick={this.handleCompanyWebsiteHyperlinkOnClicked}
          style={{ textAlign: "left" }}
        >
          www.parkeasy.co
        </div>

        <div className="about_us--space" />

        <div
          className="about_us--hyperlink"
          onClick={this.handleTermsHyperlinkOnClicked}
          style={{
            textAlign: "center",
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "56px",
          }}
        >
          Terms and Conditions
        </div>
      </div>
    );
  }
}

export default AboutUs;
