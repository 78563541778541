
const initState = {
  createBillSuccessResult: null,
  createBillErrorResult:null,
  retrievePaymentMethodsSuccessResult:null,
  retrievePaymentMethodsErrorResult:null,
  createPaymentIntentSuccessResult: null,
  createPaymentIntentErrorResult: null,
  saveCardSuccessResult: null,
  saveCardErrorResult: null,
  getSSTRateSuccessResult: null,
  getSSTRateErrorResult: null,

}


const PaymentReducer = (state = initState, action) => {
  switch(action.type){

    case 'CREATE_BILL_SUCCESS':
      console.log('create bill success', action.result);
      return {
        ...state,
        createBillSuccessResult : action.result
      };

    case 'CREATE_BILL_ERROR':
      console.log('create bill error');
      return {
        ...state,
        createBillErrorResult: action.error
      }

      case 'RESET_CREATE_BILL_RESULT_STATE':
        return {
          ...state,
           createBillSuccessResult: null,
          createBillErrorResult:null,
        }

        case 'RETRIEVE_PAYMENT_METHODS_SUCCESS':
          return{
            ...state,
            retrievePaymentMethodsSuccessResult:action.result
          }

        case 'RETRIEVE_PAYMENT_METHODS_ERROR':
          return{
            ...state,
            retrievePaymentMethodsErrorResult: action.error
          }

        case 'RESET_RETRIEVE_PAYMENT_METHODS_RESULT_STATE':
          return {
            ...state,
            retrievePaymentMethodsSuccessResult: null,
            retrievePaymentMethodsErrorResult:null,
          }

          case 'CREATE_PAYMENT_INTENT_SUCCESS':
            console.log('create payment intent success', action.result);
            return {
              ...state,
              createPaymentIntentSuccessResult : action.result
            };
      
          case 'CREATE_PAYMENT_INTENT_ERROR':
            console.log('create payment intent error');
            return {
              ...state,
              createPaymentIntentErrorResult: action.error
            }
      
            case 'RESET_CREATE_PAYMENT_INTENT_RESULT_STATE':
              return {
                ...state,
                 createPaymentIntentSuccessResult: null,
                createPaymentIntentErrorResult:null,
              }

            case 'SAVE_CARD_SUCCESS':
              console.log('save card success', action.result);
              return {
                ...state,
                saveCardSuccessResult : action.result
              };
          
            case 'SAVE_CARD_ERROR':
              console.log('save card error');
              return {
                ...state,
                saveCardErrorResult: action.error
              }
          
            case 'RESET_SAVE_CARD_RESULT_STATE':
              return {
                ...state,
                saveCardSuccessResult: null,
                saveCardErrorResult:null,
              }

              case 'GET_SST_RATE_SUCCESS':
              console.log('GET_SST_RATE_SUCCESS', action.result);
              return {
                ...state,
                getSSTRateSuccessResult : action.result
              };

              case 'GET_SST_RATE_ERROR':
                console.log('GET_SST_RATE_ERROR');
                return {
                  ...state,
                  getSSTRateErrorResult: action.error
                }
            
              case 'RESET_GET_SST_RATE_RESULT_STATE':
                return {
                  ...state,
                  getSSTRateSuccessResult: null,
                  getSSTRateErrorResult:null,
                }
           
        


    default:
      return state
  }
};






export default PaymentReducer;