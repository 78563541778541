const PARKEASY_DOMAIN = 'https://production.parkeasy.cc/';
// const PARKEASY_DOMAIN = 'https://sandbox.parkeasy.cc/';

const Url = {
  SIGN_IN : PARKEASY_DOMAIN +'signInJSON',
  SIGN_UP : PARKEASY_DOMAIN + 'signUpJSON',
  SIGN_OUT : PARKEASY_DOMAIN + 'signOutJSON',
  MAKE_RESERVATION : PARKEASY_DOMAIN + 'makeReservationJSON',
  ACTIVATE_RESERVATION : PARKEASY_DOMAIN + 'reservationActivateJSON',
  CANCEL_RESERVATION : PARKEASY_DOMAIN + 'cancelReservation',
  REPORT_PARKING_SUCCESSFUL : PARKEASY_DOMAIN + 'reservationSuccessJSON',
  FEEDBACK_CANCEL_RESERVATION : PARKEASY_DOMAIN + 'feedbackCancelReservation',
  END_SESSION : PARKEASY_DOMAIN + 'manualEndSessionJSON',
  SYNC_RESERVED_TICKETS : PARKEASY_DOMAIN + 'reservationSyncJSON',
  MAKE_PAYMENT : PARKEASY_DOMAIN + 'purchaseCredits',
  GET_GENERAL_LOCATION_LIST : PARKEASY_DOMAIN + 'retrieveLocationList',
  GET_CUSTOM_LOCATION_LIST : PARKEASY_DOMAIN + 'retrieveCustomLocationList',
  SYNC_CREDITS_BALANCE : PARKEASY_DOMAIN + 'userCreditBalanceJSON2',
  CLAIM_PROMO_CODE : PARKEASY_DOMAIN + "claimPromoCodeJSON2",
  REQUEST_SMS_VERIFICATION : PARKEASY_DOMAIN + "requestSmsVerification",
  RETRIEVE_PAYMENT_METHODS : PARKEASY_DOMAIN + "retrievePaymentMethods",
  SAVE_CARD : PARKEASY_DOMAIN + "saveCard",
  CHANGE_EV_CHARGING_STATE : PARKEASY_DOMAIN + "changeEVChargingStateJSON",
  GET_SST_RATE : PARKEASY_DOMAIN + "retrieveSSTChargeJSON"
};

export default Url;