const initState = {
  syncCreditsBalanceSuccessResult: null,
  syncCreditsBalanceErrorResult: null,
  claimPromoCodeSuccessResult: null,
  claimPromoCodeErrorResult: null,
}

const authReducer = (state = initState, action) => {
  switch(action.type){

   

      case 'SYNC_CREDITS_BALANCE_SUCCESS':
      console.log('syncCreditsBalanceSuccessResult', action.result)
      return {
        ...state,
        syncCreditsBalanceSuccessResult : action.result
      }

    case 'SYNC_CREDITS_BALANCE_ERROR':
      console.log('syncCreditsBalanceErrorResult', action.error)
      return {
        ...state,
        syncCreditsBalanceErrorResult: action.error
      }

      case 'RESET_SYNC_CREDITS_BALANCE_STATE':
        return {
          syncCreditsBalanceSuccessResult: null,
          syncCreditsBalanceErrorResult:null,
        }

        case 'CLAIM_PROMO_CODE_SUCCESS':
          console.log('CLAIM_PROMO_CODE_SUCCESS', action.result)
          return {
            ...state,
            claimPromoCodeSuccessResult : action.result
          }
    
        case 'CLAIM_PROMO_CODE_ERROR':
          console.log('CLAIM_PROMO_CODE_ERROR', action.error)
          return {
            ...state,
            claimPromoCodeErrorResult: action.error
          }

          
      case 'RESET_CLAIM_PROMO_CODE_STATE':
        return {
          claimPromoCodeSuccessResult: null,
          claimPromoCodeErrorResult:null,
        }
        
   

    

    default:
      return state
  }
};






export default authReducer;