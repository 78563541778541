const initState = {
    reservations:[]
}

const ReservationReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_RESERVATION':
            console.log('created reservation', action.reservation );
            return state;

        case 'CREATE_RESERVATION_ERROR':
            console.log('created reservation ERROR', action.error );
            return state;

        case 'RETRIEVE_RESERVATION':
            console.log('retrieved reservation', action.snapshot );
            return state;

        case 'RETRIEVE_RESERVATION_ERROR':
            console.log('retrieved reservation ERROR', action.error );
            return state;

        case 'DELETE_RESERVATION':
            console.log('deleted reservation', action.docId );
            return state;

        case 'DELETE_RESERVATION_ERROR':
            console.log('deleted reservation ERROR', action.error );
            return state;
        default : 
            return state;
    }
}

export default ReservationReducer