
const Colors = {
	red : '#FF0000',
	green : '#00BA64',
	blue : '#38399C',
	purple : '#4A156A',
	background_grey : '#EFF0F1FF',
	border_grey : '#BBBDC0',
	text_grey : '#4A4A4A',
	background_purple : '#EDE7EF',
};

export default Colors;