import React, { Component } from "react";

import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

import "./ActivateReservation.css";

import Toolbar from "../layout/toolbar/Toolbar";

import Timer from "react-compound-timer";
import ReservationStatus from "../../constants/ReservationStatus";
import Colors from "../../constants/Colors";

import {
  activateReservationAction,
  cancelReservationAction,
  reportParkingSuccessfulAction,
  resetActivateReservationResultStateAction,
  resetCancelReservationResultStateAction
} from "../../store/actions/ReservationPostActions";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import TwoButtonsPopup from "../layout/popup/TwoButtonsPopup";
import ReportParkingSuccessPopup from "../layout/popup/ReportParkingSuccessPopup";
import LoadingPopup from "../layout/popup/LoadingPopup";
import CancelReservationFeedbackPopup from "../layout/popup/CancelReservationFeedbackPopup";
import NavigateToEntrancePopup from "../layout/popup/NavigateToEntrancePopup";

class ActivateReservation extends Component {
  state = {
    width: window.innerWidth,

    isBackButtonClicked: false,
    isUnlockBarrierButtonClicked: false,
    isErrorPopupDismissButtonClicked: false,
    isCancelReservationButtonClicked: false,
    isCancellationConfirmButtonClicked: false,
    isCancelReservationDismissButtonClicked: false,
    isNavigateMeButtonClicked:false,
    isNotParkedButtonClicked: false,
    isParkedButtonClicked: false,

    isActivateReservationLoadingPopupShown: false,
    isCancelReservationLoadingPopupShown: false,
    isCancelReservationSuccessPopupShown: false,
    isConfirmCancelReservationPopupShown: false,
    isCancelReservationFeedbackPopupShown: false,
    isNavigateToEntrancePopupShown: true,
    isCancelReservationErrorPopupShown: false,
    isActivateReservationErrorPopupShown: false,
    isReportParkingSuccessPopupShown: false,
    isConfirmUnlockBarrierPopupShown: false,
    

  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleToolbarBackButtonClicked = () => {
    this.setState({ isBackButtonClicked: true });
  };

  handleUnlockBarrierButtonClicked = () => {
    this.setState({ isUnlockBarrierButtonClicked: true });
    this.setState({ isErrorPopupDismissButtonClicked: false });
  };

  handleDismissErrorPopupButtonClicked = () => {
    this.setState({ isErrorPopupDismissButtonClicked: true });
  };

  handleCancelReservationButtonClicked = () => {
    this.setState({ isCancelReservationButtonClicked: true });
  };

  handleCancelReservationDimissButtonClicked = () => {
    this.setState({ isCancelReservationDismissButtonClicked: true });
  };

  handleCancellationConfirmButtonClicked = () => {
    this.setState({ isCancellationConfirmButtonClicked: true });
  };

  handleHelpButtonClicked = () => {};

  handleNavigationButtonClicked = (lat, lng) => {
    //show popup to choose waze or google
    this.setState({isNavigateMeButtonClicked:true});
    //window.open("https://maps.google.com?q=" + lat + "," + lng);
    window.open("https://www.waze.com/ul?ll=" + lat + "%2C" + lng + "&navigate=yes&zoom=17");
  };

  handleNavigateToEntrancePopupDimissButtonClicked = () => {
    this.setState({ isNavigateToEntrancePopupShown: false });
  };

  handleNavigateMeButtonClicked = (lat, lng) => {
    this.setState({ isNavigateToEntrancePopupShown: false });
   // window.open("https://maps.google.com?q=" + lat + "," + lng);

    window.open("https://www.waze.com/ul?ll=" + lat + "%2C" + lng + "&navigate=yes&zoom=17");
  };

  handleTimesUp = () => {
    //route to parking summary page
    //check ticket type
  };

  handleNotParkedButtonClicked = () => {
    this.setState({ isNotParkedButtonClicked: true });
  };

  handleParkedButtonClicked = () => {
    this.setState({ isParkedButtonClicked: true });
  };

  openDialScreen = () => {
    window.open('tel:+60162991468');
  
  };

  render() {
    //const { width } = this.state;
    

  
     //from Redirect
     const reservationInfo = this.props.location.state.ticketInfo;


    //from reducers
    const {
      activateReservationErrorResult,
      activateReservationSuccessResult,
      cancelReservationSuccessResult,
      cancelReservationErrorResult,
    } = this.props;

    let routeToRequestParkingPage;
    let routeToOneOffParkingSummaryPage;
    let routeToHourlyChargingPage;

    let navigateToEntrancePopup = (
      <NavigateToEntrancePopup
        show={this.state.isNavigateToEntrancePopupShown}
        reservationInfo={reservationInfo}
        onNavigateMeButtonClicked={() =>
          this.handleNavigateMeButtonClicked(
            reservationInfo.EntranceLat,
            reservationInfo.EntranceLng
          )
        }
        onDismissButtonClicked={
          this.handleNavigateToEntrancePopupDimissButtonClicked
        }
      />
    );

    var activateReservationErrorMessage;
    let activateReservationErrorPopup = (
      <OneButtonPopup
        show={this.state.isActivateReservationErrorPopupShown}
        title="Error"
        message={activateReservationErrorMessage}
        buttonText="Okay, got it."
        onButtonClick={() =>
          this.setState({ isActivateReservationErrorPopupShown: false })
        }
      />
    );

    let reportParkingSuccessPopup = (
      <ReportParkingSuccessPopup
        show={this.state.isReportParkingSuccessPopupShown}
        baySignage={reservationInfo.BayImage}
        onCancelButtonClicked={this.handleNotParkedButtonClicked}
        onParkedButtonClicked={this.handleParkedButtonClicked}
      />
    );

    let activateReservationLoadingPopup = (
      <LoadingPopup
        show={this.state.isActivateReservationLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Unblocking barrier ... "
      />
    );

    let cancelReservationLoadingPopup = (
      <LoadingPopup
        show={this.state.isCancelReservationLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Cancelling reservation ... "
      />
    );

    let cancelReservationFeedbackPopup = (
      <CancelReservationFeedbackPopup
        show={this.state.isCancelReservationFeedbackPopupShown}
        reservationId={reservationInfo.ReservationId}
      />
    );

    let confirmCancelReservationPopup = (
      <TwoButtonsPopup
        show={this.state.isConfirmCancelReservationPopupShown}
        title="Confirm cancellation"
        message="You will get a full refund if your cancellation is within 1 minute of your booking time."
        leftButtonText="Go back"
        rightButtonText="Confirm"
        onLeftButtonClick={this.handleCancelReservationDimissButtonClicked}
        onRightButtonClick={this.handleCancellationConfirmButtonClicked}
      />
    );

    var cancelReservationErrorMessage;
    let cancelReservationErrorPopup = (
      <OneButtonPopup
        show={this.state.isCancelReservationErrorPopupShown}
        title="Error"
        message={cancelReservationErrorMessage}
        buttonText="OK, got it."
        onButtonClick={() => {
          this.setState({ isCancelReservationErrorPopupShown: false });
        }}
      />
    );

    let confirmUnlockBarrierPopup = (
      <TwoButtonsPopup
        show={this.state.isConfirmUnlockBarrierPopupShown}
        title="Confirm Unlock Barrier"
        message="Are you sure to unlock barrier?"
        leftButtonText="Cancel"
        rightButtonText="Confirm"
        onLeftButtonClick={() =>
          this.setState({ isConfirmUnlockBarrierPopupShown: false })
        }
        onRightButtonClick={() => {
          this.setState({ isActivateReservationLoadingPopupShown: true });
          this.setState({ isConfirmUnlockBarrierPopupShown: false });
          this.setState({ isPostResultGranted: false });
          this.props.activateReservation(reservationInfo.ReservationId);
        }}
      />
    );

    let toolbar = (
      <Toolbar
        title="Reserved Bay"
        leftNavItemType="back-button"
        toolbarBackButtonOnClick={() => this.handleToolbarBackButtonClicked()}
      />
    );



    var bookingTimeInMilliseconds = Number(reservationInfo.ReservationTimestamp);
    var arriveWithinInMilliseconds = Number(reservationInfo.ArriveWithin);
    
    var timeLeft = bookingTimeInMilliseconds + arriveWithinInMilliseconds - Date.now();
    console.log("timeleft : " + timeLeft);

    let countdownTimer = (
      <Timer
        initialTime={timeLeft}
        direction="backward"
        checkpoints={[
          {
            time: 0,
            callback: () => this.handleTimesUp,
          },
        ]}
      >
        {() => (
          <React.Fragment>
            <Timer.Minutes />m {<span>&nbsp;</span>}
            <Timer.Seconds />s {<span>&nbsp;</span>}
          </React.Fragment>
        )}
      </Timer>
    );

    if (this.state.isBackButtonClicked) {
      routeToRequestParkingPage = (
        <Redirect
          to={{
            pathname: "/RequestParking"
          }}
        />
      );
    }

    if (this.state.isUnlockBarrierButtonClicked) {
      this.setState({ isUnlockBarrierButtonClicked: false });
      //show confirmation popup
      this.setState({ isConfirmUnlockBarrierPopupShown: true });
      console.log("unlock barrier button clicked");
    }

    if (this.state.isNotParkedButtonClicked) {
      this.setState({ isNotParkedButtonClicked: false });
      this.setState({ isReportParkingSuccessPopupShown: false });
    }

    if (this.state.isParkedButtonClicked) {
      this.setState({ isParkedButtonClicked: false });
      this.setState({ isReportParkingSuccessPopupShown: false });

      //post to server to report success
      this.props.reportParkingSuccessful(reservationInfo.ReservationId);
      
      //check ticket to route to corresponding next page
      if (reservationInfo.ChargingType === "one-off") {
        //go to parking summary page
        routeToOneOffParkingSummaryPage = (
          <Redirect
            to={{
              pathname: "/OneOffTicketParkingSummary",
              state: { reservationInfo: reservationInfo },
            }}
          />
        );
      } else if (reservationInfo.ChargingType === "hourly") {
        
        //go to hourly charging ticket page
        routeToHourlyChargingPage = <Redirect to={{
          pathname: "/HourlyCharging",
          state: { ticketInfo: reservationInfo },
        }} />;
      }
    }

    if(reservationInfo.Status === 'activated' && !this.state.isReportParkingSuccessPopupShown){
      //show report parking successful pop up
      this.setState({ isReportParkingSuccessPopupShown: true });

      //hide navigation popup
      this.setState({isNavigateToEntrancePopupShown : false});
    }

    if (activateReservationSuccessResult) {
     
      if (activateReservationSuccessResult.Status === "Success") {
        this.setState({ isActivateReservationLoadingPopupShown: false });
        //show report parking successful pop up
        this.setState({ isReportParkingSuccessPopupShown: true });
      } else {
        this.setState({ isActivateReservationLoadingPopupShown: false });
        //show error pop up
        this.setState({ isActivateReservationErrorPopupShown: true });

        activateReservationErrorMessage =
          activateReservationSuccessResult.Message;
      }

      this.props.resetActivateReservationResultState();
    
    }
    if (activateReservationErrorResult) {

      //show error pop up
      this.setState({ isActivateReservationLoadingPopupShown: false });
      this.setState({ isActivateReservationErrorPopupShown: true });
      activateReservationErrorMessage = activateReservationErrorResult;
      this.props.resetActivateReservationResultState();
    }

    if (this.state.isCancelReservationDismissButtonClicked) {
      this.setState({ isConfirmCancelReservationPopupShown: false });
      this.setState({ isCancelReservationDismissButtonClicked: false });
      this.setState({ isCancelReservationButtonClicked: false });
    }

    if (this.state.isCancelReservationButtonClicked) {
      this.setState({ isCancelReservationButtonClicked: false });
      //show confirm cancel pop up
      this.setState({ isConfirmCancelReservationPopupShown: true });
    }

    if (this.state.isCancellationConfirmButtonClicked) {
      this.setState({ isCancellationConfirmButtonClicked: false });

      this.setState({ isConfirmCancelReservationPopupShown: false });
      //show loading popup
      this.setState({ isCancelReservationLoadingPopupShown: true });
      //post to server to cancel ticket
      this.props.cancelReservation(reservationInfo.ReservationId);
    }

    if (
      cancelReservationSuccessResult 
    ) {
      if (cancelReservationSuccessResult.Status === "Success") {
        if (this.state.isCancelReservationLoadingPopupShown) {
          this.setState({ isCancelReservationLoadingPopupShown: false });
        }

        //show cancel feedback popup
        this.setState({ isCancelReservationFeedbackPopupShown: true });
      } else {
        //display fail error message from server
        this.setState({ isCancelReservationErrorPopupShown: true });
        cancelReservationErrorMessage = cancelReservationSuccessResult.Message;
      }

      this.props.resetCancelReservationResultState();
    }

    if (cancelReservationErrorResult) {
      //display error mesg from app
      this.setState({ isCancelReservationErrorPopupShown: true });
      cancelReservationErrorMessage = cancelReservationErrorResult;

      this.props.resetCancelReservationResultState();
    }

    if (timeLeft > 0) {
      return (
        <div className="activate_reservation">
          {toolbar}
          {routeToRequestParkingPage}
          {routeToOneOffParkingSummaryPage}
          {routeToHourlyChargingPage}
          {activateReservationErrorPopup}
          {reportParkingSuccessPopup}
          {activateReservationLoadingPopup}
          {cancelReservationLoadingPopup}
          {confirmCancelReservationPopup}
          {cancelReservationFeedbackPopup}
          {navigateToEntrancePopup}
          {cancelReservationErrorPopup}
          {confirmUnlockBarrierPopup}

          <div className="activate_reservation--area_name">
            {reservationInfo.AreaName}
          </div>
          <div className="activate_reservation--zone_name">
            {reservationInfo.LocationName}
          </div>

          <div className="activate_reservation--bay_time_container">
            <div className="activate_reservation--bay_time_container--bay_signage">
              <div
                className="activate_reservation--bay_time_container--bay_signage--image"
                style={{ backgroundImage: `url(${reservationInfo.BayImage})` }}
              ></div>
            </div>

            <div className="activate_reservation--bay_time_container--time_remaining">
              <div className="activate_reservation--bay_time_container--time_remaining--label">
                Arrive within:
              </div>
              <div className="activate_reservation--bay_time_container--time_remaining--timer">
                {countdownTimer}
              </div>
            </div>
          </div>

          <div className="activate_reservation--bay_info_container">
            <div
              className="bay_info_container--image"
              style={{ backgroundImage: `url(${reservationInfo.PillarImage})` }}
            />

            <div className="bay_info_container--remark">
              {reservationInfo.LocationRemark}
            </div>
          </div>

          <div className="activate_reservation--buttons">
            <div
              className="buttons--cancel_button"
              onClick={this.handleCancelReservationButtonClicked}
            />
            <div className="buttons-separator" />
            <div
              className="buttons--help_button"
              onClick={ () => this.openDialScreen() }
            />
            <div className="buttons-separator" />
            <div
              className="buttons--gps_button"
              onClick={() =>
                this.handleNavigationButtonClicked(
                  reservationInfo.EntranceLat,
                  reservationInfo.EntranceLng
                )
              }
            />
          </div>

          <div
            className="activate_reservation--unlock_barrier_button_container"
            onClick={this.handleUnlockBarrierButtonClicked}
          >
            <div className="unlock_barrier_button_container--label">
              I'm here, unlock my bay!
            </div>
          </div>
        </div>
      );
    } else {
      //times up
      //check ticket charging type

      if (reservationInfo.ChargingType === "one-off") {
        return <Redirect to="/RequestParking" />;
      } else if (reservationInfo.ChargingType === "hourly") {
        //check ticket status
        if (reservationInfo.Status === ReservationStatus.UNCLAIMED) {
          //go to request parking page
          return <Redirect to="/RequestParking" />;
        } else if (
          reservationInfo.Status === ReservationStatus.HOURLY_CHARGING ||
          reservationInfo.Status === ReservationStatus.ACTIVATED
        ) {
          //go to hourly charging page
          return <Redirect to="/HourlyCharging" />;
        } else {
          return <Redirect to="/RequestParking" />;
        }
      } else {
        return <Redirect to="/RequestParking" />;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    activateReservationSuccessResult:
      state.reservationPost.activateReservationSuccessResult,

    activateReservationErrorResult:
      state.reservationPost.activateReservationErrorResult,

    cancelReservationSuccessResult:
      state.reservationPost.cancelReservationSuccessResult,

    cancelReservationErrorResult:
      state.reservationPost.cancelReservationErrorResult,

    reportParkingSuccessfulSuccessResult:
      state.reservationPost.reportParkingSuccessfulSuccessResult,

    reportParkingSuccessfulErrorResult:
      state.reservationPost.reportParkingSuccessfulErrorResult,

    


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activateReservation: (reservationId) =>
      dispatch(activateReservationAction(reservationId)),

    cancelReservation: (reservationId) =>
      dispatch(cancelReservationAction(reservationId)),

    reportParkingSuccessful: (reservationId) =>
      dispatch(reportParkingSuccessfulAction(reservationId)),

      resetActivateReservationResultState: () =>
      dispatch(resetActivateReservationResultStateAction()),

      resetCancelReservationResultState: () =>
      dispatch(resetCancelReservationResultStateAction()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ActivateReservation
);
