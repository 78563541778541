import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

import "./RequestParking.css";
import LocationList from "../layout/list/LocationList";
import TwoButtonsPopup from "../layout/popup/TwoButtonsPopup";
import Backdrop from "../layout/backdrop/Backdrop";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import PriceInfoList from "../layout/list/PriceInfoList";
import LocationDatabase from "../../raw-database/LocationProduction.json";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import NotificationBar from "../layout/notification/NotificationBar";
import Colors from "../../constants/Colors";
import LoadingPopup from "../layout/popup/LoadingPopup";
import NewbieFreeCreditsPopup from "../layout/popup/NewbieFreeCreditsPopup";
import Toolbar from "../layout/toolbar/Toolbar";
import ReservationSuccessfulPopup from "../layout/popup/ReservationSuccessfulPopup";
import InsufficientCreditsPopup from "../layout/popup/InsufficientCreditsPopup";
import LocalStorageKeys from "../../constants/LocalStorageKeys.js";

import {
  retrieveGeneralLocationListAction,
  retrieveCustomLocationListAction,
  resetGetGeneralLocationsResultStateAction,
  resetGetCustomLocationsResultStateAction,
  monitorBayAvailableNumberAction,
  monitorPriceInfoAction,
  monitorOperationInfoAction,
} from "../../store/actions/RealtimeDatabaseActions";
import {
  makeReservationAction,
  resetMakeReservationResultStateAction,
} from "../../store/actions/ReservationPostActions";

class RequestParking extends Component {
  state = {
    currentSelectedCellName: null,
    currentSelectedAreaName: null,
    currentSelectedZoneId: null,
    selectedZoneBayAvailableNumber: "-",
    selectedZoneOperationInfo: null,

    isBayAvailableNumberUpdated : false,
    isOperationInfoUpdated : false,
    isLocationIdFromUrlValid : false,

    //allLocations: LocationDatabase.Database,

    isDatabaseLoaded: false,
    isReserveButtonClicked: false,
    isPriceInfoPanelShown: false,
    isSideDrawerOpened: false,
    isConfirmReservationButtonClicked: false,
    isReservationSuccessfulDimissButtonClicked: false,
    isReservationErrorDismissButtonClicked: false,
    isNotSignInErrorDismissButtonClicked: false,
    isGoToSignInButtonClicked: false,
    isTimeoutErrorDismissButtonClicked: false,
    isAddCreditsButtonClicked: false,

    isDatabaseLoadingPopupShown: true,
    isReserveLoadingPopupShown: false,
    isReservationSuccessfulPopupShown: false,
    isConfirmReservationPopupShown: false,
    isNotSignInErrorPopupShown: false,
    isMakeReservationErrorPopupShown: false,
    isOfflineOperationPopupShown: false,
    isDismissOperationMessageButtonClicked: false,
    isUrlParamFound: false,
    isNewbieFreeCreditsPopupShown: false,
    isInsufficientCreditsPopupShown: false,
    isInvalidLocationFoundPopupShown: false,

    offTitle: "",
    offMessage: "",

    currentReservationSuccessResult: null,
  };

  componentDidMount = () => {

    //retrieve location list - parkeasy server
    if (reactLocalStorage.get(LocalStorageKeys.IS_LOGGED_IN) === "true") {
      this.props.retrieveCustomLocationList();
    }else{
      this.props.retrieveGeneralLocationList();
    }
   
    //  //set timer for database to loaded
    //  setTimeout(() => {
    //   this.setState({ isDatabaseLoaded: true });
    //   this.setState({ isDatabaseLoadingPopupShown: false });

    //   if(this.props.locations.length > 0 ){
    //      this.setState({allLocations: this.props.locations})
    //      //attach listener for price info - firebase
    //      this.props.monitorPriceInfo(this.props.locations);
   
    //      //attach listener for bay available number - firebase
    //      this.props.monitorBayAvailableNumber(this.props.locations);
   
    //      //attach listener for operation info - firebase
    //      this.props.monitorOperationInfo(this.props.locations);
    //    }
    // }, 3000);

  };


  handleLocationClicked = (selectedCellName, selectedZoneId) => {
    this.setState({ currentSelectedCellName: selectedCellName });

    if (selectedZoneId) {
      this.setState({ currentSelectedZoneId: selectedZoneId });
    }
  };

  handleBackButtonClickedToDisplayZoneList = (areaName) => {
    this.setState({ currentSelectedCellName: areaName });

    this.setState({ currentSelectedZoneId: null });

    this.setState({ isDismissOperationMessageButtonClicked: false });

    this.setState({isBayAvailableNumberUpdated : false});
    this.setState({isOperationInfoUpdated : false});
  };

  handleBackButtonClickedToDisplayAreaList = () => {
    this.setState({ currentSelectedCellName: null });
    this.setState({ currentSelectedZoneId: null });
    this.setState({isBayAvailableNumberUpdated : false});
    this.setState({isOperationInfoUpdated : false});
  };

  handleBackdropClicked = () => {
    this.setState({ isReserveButtonClicked: false });

    this.setState({ isDismissOperationMessageButtonClicked: false });

    this.setState({ isSideDrawerOpened: false });
  };

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.sideDrawerOpen };
    });
  };

  handleReserveButtonClicked = () => {
    this.setState({ isReserveButtonClicked: true });
    this.setState({ isNotSignInErrorDismissButtonClicked: false });
    this.setState({ isGoToSignInButtonClicked: false });
    this.setState({ isConfirmReservationButtonClicked: false });
    this.setState({ isReservationSuccessfulPopupShown: false });
  };

  handleCancelReservationButtonClicked = () => {
    this.setState({ isConfirmReservationButtonClicked: false });
    this.setState({ isNotSignInErrorDismissButtonClicked: false });
    this.setState({ isGoToSignInButtonClicked: false });
    this.setState({ isReserveButtonClicked: false });
    this.setState({ isReservationSuccessfulPopupShown: false });
    this.setState({ isConfirmReservationPopupShown: false });
  };

  handleConfirmReservationButtonClicked = () => {
    this.setState({ isConfirmReservationButtonClicked: true });
    this.setState({ isReserveButtonClicked: false });
    this.setState({ isReservationErrorDismissButtonClicked: false });
  };

  handlePriceInfoExpandIconClicked = () => {
    this.setState({ isPriceInfoPanelShown: true });
  };

  handlePriceInfoCancelIconClicked = () => {
    this.setState({ isPriceInfoPanelShown: false });
  };

  handleCancelSignInButtonClicked = () => {
    this.setState({ isNotSignInErrorPopupShown: false });

    this.setState((prevState) => {
      return {
        isNotSignInErrorDismissButtonClicked: !prevState.isNotSignInErrorDismissButtonClicked,
      };
    });
  };

  handleGoToSignInButtonClicked = () => {
    this.setState((prevState) => {
      return {
        isGoToSignInButtonClicked: !prevState.isGoToSignInButtonClicked,
      };
    });
  };

  handleTimeoutErrorDismissButtonClicked = () => {
    this.setState((prevState) => {
      return {
        isTimeoutErrorDismissButtonClicked: !prevState.isTimeoutErrorDismissButtonClicked,
      };
    });
  };

  handleReservationSuccessfulDismissButtonClicked = () => {
    this.setState({ isReservationSuccessfulPopupShown: false });
    this.setState({ isReservationSuccessfulDimissButtonClicked: true });
  };

  handleNewbieFreeCreditsPopupDismissButtonClicked = () => {
    this.setState({ isNewbieFreeCreditsPopupShown: false });
    reactLocalStorage.set(LocalStorageKeys.IS_NEW_USER, false);
  };

  handleAddCreditsButtonClicked = () => {
    this.setState({ isAddCreditsButtonClicked: true });
  };

  render() {
    //from reducers
    const {
      locations,
      bayAvailableNumberArray,
      priceInfoArray,
      operationInfoArray,
      reservationErrorResult,
      reservationSuccessResult,
      getGeneralLocationsSuccessResult,
      getGeneralLocationsErrorResult,
      getCustomLocationsSuccessResult,
      getCustomLocationsErrorResult,
    } = this.props;


    if(getCustomLocationsSuccessResult){
      if(getCustomLocationsSuccessResult.Status === "Success"){
        this.setState({ isDatabaseLoaded: true });

        
        if (locations && locations.length > 0) {
          //attach listener for price info - firebase
          this.props.monitorPriceInfo(locations);

          //attach listener for bay available number - firebase
          this.props.monitorBayAvailableNumber(locations);

          //attach listener for operation info - firebase
          this.props.monitorOperationInfo(locations);

          this.setState({ isDatabaseLoadingPopupShown: false });
        }
      }
      this.props.resetCustomLocationsResultState();
    }

    if (getCustomLocationsErrorResult) {
      this.props.resetCustomLocationsResultState();
    }

    if(getGeneralLocationsSuccessResult){
      if(getGeneralLocationsSuccessResult.Status === "Success"){
        this.setState({ isDatabaseLoaded: true });
        if (locations && locations.length > 0) {
          //attach listener for price info - firebase
          this.props.monitorPriceInfo(locations);

          //attach listener for bay available number - firebase
          this.props.monitorBayAvailableNumber(locations);

          //attach listener for operation info - firebase
          this.props.monitorOperationInfo(locations);

          this.setState({ isDatabaseLoadingPopupShown: false });
        }
      }
      this.props.resetGeneralLocationsResultState();
    }

    if (getGeneralLocationsErrorResult) {
      this.props.resetGeneralLocationsResultState();
    }



    //to get the url parameters
    //to redirect to specific zone page
    const { params } = this.props.match;

    if (
      typeof params.location_id !== "undefined" &&
      !this.state.isUrlParamFound && 
      locations.length > 0
    ) {
      this.setState({ isUrlParamFound: true });

      var theLocationId = params.location_id;
      var isLocationValid = false;


      if(reactLocalStorage.get(LocalStorageKeys.IS_LOGGED_IN) === "true"){
          //if reference location id is not null, that means special location exists
          locations &&
          locations.map((location) =>
            location.ZoneList.map(
              (zone) =>
                (zone.ReferenceLocationId === theLocationId) 
                &&
                (
                  theLocationId = zone.LocationId
                )
            )
          );
      }

      //check if theLocationId exists in the retrieved location list
      locations &&
      locations.map((location) =>
        location.ZoneList.map(
          (zone) =>
            (zone.LocationId === theLocationId) 
            &&
            (
              isLocationValid = true
             
            )
        )
      );

      //set the location id after checking reference location id
      if(isLocationValid){

        this.setState({ currentSelectedZoneId: theLocationId });
      }else{
        this.setState({ currentSelectedZoneId: null });
        this.setState({isInvalidLocationFoundPopupShown:true});

      }
    

      //set zone name
      locations &&
        locations.map((location) =>
          location.ZoneList.map(
            (zone) =>
              (zone.LocationId === theLocationId) 
              &&
              (
               
                this.setState({ currentSelectedCellName: zone.ZoneName })
              )
              


          )
        );

     

      

      //record for current selected location id
      //so that no need to reselect the location again
      var currentPath = "/RequestParking/" + params.location_id;
      reactLocalStorage.set(LocalStorageKeys.PREVIOUS_PAGE_PATH, currentPath);
    }

   
   
    let routeToPreviousPage;

    if (
      reactLocalStorage.get(LocalStorageKeys.PREVIOUS_PAGE_PATH) !==
        "undefined" &&
      reactLocalStorage.get(LocalStorageKeys.IS_LOGGED_IN) === "true"
    ) {
      const destinationPath = reactLocalStorage.get(
        LocalStorageKeys.PREVIOUS_PAGE_PATH
      );
      routeToPreviousPage = <Redirect to={destinationPath} />;
      reactLocalStorage.set(LocalStorageKeys.PREVIOUS_PAGE_PATH, "undefined");
    }

    var areaName = null;
    var zoneName = null;
    var zoneInfo = [];
    var currentSelectedZoneBayAvailableNumber = "-";
    var currentSelectedZoneOperationInfo = null;
  

    let backdrop;

    let confirmReservationPopup = (
      <TwoButtonsPopup
        show={this.state.isConfirmReservationPopupShown}
        title="Confirm Reservation"
        message="Are you sure to make this reservation?"
        leftButtonText="Cancel"
        rightButtonText="Confirm"
        onLeftButtonClick={this.handleCancelReservationButtonClicked}
        onRightButtonClick={this.handleConfirmReservationButtonClicked}
      />
    );

    let invalidLocationFoundPopup = (
      <OneButtonPopup
      show={this.state.isInvalidLocationFoundPopupShown}
      title="Error"
      message="Sorry, this location is not accessible."
      buttonText="Ok, got it."
      onButtonClick={() => {
        this.setState({ isInvalidLocationFoundPopupShown: false });
      }}
    />
    );

    let offlineOperationMessagePopup = (
      <OneButtonPopup
        show={this.state.isOfflineOperationPopupShown}
        title={this.state.offTitle}
        message={this.state.offMessage}
        buttonText="Ok, got it."
        onButtonClick={() => {
          this.setState({ isDismissOperationMessageButtonClicked: true });
          this.setState({ isOfflineOperationPopupShown: false });
        }}
      />
    );

    let reservationSuccessfulPopup = (
      <ReservationSuccessfulPopup
        show={this.state.isReservationSuccessfulPopupShown}
        onOkayButtonClick={this.handleReservationSuccessfulDismissButtonClicked}
      />
    );

    let notSignInErrorPopup = (
      <TwoButtonsPopup
        show={this.state.isNotSignInErrorPopupShown}
        title="Not Yet Sign In"
        message="Please sign in to make a reservation."
        leftButtonText="Maybe later"
        rightButtonText="Go to Sign In"
        onLeftButtonClick={this.handleCancelSignInButtonClicked}
        onRightButtonClick={this.handleGoToSignInButtonClicked}
      />
    );

    var makeReservationErrorMessage;
    let makeReservationErrorPopup = (
      <OneButtonPopup
        show={this.state.isMakeReservationErrorPopupShown}
        title="Error"
        message={makeReservationErrorMessage}
        buttonText="Okay, got it."
        onButtonClick={() => {
          this.setState({ isMakeReservationErrorPopupShown: false });
        }}
      />
    );

    let loadingPopup = (
      <LoadingPopup
        show={this.state.isReserveLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Making reservation ... "
      />
    );

    let timeoutErrorPopup;

    let routeToTicketActivationPage;
    let routeToSignInPage;
    let routeToAddCreditsPage;

    let insufficientCreditsPopup = (
      <InsufficientCreditsPopup
        show={this.state.isInsufficientCreditsPopupShown}
        onAddCreditsButtonClicked={this.handleAddCreditsButtonClicked}
        onDismissButtonClicked={() =>
          this.setState({ isInsufficientCreditsPopupShown: false })
        }
      />
    );

    //when insufficient credits popup shown
    if (this.state.isAddCreditsButtonClicked) {
      this.setState({ isAddCreditsButtonClicked: false });
      this.setState({ isInsufficientCreditsPopupShown: false });
      routeToAddCreditsPage = <Redirect to="/AddCredits" />;
    }

    var toggleNewbiePopup = (reactLocalStorage.get(LocalStorageKeys.IS_NEW_USER) === 'true');
    //if new user, show newbie free credit popup
    if (reactLocalStorage.get(LocalStorageKeys.IS_NEW_USER) === "true") {
      //reactLocalStorage.set(LocalStorageKeys.IS_NEW_USER, false);
      //this.setState({ isNewbieFreeCreditsPopupShown: true });
      console.log("isNewUser = true" );
     
      //callback of setState 
      // this.setState({ isNewbieFreeCreditsPopupShown: true }, () => {
      //   console.log("isNewbieFreeCreditsPopupShown : " + this.state.isNewbieFreeCreditsPopupShown);
      // }); 
    }

  


    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

    if (this.state.isReserveButtonClicked) {
      this.setState({ isReserveButtonClicked: false });

      if (reactLocalStorage.get(LocalStorageKeys.IS_LOGGED_IN) === "true") {
        this.setState({ isConfirmReservationPopupShown: true });
      } else {
        this.setState({ isNotSignInErrorPopupShown: true });
      }
    }

    if (this.state.isGoToSignInButtonClicked) {
      this.setState({ isGoToSignInButtonClicked: false });
      this.setState({ isNotSignInErrorPopupShown: false });
      routeToSignInPage = <Redirect to="/SignIn" />;
    }

    // confirm to make reservation
    if (this.state.isConfirmReservationButtonClicked) {
      this.setState({ isConfirmReservationButtonClicked: false });
      this.setState({ isConfirmReservationPopupShown: false });
      this.setState({ isReserveLoadingPopupShown: true });

      //post to server to reserve
      this.props.makeReservation(
        this.state.currentSelectedZoneId,
        priceInfoArray
      );
    }

    /*
      process make reservation post result
     */
    if (
      reservationSuccessResult &&
      !this.state.isReservationSuccessfulDimissButtonClicked &&
      !this.state.isReservationSuccessfulPopupShown
    ) {
      if (reservationSuccessResult.Status === "Success") {
        if (reservationSuccessResult.CreditBalance) {
          reactLocalStorage.set(
            LocalStorageKeys.CREDIT_BALANCE,
            reservationSuccessResult.CreditBalance
          );
        }
        this.setState({ isReserveLoadingPopupShown: false });
        this.setState({ isReservationSuccessfulPopupShown: true });
      } else {
        this.setState({ isReserveLoadingPopupShown: false });

        if (
          reservationSuccessResult.ErrorCode === "u1" ||
          reservationSuccessResult.ErrorCode === "u3"
        ) {
          this.setState({ isInsufficientCreditsPopupShown: true });
        } else {
          //error pop up
          this.setState({ isMakeReservationErrorPopupShown: true });
          makeReservationErrorMessage = reservationSuccessResult.Message;
        }

        //reset reservation state to null
        this.props.resetMakeReservationState();
      }
    }

    if (reservationErrorResult) {
      this.setState({ isReserveLoadingPopupShown: false });
      //error pop up
      this.setState({ isMakeReservationErrorPopupShown: true });
      makeReservationErrorMessage = reservationErrorResult;
      //reset reservation state to null
      this.props.resetMakeReservationState();
    }

    if (this.state.isReservationSuccessfulDimissButtonClicked) {
      //CurrentReservationId
      const ticketArray = reactLocalStorage.getObject(
        LocalStorageKeys.TICKET_LIST
      );

      if (ticketArray && ticketArray.length > 0) {
        ticketArray.map((t) => {
          if (
            t.ReservationId === reservationSuccessResult.CurrentReservationId
          ) {
            routeToTicketActivationPage = (
              <Redirect
                to={{
                  pathname: "/ActivateReservation",
                  state: { ticketInfo: t },
                }}
              />
            );
          }
        });
      }

      //reset reservation state to null
      this.props.resetMakeReservationState();
    }

    /* 
      set up bay available number and operation info 
    */
    if (this.state.currentSelectedZoneId && locations.length>0) {
      //set current area name, zone name and zone info
        locations.map((location) =>
          location.ZoneList.map(
            (zone) =>
              zone.LocationId === this.state.currentSelectedZoneId &&
              ((areaName = location.Area),
              (zoneName = zone.ZoneName),
              zoneInfo.push(zone))
          )
        );

      //get the bay available number
      //console.log("bayAvailableNumberArray : ", bayAvailableNumberArray);
      Object.values(bayAvailableNumberArray).forEach((pair) => {
        Object.keys(pair).forEach((key) => {

          //key - represent firebase location id
          var zoneId = key;

          //check if reference location id is the same as the one in firebase
          locations.map((location) =>
            location.ZoneList.map(
              (zone) =>
                (zone.ReferenceLocationId === key) &&
                (zoneId = zone.LocationId)
            )
          );

          var availableNumber = pair[key];
          (zoneId === this.state.currentSelectedZoneId )&&
            (currentSelectedZoneBayAvailableNumber = availableNumber
              );
        });
      });

      //get the operation info
      //console.log("operationInfoArray : ", operationInfoArray);
      Object.values(operationInfoArray).forEach((pair) => {
        Object.keys(pair).forEach((key) => {
          var zoneId = key;

            //check if reference location id is the same as the one in firebase
            locations.map((location) =>
              location.ZoneList.map(
                (zone) =>
                  (zone.ReferenceLocationId === key) &&
                  (zoneId = zone.LocationId)
              )
            );

          var operationInfo = pair[key];
          
          (zoneId === this.state.currentSelectedZoneId) &&
              (currentSelectedZoneOperationInfo = operationInfo);
              
             
            
        });
      });
    }
    //console.log("currentSelectedZoneBayAvailableNumber = ", currentSelectedZoneBayAvailableNumber);
   
    //console.log("currentSelectedZoneOperationInfo = ", currentSelectedZoneOperationInfo);
   
    

    if (
      currentSelectedZoneOperationInfo &&
      currentSelectedZoneOperationInfo.Operating === "false" &&
      this.state.isOfflineOperationPopupShown === false &&
      this.state.isDismissOperationMessageButtonClicked === false
    ) {
      this.setState({ offTitle: currentSelectedZoneOperationInfo.OffTitle });
      this.setState({ offMessage: currentSelectedZoneOperationInfo.OffMessage });
      this.setState({ isOfflineOperationPopupShown: true });
    }
    
    if(currentSelectedZoneBayAvailableNumber !== "-" && !this.state.isBayAvailableNumberUpdated){
      console.log("selectedZoneBayAvailableNumber = ", currentSelectedZoneBayAvailableNumber);
      this.setState({selectedZoneBayAvailableNumber : currentSelectedZoneBayAvailableNumber});
      this.setState({isBayAvailableNumberUpdated : true});
      
    }
     
    if(currentSelectedZoneOperationInfo !== null && !this.state.isOperationInfoUpdated){
      console.log("selectedZoneOperationInfo = ", currentSelectedZoneOperationInfo);
      this.setState({selectedZoneOperationInfo : currentSelectedZoneOperationInfo});
      this.setState({isOperationInfoUpdated : true});
    }
   
   

    if (locations && locations.length > 0) {
      //locations loaded
      return (
        <div className="request_parking">
          <Toolbar
            title="ParkEasy"
            leftNavItemType="toggle-button"
            drawerClickHandler={this.handleDrawerToggleClicked}
          />
          <SideDrawer show={this.state.isSideDrawerOpened} />

          {backdrop}

          {confirmReservationPopup}
          {offlineOperationMessagePopup}
          {reservationSuccessfulPopup}
          {notSignInErrorPopup}
          {makeReservationErrorPopup}
          {loadingPopup}
          {timeoutErrorPopup}
          {insufficientCreditsPopup}
          {invalidLocationFoundPopup}

          {routeToSignInPage}
          {routeToTicketActivationPage}
          {routeToPreviousPage}
          {routeToAddCreditsPage}

          <NewbieFreeCreditsPopup
          show={toggleNewbiePopup}
          onDismissButtonClicked={
            this.handleNewbieFreeCreditsPopupDismissButtonClicked
          }
        />

      
          {this.state.currentSelectedZoneId &&
            this.state.selectedZoneOperationInfo &&
            this.state.selectedZoneOperationInfo.Operating === "true" && (
              <NotificationBar
                message={this.state.selectedZoneOperationInfo.OnTitle}
                barColor={Colors.green}
              />
            )}

          {this.state.currentSelectedZoneId &&
            this.state.selectedZoneOperationInfo &&
            this.state.selectedZoneOperationInfo.Operating === "false" && (
              <NotificationBar
                message={this.state.selectedZoneOperationInfo.OffTitle}
                barColor={Colors.red}
              />
            )}

          {this.state.currentSelectedZoneId &&
            this.state.selectedZoneOperationInfo &&
            this.state.selectedZoneOperationInfo.Operating === "true" ? (
            <div className="request_parking--info_container">
              <div className="info_container--reserve_container">
                <div className="reserve_container--title">Reserve</div>
                <div className="reserve_container--label_line_1">
                  Reserve a parking bay
                </div>
                <div className="reserve_container--label_line_2">
                  before you arrive.
                </div>
              </div>

              <div className="info_container--bay_available_container">
                <div className="bay_available_container--available_number">
                  {this.state.selectedZoneBayAvailableNumber}
                </div>
                <div className="bay_available_container--label_line_1">bay</div>
                <div className="bay_available_container--label_line_2">
                  available
                </div>
              </div>
            </div>
          ) : (
            <div className="request_parking--info_container">
              <div className="info_container--reserve_container">
                <div className="reserve_container--title">Reserve</div>
                <div className="reserve_container--label_line_1">
                  Reserve a parking bay
                </div>
                <div className="reserve_container--label_line_2">
                  before you arrive.
                </div>
              </div>
            </div>
          )}

          {this.state.currentSelectedZoneId ? (
            <div className="request_parking--choose_location_container">
              <div
                className="choose_location_container--back_button"
                onClick={() =>
                  this.handleBackButtonClickedToDisplayZoneList(areaName)
                }
              />
              <div className="choose_location_container--selected_location_container">
                <div className="selected_location_container--area_name">
                  {areaName}
                </div>
                <div className="selected_location_container--zone_name">
                  {zoneName}
                </div>
              </div>
              <div className="choose_location_container--space" />
            </div>
          ) : this.state.currentSelectedCellName ? (
            <div className="request_parking--choose_location_container">
              <div
                className="choose_location_container--back_button"
                onClick={this.handleBackButtonClickedToDisplayAreaList}
              />
              <div className="choose_location_container--label">
                {this.state.currentSelectedCellName}
              </div>
              <div className="choose_location_container--space" />
            </div>
          ) : (
            <div
              className="request_parking--choose_location_container"
              style={{ justifyContent: "center" }}
            >
              <div className="choose_location_container--label">
                Choose a location
              </div>
            </div>
          )}

          <LocationList
            rawData={locations}
            previousSelectedCell={this.state.currentSelectedCellName}
            selectedZoneInfo={zoneInfo}
            onLocationClick={this.handleLocationClicked}
          />

          {this.state.currentSelectedZoneId && (
            <PriceInfoList
              isExpandableMode={true}
              isPriceInfoPanelShown={this.state.isPriceInfoPanelShown}
              OnExpandIconClick={this.handlePriceInfoExpandIconClicked}
              OnCancelIconClick={this.handlePriceInfoCancelIconClicked}
              zonePriceInfo={priceInfoArray}
              selectedZoneId={this.state.currentSelectedZoneId}
            />
          )}

          {this.state.currentSelectedZoneId ? (
            this.state.selectedZoneOperationInfo?(
              this.state.selectedZoneOperationInfo.Operating === "true" ? (
              //operation state is true
              this.state.selectedZoneBayAvailableNumber === 0 ? (
                <div
                  className="request_parking--confirm_reservation_button_container"
                  style={{ backgroundColor: "#BBBDC0" }}
                >
                  <div className="confirm_reservation_button_container--label">
                    Fully Reserved
                  </div>
                </div>
              ) : (
                <div
                  className="request_parking--confirm_reservation_button_container"
                  onClick={this.handleReserveButtonClicked}
                >
                  <div className="confirm_reservation_button_container--label">
                    Confirm reservation
                  </div>
                </div>
              )
            ) : (
              //operation state is false
              <div
                className="request_parking--confirm_reservation_button_container"
                style={{ backgroundColor: "#BBBDC0" }}
              >
                <div className="confirm_reservation_button_container--label">
                  Temporarily closed
                </div>
              </div>
            ))
            :(
              <div
              className="request_parking--confirm_reservation_button_container"
              style={{ backgroundColor: "#BBBDC0" }}
            >
              <div className="confirm_reservation_button_container--label">
               Loading
              </div>
            </div>
            )
          ) : (
            <div className="request_parking--icon_container">
              <div className="icon_container--image" />
              <div className="icon_container--label">EV charger available</div>
            </div>
          )}

          {/**/}
        </div>
      );
    } else {
      //no locations loaded
      return (
        <div className="request_parking">
          <Toolbar
            title="ParkEasy"
            leftNavItemType="toggle-button"
            drawerClickHandler={this.handleDrawerToggleClicked}
          />
          <SideDrawer show={this.state.isSideDrawerOpened} />
          <LoadingPopup
            show={this.state.isDatabaseLoadingPopupShown}
            type="spinningBubbles"
            color={Colors.blue}
            message="Loading database ... "
          />

          <div className="request_parking--info_container">
            <div className="info_container--reserve_container">
              <div className="reserve_container--title">Reserve</div>
              <div className="reserve_container--label_line_1">
                Reserve a parking bay
              </div>
              <div className="reserve_container--label_line_2">
                before you arrive.
              </div>
            </div>
          </div>

          <div
            className="request_parking--choose_location_container"
            style={{ justifyContent: "center" }}
          >
            <div className="choose_location_container--label">
              Choose a location
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    //.location - from RootReducer.js
    //.locations - from LocationReducer.js
    locations: state.realtimeDatabase.locations,

    getGeneralLocationsSuccessResult:state.realtimeDatabase.getGeneralLocationsSuccessResult,
    getGeneralLocationsErrorResult:state.realtimeDatabase.getGeneralLocationsErrorResult,

    getCustomLocationsSuccessResult:state.realtimeDatabase.getCustomLocationsSuccessResult,
    getCustomLocationsErrorResult:state.realtimeDatabase.getCustomLocationsErrorResult,

    //.firestore - from RootReducer.js
    //.reservations - from ReservationReducer.js
    reservations: state.firestore.ordered.reservations,

    bayAvailableNumberArray: state.realtimeDatabase.bayAvailableNumber,

    priceInfoArray: state.realtimeDatabase.priceInfo,

    operationInfoArray: state.realtimeDatabase.operationInfo,

    reservationSuccessResult:
      state.reservationPost.makeReservationSuccessResult,

    reservationErrorResult: state.reservationPost.makeReservationErrorResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    retrieveGeneralLocationList: () =>
      dispatch(retrieveGeneralLocationListAction()),
    retrieveCustomLocationList: () =>
      dispatch(retrieveCustomLocationListAction()),

    resetGeneralLocationsResultState:() =>
    dispatch(resetGetGeneralLocationsResultStateAction()),
    resetCustomLocationsResultState:() =>
    dispatch(resetGetCustomLocationsResultStateAction()),

    monitorBayAvailableNumber: (locations) =>
      dispatch(monitorBayAvailableNumberAction(locations)),
    monitorPriceInfo: (locations) =>
      dispatch(monitorPriceInfoAction(locations)),
    monitorOperationInfo: (locations) =>
      dispatch(monitorOperationInfoAction(locations)),
    //priceInfoArray includes all locations
    makeReservation: (zoneId, priceInfoArray) =>
      dispatch(makeReservationAction(zoneId, priceInfoArray)),

    resetMakeReservationState: () =>
      dispatch(resetMakeReservationResultStateAction()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RequestParking
);
