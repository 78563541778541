import React, { Component } from "react";
import "./OneButtonPopup.css";

class OneButtonPopup extends Component {
  state = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width, height } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      // mainContainerTop = "35%";
      // mainContainerBottom = "35%";
    } else {
      mainContainerLeft = "30%";
      mainContainerRight = "30%";
      // mainContainerTop = "25%";
      // mainContainerBottom = "25%";
    }
    
    
    if(height > 600 && height < 700){
      mainContainerTop = "30%";
      mainContainerBottom = "30%";

  }else if(height > 700 && height < 800){
    mainContainerTop = "35%";
    mainContainerBottom = "35%";

  }else if(height > 800){
    mainContainerTop = "30%";
    mainContainerBottom = "30%";

  }else{
    mainContainerTop = "22%";
    mainContainerBottom = "22%";
  }

    if (this.props.show) {
      return (
        <div className="one_button_popup">
          <div
            className="one_button_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
            <div className="one_button_popup--title">
              <div className="one_button_popup--title--label">
                {this.props.title}
              </div>
            </div>

            <div className="one_button_popup--message">
              {this.props.message}
            </div>

            <div className="one_button_popup--button">
              <div
                className="one_button_popup--button--label"
                onClick={this.props.onButtonClick}
              >
                {this.props.buttonText}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default OneButtonPopup;
