import axios from "axios";
import url from "../../constants/Url";
import { reactLocalStorage } from "reactjs-localstorage";

import StorageKeys from "../../constants/LocalStorageKeys";

export const resetSignInStateAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: "RESET_SIGN_IN_STATE" });
  };
};

export const resetSignUpStateAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: "RESET_SIGN_UP_STATE" });
  };
};

export const resetSignOutStateAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: "RESET_SIGN_OUT_STATE" });
  };
};

/* Sinch */
export const requestSmsVerificationAction = (phoneNumber) => {
  return (dispatch, getState) => {
    //post to parkeasy server
    const params = new URLSearchParams();
    params.append("phone_number", phoneNumber);

    //post to parkeasy server
    axios
      .post(url.REQUEST_SMS_VERIFICATION, params, {
          headers: {
            "X-Firebase-AppCheck": getState().appCheck.token,
          },
          crossDomain: true,
      })
      .then((response) => {
        console.log(response.data);

        const result = response.data;

        dispatch({ type: "REQUEST_SMS_VERIFICATION_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "REQUEST_SMS_VERIFICATION_ERROR", error });
      });
  };
};

export const resetRequestSmsVerificationStateAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: "RESET_REQUEST_SMS_VERIFICATION_STATE" });
  };
};

export const signInWithSinchAction = (phoneNumber, otp) => {
  return (dispatch, getState) => {
    //post to parkeasy server
    const params = new URLSearchParams();
    params.append("login_method", "SINCH");
    params.append("phone_number", phoneNumber);
    params.append("otp", otp);

    //post to parkeasy server
    axios
      .post(url.SIGN_IN, params, {
        headers: {
          "X-Firebase-AppCheck": getState().appCheck.token,
        },
        crossDomain: true,
      })
      .then((response) => {
        console.log(response.data);

        const result = response.data;

        //save user data to local storage
        reactLocalStorage.set(StorageKeys.IS_LOGGED_IN, true);
        reactLocalStorage.set(StorageKeys.USER_ID, result.ParkeasyUserId);
        reactLocalStorage.set(StorageKeys.USER_NAME, result.UserName);
        reactLocalStorage.set(StorageKeys.SESSION_ID, result.SessionId);
        reactLocalStorage.set(StorageKeys.SESSION_TOKEN, result.SessionToken);
        reactLocalStorage.set(StorageKeys.EMAIL, result.Email);
        reactLocalStorage.set(StorageKeys.PHONE_NUMBER, result.PhoneNumber);
        reactLocalStorage.set(StorageKeys.CREDIT_BALANCE, result.CreditBalance);

        dispatch({ type: "SIGN_IN_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SIGN_IN_ERROR", error });
      });
  };
};

export const signUpWithSinchAction = (phoneNumber, name, email) => {
  return (dispatch, getState) => {
    const params = new URLSearchParams();
    params.append("login_method", "SINCH");
    params.append("phone_number", phoneNumber);
    params.append("name", name);
    params.append("email", email);

    //post to parkeasy server
    axios
      .post(url.SIGN_UP, params, {
        headers: {
          "X-Firebase-AppCheck": getState().appCheck.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        dispatch({ type: "SIGN_UP_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SIGN_UP_ERROR", error });
      });
  };
};



export const signUpAction = (phoneNumber, idToken, name, email) => {
  return (dispatch, getState) => {
    const params = new URLSearchParams();
    params.append("login_method", "FIREBASE");
    params.append("phone_number", phoneNumber);
    params.append("id_token", idToken);
    params.append("name", name);
    params.append("email", email);

    //post to parkeasy server
    axios
      .post(url.SIGN_UP, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        dispatch({ type: "SIGN_UP_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SIGN_UP_ERROR", error });
      });
  };
};

export const signOutAction = () => {
  return (dispatch, getState) => {
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );

    //post to parkeasy server
    axios
      .post(url.SIGN_OUT, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        dispatch({ type: "SIGN_OUT_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SIGN_OUT_ERROR", error });
      });

    //sigout firebase
    //if enabled, sign in will prompt app verify and code confirmation every sign in
    // const firebase = getFirebase();
    // firebase.auth().signOut().then(function() {
    //   console.log("firebase sign out success")
    //   // Sign-out successful.
    // }).catch(function(error) {
    //   // An error happened.
    //   console.log("firebase sign out failed , error : " + error);
    // });
  };
};
