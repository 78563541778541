import React, { Component } from "react";
import "./SignUp.css";
import Toolbar from "../layout/toolbar/Toolbar";
import TextField, { Input } from "@material/react-text-field";

import Colors from "../../constants/Colors";
import LocalStorageKey from '../../constants/LocalStorageKeys';

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  signUpWithSinchAction,
  resetSignUpStateAction,
} from "../../store/actions/AuthActions";
import { reactLocalStorage } from "reactjs-localstorage";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import LoadingPopup from "../layout/popup/LoadingPopup";



class SignUp extends Component {
  state = {
    isBackButtonClicked: false,
    isSubmitButtonClicked: false,
    isDismissErrorPopupButtonClicked: false,
    userName: "",
    email: "",
    isEmailValid: true,
    isSignUpErrorPopupShown: false,
    isTimeoutErrorPopupShown: false,
    isPostResultGranted: false,
  };

  handleSubmitButtonClicked = () => {
    this.setState((prevState) => {
      return { isSubmitButtonClicked: !prevState.isSubmitButtonClicked };
    });
  };

  handleEmailChanged = (e) => {
    this.setState({ email: e.target.value });
    console.log(this.state.email);

    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regex.test(e.target.value)) {
      // this is a valid email address
      this.setState({ isEmailValid: true });
    } else {
      // invalid email, maybe show an error to the user.
      this.setState({ isEmailValid: false });
    }
  };

  handleDismissErrorPopupButtonClicked = () => {
    this.setState({ isDismissErrorPopupButtonClicked: true });
  };

  render() {
    //from auth reducer
    const { signUpResult, signUpFailedError } = this.props;

    //from <Redirect> props
    const phoneNumber = this.props.location.state.phoneNumber;
    const idToken = this.props.location.state.idToken;

    console.log("phoneNumber : " + phoneNumber);
    console.log("idToken : " + idToken);

    let routeToSignInPage;
    let routeToRequestParkingPage;

    let loadingPopup;

    var errorMessage;
    let signUpErrorPopup = (
      <OneButtonPopup
        show={this.state.isSignUpErrorPopupShown}
        title="Error"
        message={errorMessage}
        buttonText="OK, got it."
        onButtonClick={this.handleDismissErrorPopupButtonClicked}
      />
    );

    let timeoutErrorPopup = (
      <OneButtonPopup
        show={this.state.isTimeoutErrorPopupShown}
        title="Timeout"
        message="Sorry, please try again later."
        buttonText="OK, got it."
        onButtonClick={this.handleDismissErrorPopupButtonClicked}
      />
    );

    let toolbar = (
      <Toolbar
        title="Sign Up"
        leftNavItemType="back-button"
        toolbarBackButtonOnClick={() =>
          this.setState({ isBackButtonClicked: true })
        }
      />
    );

    if (this.state.isBackButtonClicked) {
      this.setState({ isBackButtonClicked: false });
      routeToSignInPage = <Redirect to="/SignIn" />;
    }

    if (signUpResult) {
      if (signUpResult.Status === "Success") {
        //save user data to local storage
        reactLocalStorage.set(LocalStorageKey.IS_LOGGED_IN, true);
        reactLocalStorage.set(LocalStorageKey.USER_ID, signUpResult.ParkeasyUserId);
        reactLocalStorage.set(LocalStorageKey.USER_NAME, signUpResult.UserName);
        reactLocalStorage.set(LocalStorageKey.SESSION_ID, signUpResult.SessionId);
        reactLocalStorage.set(LocalStorageKey.SESSION_TOKEN, signUpResult.SessionToken);
        reactLocalStorage.set(LocalStorageKey.EMAIL, signUpResult.Email);
        reactLocalStorage.set(LocalStorageKey.PHONE_NUMBER, signUpResult.PhoneNumber);
        reactLocalStorage.set(LocalStorageKey.CREDIT_BALANCE, signUpResult.CreditBalance);
        reactLocalStorage.set(LocalStorageKey.IS_NEW_USER, true);

        //redirect to home page
        routeToRequestParkingPage = (
          <Redirect
            to={{
              pathname: "/RequestParking",
             
            }}
          />
        );


      } else {
        //error pop up
        this.setState({ isSignUpErrorPopupShown: true });
        errorMessage = signUpResult.message;
      }

      this.props.resetSignUpState();
    }

    if (this.state.isSubmitButtonClicked) {
      //post to parkeasy server to sign up
      this.props.signUp(
        phoneNumber,
        idToken,
        this.state.userName,
        this.state.email
      );

      loadingPopup = (
        <LoadingPopup
          show={this.state.isSubmitButtonClicked}
          type="spinningBubbles"
          color={Colors.blue}
          message="Signing up ... "
        />
      );

      this.setState({ isSubmitButtonClicked: false });

    }

    return (
      <div className="sign_up">
        {toolbar}
        {routeToSignInPage}
        {routeToRequestParkingPage}

        {loadingPopup}
        {signUpErrorPopup}
        {timeoutErrorPopup}

        <div className="sign_up--text_field_container">
          <h4 style={{ color: Colors.text_grey }}> Name: </h4>
          <TextField>
            <Input
              style={{
                borderRadius: "2px",
                border: "1px solid #BBBDC0",
                width: "100%",
                padding: "0.5em",
              }}
              value={this.state.userName}
              onChange={(e) =>
                this.setState({ userName: e.currentTarget.value })
              }
            />
          </TextField>
        </div>

        <div className="sign_up--text_field_container">
          <h4 style={{ color: Colors.text_grey }}> Email: </h4>
          
            <TextField>
              <Input
                style={{
                  borderRadius: "2px",
                  border: "1px solid #BBBDC0",
                  width: "100%",
                  padding: "0.5em",
                }}
                value={this.state.email}
                onChange={this.handleEmailChanged}
              />
            </TextField>
        
        </div>

        <div className="sign_up--text_field_container">
          <h4 style={{ color: Colors.text_grey }}> Phone Number: </h4>
          <TextField>
            <Input
              style={{
                borderRadius: "2px",
                border: "1px solid #BBBDC0",
                width: "100%",
                padding: "0.5em",
              }}
              value={phoneNumber}
              disabled={true}
            />
          </TextField>
        </div>

        {this.state.isEmailValid ? (
          <div
            className="sign_up--submit_button_container"
            onClick={this.handleSubmitButtonClicked}
            style={{ backgroundColor: Colors.purple }}
          >
            <div className="submit_button_container--label">Submit</div>
          </div>
        ) : (
          <div
            className="sign_up--submit_button_container"
            style={{ backgroundColor: Colors.background_grey }}
          >
            <div className="submit_button_container--label">Submit</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signUpError: state.auth.signUpFailedError,
    signUpResult: state.auth.signUpSuccessResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (phoneNumber, idToken, name, email) =>
      dispatch(signUpWithSinchAction(phoneNumber, name, email)),
    resetSignUpState: () => dispatch(resetSignUpStateAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
