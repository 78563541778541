import React, { Component } from "react";
import "./ReservationSuccessfulPopup.css";

class ReservationSuccessfulPopup extends Component {
  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    } else {
      mainContainerLeft = "38%";
      mainContainerRight = "38%";
      mainContainerTop = "20%";
      mainContainerBottom = "20%";
    }

    if(this.props.show){
      return (
        <div className="reservation_made_popup">
          <div
            className="reservation_made_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
  
          
            <div className="reservation_made_popup--title_container">
              <div className="reservation_made_popup--title_container--title">
                Reservation Successful!
              </div>
             
            </div>
  
            <div className="reservation_made_popup--important_content">
              <div className="reservation_made_popup--important_content--icon" />
              <div className="reservation_made_popup--important_content--message">
                Arrive within 1 hour to claim your reserve bay
              </div>
            </div>
  
            <div className="reservation_made_popup--important_content">
              <div className="reservation_made_popup--important_content--icon" />
              <div className="reservation_made_popup--important_content--message">
                Use our GPS to navigate to the correct location
              </div>
            </div>
  
            <div className="reservation_made_popup--important_content">
              <div className="reservation_made_popup--important_content--icon" />
              <div className="reservation_made_popup--important_content--message">
                Pay attention to the correct carpark bay number
              </div>
            </div>
  
            <div className='reservation_made_popup--background_image'/>
  
            <div className="reservation_made_popup--dismiss_button" onClick={this.props.onOkayButtonClick}>
              <div className="reservation_made_popup--dismiss_button--label">Okay, got it.</div>
            </div>
          </div>
        </div>
      );
    }else{
      return null;
    }

  
  }
}

export default ReservationSuccessfulPopup;
