import React, { Component } from 'react'
import './LocationList.css'

class LocationList extends Component {

    state= {
        windowHeight : window.innerHeight,
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
      }
    
      // make sure to remove the listener
      // when the component is not mounted anymore
      componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
      }
    
      handleWindowSizeChange = () => {
        this.setState({ windowHeight: window.innerHeight });
      };

    render(){

        var scrollListHeight = 0;
        var selectedListHeight = 0;
        if(this.state.windowHeight > 600 && this.state.windowHeight < 700){
            scrollListHeight = this.state.windowHeight * 0.4;
            selectedListHeight = this.state.windowHeight * 0.33;

        }else if(this.state.windowHeight > 700 && this.state.windowHeight < 800){
            scrollListHeight = this.state.windowHeight * 0.45;
            selectedListHeight = this.state.windowHeight * 0.4;

        }else if(this.state.windowHeight > 800){
            scrollListHeight = this.state.windowHeight * 0.5;
            selectedListHeight = this.state.windowHeight * 0.45;

        }else{
            scrollListHeight = this.state.windowHeight * 0.3;
            selectedListHeight = this.state.windowHeight * 0.22;
        }

        
        

        //convert json string to array
        const locations = Object.values(this.props.rawData);

        var zoneList = null;

        
       
        if(this.props.previousSelectedCell && this.props.selectedZoneInfo.length ===  0){

            //display zone list

            locations && locations.map((location) =>
                (location.Area === this.props.previousSelectedCell)
                && (zoneList = location.ZoneList)
            )

            
           
            return(
           
                <div className='location_list'>
                   <div className='location_list--scroll_list' style = { {height : scrollListHeight}}>
                        {zoneList && zoneList.map((zone) =>
                            (zone.ReservationType === 'EV')
                            ?   <div className='location_list--image_container' 
                                        id={zone.LocationId}
                                        key={zone.LocationId}
                                        onClick={()=>this.props.onLocationClick(zone.ZoneName, zone.LocationId)}>
                                    <div className='location_list--item' >
                                        {zone.ZoneName}
                                    </div>  
                                    <div className='location_list--image'/>
                                </div>
                            :   <div className='location_list--image_container'
                                            id={zone.LocationId}
                                            key={zone.LocationId}
                                            onClick={()=>this.props.onLocationClick(zone.ZoneName, zone.LocationId)}
                                            >
                                    <div className='location_list--item'>
                                        {zone.ZoneName}
                                    </div>  
                                </div>
                        )}
                    </div>
                </div>
                    
                
            )
        }else if(this.props.previousSelectedCell && this.props.selectedZoneInfo.length>0){
              //display selected zone info

           var zoneImageUrl = null;
           var zoneDescriptionArray1 = null;
           var zoneDescriptionArray2 = null;
           this.props.selectedZoneInfo.map((item)=>
                zoneImageUrl = item.ZoneImageUrl 
           )

           this.props.selectedZoneInfo.map((item)=>
           zoneDescriptionArray1 = item.ZoneInfo1
           )

           this.props.selectedZoneInfo.map((item)=>
           zoneDescriptionArray2 = item.ZoneInfo2
           )

            return(
           
                <div className='location_list'>
                     <div className='location_list--scroll_list' style={{paddingTop:'1em', paddingBottom:'1em', height : selectedListHeight}}>
                        {zoneDescriptionArray1.map((info)=>
                            <div  key={info} className='location_list--zone_description'>
                                {info}
                            </div>
                        )}
                        
                    <div className='location_list--zone_image' style={{backgroundImage: `url(${zoneImageUrl})`}}/>
                        
                    {zoneDescriptionArray2.map((info)=>
                            <div className='location_list--zone_description'>
                                {info}
                            </div>
                        )}
                    </div>
                </div>
            )

        } else{
             //display area list

             var isEVZoneFound = false;
            let array = [];

            locations && locations.map((location) =>{
                location.ZoneList.map((zone)=>{
                    if(zone.ReservationType === 'EV'){
                        isEVZoneFound = true;
                    } 

                });

                if(isEVZoneFound){
                    array.push({[location.Area] : 'EV'});
                }else{
                    array.push({[location.Area] : 'Normal'});
                }

                isEVZoneFound = false;

            });

           
            return(
                <div className='location_list'>
                    <div className='location_list--scroll_list' style = { {height : scrollListHeight}}>
                        {Object.values(array).map((pair)=>
                            Object.keys(pair).map((key)=>
                            (pair[key] === 'EV')
                            ?   <div className='location_list--image_container' 
                                    id={key}
                                    onClick={()=>this.props.onLocationClick(key, null)}>
                                    <div className='location_list--item' >
                                        {key}
                                    </div>
                                    <div className='location_list--image'/>
                                </div>
                            :   <div className='location_list--image_container' 
                                    id={key}
                                    onClick={()=>this.props.onLocationClick(key, null)}>
                                    <div className='location_list--item' >
                                        {key}
                                    </div>
                                </div>
                        ))}
                    </div>
                   
                </div>
                    
                
            )
        }

      


       

       
    }

}

export default LocationList;