import AppCheckReducer from './AppCheckReducer'
import AuthReducer from './AuthReducer'
import RealtimeDatabaseReducer from './RealtimeDatabaseReducer'
import ReservationReducer from './ReservationReducer';
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import ReservationPostReducer from './ReservationPostReducer.js'
import PaymentReducer from './PaymentReducer';
import UserReducer from './UserReducer';

const rootReducer = combineReducers({
  appCheck: AppCheckReducer,
  auth: AuthReducer,
  realtimeDatabase: RealtimeDatabaseReducer,
  reservation: ReservationReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  reservationPost: ReservationPostReducer,
  payment: PaymentReducer,
  user: UserReducer,
});

export default rootReducer;

// the key name will be the data property on the state object