import React, { Component } from "react";

import "./AccountSettings.css";

import { reactLocalStorage } from "reactjs-localstorage";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import LocalStorageKeys from "../../constants/LocalStorageKeys";
import Toolbar from "../layout/toolbar/Toolbar";
import Backdrop from "../layout/backdrop/Backdrop";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import TwoButtonsPopup from "../layout/popup/TwoButtonsPopup";

import {signOutAction, resetSignOutStateAction} from '../../store/actions/AuthActions'

class AccountSettings extends Component {
  state = {
    width: window.innerWidth,
    isSignOutButtonClicked: false,
    isConfirmToSignOutPopupShown: false,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleBackdropClicked = () => {
    this.setState({ isSideDrawerOpened: false });
  };

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.sideDrawerOpen };
    });
  };

  handleSignOutButtonClicked = () => {
    this.setState({ isSignOutButtonClicked: true });
  };

  handleCancelSignOutButtonClicked = () => {
    this.setState({ isConfirmToSignOutPopupShown: false });
  };

  handleConfirmSignOutButtonClicked = () => {
    //post to server to sign out
    this.props.signOut();
  };

  render() {
    //const { width } = this.state;
    //const isMobile = width <= 500;
    const {signOutSuccessResult, signOutErrorResult} = this.props;

    let backdrop;

    let toolbar = (
      <Toolbar
        title="Account Setting"
        leftNavItemType="toggle-button"
        drawerClickHandler={this.handleDrawerToggleClicked}
      />
    );

    let sideDrawer = <SideDrawer show={this.state.isSideDrawerOpened} />;

    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

    let confirmToSignOutPopup = (
      <TwoButtonsPopup
        show={this.state.isConfirmToSignOutPopupShown}
        title="Where are you going?"
        message="No! Seriously are you going?! :("
        leftButtonText="Cancel"
        rightButtonText="Yes"
        onLeftButtonClick={this.handleCancelSignOutButtonClicked}
        onRightButtonClick={this.handleConfirmSignOutButtonClicked}
      />
    );

    if (this.state.isSignOutButtonClicked) {
      //show confirm popup
      this.setState({ isSignOutButtonClicked: false });
      this.setState({ isConfirmToSignOutPopupShown: true });
    }

    let routeToRequestParkingPage;
  
    if(signOutErrorResult){
      this.setState({ isConfirmToSignOutPopupShown: false });

      reactLocalStorage.clear();
      routeToRequestParkingPage = <Redirect to='/RequestParking'/>;

      this.props.resetSignOutState();
    }

    if(signOutSuccessResult){
      this.setState({ isConfirmToSignOutPopupShown: false });

      reactLocalStorage.clear();
      routeToRequestParkingPage = <Redirect to='/RequestParking'/>;

      this.props.resetSignOutState();
    }

    return (
      <div className="account_settings">
        {toolbar}
        {backdrop}
        {sideDrawer}
        {confirmToSignOutPopup}
        {routeToRequestParkingPage}

        <div className="account_settings--title">Name:</div>
        <div className="account_settings--info">
          {reactLocalStorage.get(LocalStorageKeys.USER_NAME)}
        </div>
        <div className="account_settings--info--bottom_line" />

        <div className="account_settings--title">Email:</div>
        <div className="account_settings--info">
          {reactLocalStorage.get(LocalStorageKeys.EMAIL)}
        </div>
        <div className="account_settings--info--bottom_line" />

        <div className="account_settings--title">Phone number:</div>
        <div className="account_settings--info">
          {reactLocalStorage.get(LocalStorageKeys.PHONE_NUMBER)}
        </div>
        <div className="account_settings--info--bottom_line" />

        <div
          className="account_settings--sign_out_button"
          onClick={this.handleSignOutButtonClicked}
        >
          <div className="account_settings--sign_out_button--label">
            Sign out
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    signOutSuccessResult:
      state.auth.signOutSuccessResult,

    signOutErrorResult:
      state.auth.signOutErrorResult,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () =>
      dispatch(signOutAction()),
    resetSignOutState: () =>
      dispatch(resetSignOutStateAction())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountSettings
);

