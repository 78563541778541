import axios from "axios";
import url from "../../constants/Url";
import { reactLocalStorage } from "reactjs-localstorage";
import StorageKeys from "../../constants/LocalStorageKeys";

export const resetMakeReservationResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_MAKE_RESERVATION_STATE"});
  };
};

export const resetActivateReservationResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_ACTIVATE_RESERVATION_STATE"});
  };
}

export const resetCancelReservationResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_CANCEL_RESERVATION_STATE"});
  };
}

export const resetEndSessionResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_END_SESSION_STATE"});
  };
}

export const resetChangeEVChargingStateResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_CHANGE_EV_CHARGING_STATE"});
  };
}

export const makeReservationAction = (selectedZoneId, priceInfoArray) => {
  return (dispatch, getState) => {
    Object.values(priceInfoArray).forEach((pair) => {
      Object.keys(pair).forEach((key) => {
        var priceInfo = pair[key];
        var zoneId = key;

        if (selectedZoneId === zoneId) {
          if (priceInfo.Amount) {
            const params = new URLSearchParams();

            params.append(
              "parkeasy_user_id",
              reactLocalStorage.get(StorageKeys.USER_ID)
            );
            params.append(
              "session_id",
              reactLocalStorage.get(StorageKeys.SESSION_ID)
            );
            params.append(
              "session_token",
              reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
            );

            params.append("mall_id", zoneId);
            params.append("price", priceInfo.Amount);

            //post to parkeasy server
            axios
              .post(url.MAKE_RESERVATION, params, { crossDomain: true })
              .then((response) => {
                console.log(response.data);
                const result = response.data;

                //remove old ticket list in local storage 
                //update new ticket list from server 
                reactLocalStorage.remove(StorageKeys.TICKET_LIST);
                reactLocalStorage.setObject(
                  StorageKeys.TICKET_LIST,
                  result.TicketList
                );
                dispatch({ type: "MAKE_RESERVATION_SUCCESS", result });
              })
              .catch((error) => {
                console.log(error);
                dispatch({ type: "MAKE_RESERVATION_ERROR", error });
              });
          } else {
            const error = "Empty reservation fee.";
            dispatch({ type: "MAKE_RESERVATION_ERROR", error });
          }
        }
      });
    });
  };
};

export const activateReservationAction = (reservationId) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("reservation_id", reservationId);

    //post to parkeasy server
    axios
      .post(url.ACTIVATE_RESERVATION, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        //update existing reservation info in local storage
        var existingTicketList = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);
      
        Object.values(existingTicketList).forEach((pair) => {
          Object.keys(pair).forEach((key) => {
            
            if(key === 'ReservationId'){
              if(pair[key] === reservationId){
                pair["Status"] = 'activated';
                pair["ActivationTimestamp"] = result.ActivationTimestamp;
                pair['LocationServiceType'] = result.LocationServiceType;
              }
            }
          });
        });
        
       
        //update local storage
        reactLocalStorage.remove(StorageKeys.TICKET_LIST);
        reactLocalStorage.setObject(StorageKeys.TICKET_LIST, existingTicketList);
        

        dispatch({ type: "ACTIVATE_RESERVATION_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "ACTIVATE_RESERVATION_ERROR", error });
      });


  };
};


export const cancelReservationAction = (reservationId) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("reservation_id", reservationId);

    //post to parkeasy server
    axios
      .post(url.CANCEL_RESERVATION, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

         //update existing reservation info in local storage
         var existingTicketList = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);
      
         Object.values(existingTicketList).forEach((pair) => {
           Object.keys(pair).forEach((key) => {
             
             if(key === 'ReservationId'){
               if(pair[key] === reservationId){
                 pair["Status"] = 'cancelled';
                 pair["CancellationTimestamp"] = result.CancellationTimestamp;
               }
             }
           });
         });
         
        
         //update local storage
         reactLocalStorage.remove(StorageKeys.TICKET_LIST);
         reactLocalStorage.setObject(StorageKeys.TICKET_LIST, existingTicketList);

        dispatch({ type: "CANCEL_RESERVATION_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "CANCEL_RESERVATION_ERROR", error });
      });


  };
};

export const reportParkingSuccessfulAction = (reservationId) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("reservation_id", reservationId);

    //post to parkeasy server
    axios
      .post(url.REPORT_PARKING_SUCCESSFUL, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

         //update existing reservation info in local storage
         var existingTicketList = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);
      
         Object.values(existingTicketList).forEach((pair) => {
           Object.keys(pair).forEach((key) => {
             
             if(key === 'ReservationId'){
               if(pair[key] === reservationId){
                pair["ReportSuccessTimestamp"] = result.ReportSuccessTimestamp;
                 if(pair["ChargingType"] === 'hourly'){
                  pair["Status"] = 'hourly_charging';
                 }else{
                   pair["Status"] = 'claimed';
                 }
               }
             }
           });
         });
         
        
         //update local storage
         reactLocalStorage.remove(StorageKeys.TICKET_LIST);
         reactLocalStorage.setObject(StorageKeys.TICKET_LIST, existingTicketList);

        dispatch({ type: "REPORT_PARKING_SUCCESSFUL_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "REPORT_PARKING_SUCCESSFUL_ERROR", error });
      });


  };
};

export const feedbackCancelReservationAction = (reservationId, feedback) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("reservation_id", reservationId);
    params.append("feedback", feedback);

    //post to parkeasy server
    axios
      .post(url.FEEDBACK_CANCEL_RESERVATION, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        dispatch({ type: "FEEDBACK_CANCEL_RESERVATION_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "FEEDBACK_CANCEL_RESERVATION_ERROR", error });
      });


  };
};

export const endSessionAction = (reservationId) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("reservation_id", reservationId);

    //post to parkeasy server
    axios
      .post(url.END_SESSION, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

         //update existing reservation info in local storage
         var existingTicketList = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);
      
         Object.values(existingTicketList).forEach((pair) => {
           Object.keys(pair).forEach((key) => {
             
             if(key === 'ReservationId'){
               if(pair[key] === reservationId){
                 pair["Status"] = 'hourly_charging';
                 pair["EndSessionTimestamp"] = result.EndSessionTimestamp;
                 pair["TotalCharges"] = result.TotalCharges;
               }
             }
           });
         });
         
        
         //update local storage
         reactLocalStorage.remove(StorageKeys.TICKET_LIST);
         reactLocalStorage.setObject(StorageKeys.TICKET_LIST, existingTicketList);

        dispatch({ type: "END_SESSION_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "END_SESSION_ERROR", error });
      });


  };
};

export const syncReservedTicketsAction = () => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("reservation_id", 'none');

    //post to parkeasy server
    axios
      .post(url.SYNC_RESERVED_TICKETS, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        if(result.IsUpdateRequired){
          //remove old ticket list in local storage 
          //update new ticket list from server 
          reactLocalStorage.remove(StorageKeys.TICKET_LIST);
          reactLocalStorage.setObject(
            StorageKeys.TICKET_LIST,
            result.TicketList
          );
        }else{

        }
        dispatch({ type: "SYNC_RESERVED_TICKETS_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SYNC_RESERVED_TICKETS_ERROR", error });
      });


  };
};

export const changeEVChargingStateAction = (reservationId, chargingState) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("ticket_id", reservationId);
    params.append("charging_state", chargingState);

    //post to parkeasy server
    axios
      .post(url.CHANGE_EV_CHARGING_STATE, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        dispatch({ type: "CHANGE_EV_CHARGING_STATE_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "CHANGE_EV_CHARGING_STATE_ERROR", error });
      });


  };
};




