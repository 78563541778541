import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./TicketList.css";

import Timer from "react-compound-timer";

import Colors from "../../../constants/Colors";

class TicketList extends Component {
  state = {
    isTimeUp: false,
    windowHeight : window.innerHeight,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  handleTimesUp = (ticketInfo) => {
    if (ticketInfo.Status === "hourly_charging") {
    }
  };

  displayCountdownTimer = (ticketInfo) => {
    var bookingTimeInMilliseconds = Number(ticketInfo.ReservationTimestamp);
    var arriveWithinTimeInMilliseconds = Number(ticketInfo.ArriveWithin);
    var remainingTime = bookingTimeInMilliseconds + arriveWithinTimeInMilliseconds - Date.now();
    console.log("remaining time : " + remainingTime);

    let countdownTimer = (
      <Timer
        initialTime={remainingTime}
        direction="backward"
        checkpoints={[
          {
            time: 0,
            callback: (ticketInfo) => this.handleTimesUp,
          },
        ]}
      >
        {() => (
          <React.Fragment>
            <Timer.Minutes />m {<span>&nbsp;</span>}
            <Timer.Seconds />s {<span>&nbsp;</span>}
          </React.Fragment>
        )}
      </Timer>
    );

    return countdownTimer;
  };

  handleTimesUp = () => {
    //recall my reservation page one more time
    this.setState({ isTimeUp: true });
  };

  render() {
    const ticketArray = this.props.tickets;

    var scrollListHeight = 0;
   
    if(this.state.windowHeight > 600 && this.state.windowHeight < 700){
        scrollListHeight = this.state.windowHeight * 0.54;
       

    }else if(this.state.windowHeight > 700 && this.state.windowHeight < 800){
        scrollListHeight = this.state.windowHeight * 0.6;
       

    }else if(this.state.windowHeight > 800){
        scrollListHeight = this.state.windowHeight * 0.55;
        

    }else{
        scrollListHeight = this.state.windowHeight * 0.45;
       
    }


    let routeToMyReservationsPage;
    if (this.state.isTimeUp) {
      this.setState({ isTimeUp: false });

      //reroute to my reservation page
      routeToMyReservationsPage = <Redirect to="/MyReservations" />;
    }

    if (this.props.type === "PRESENT") {
      return (
        <div className="ticket_list">
          {ticketArray &&
            ticketArray.map((ticketInfo) =>
              ticketInfo.t.Status === "hourly_charging" ? (
                <div
                  className="ticket_list--content"
                  id={ticketInfo.t.ReservationId}
                  onClick={() => this.props.onTicketClicked(ticketInfo.t)}
                >
                  <div className="ticket_list--content--left">
                    <div className="ticket_list--content--left--text">
                      {ticketInfo.t.AreaName}
                    </div>
                    <div className="ticket_list--content--left--text">
                      {ticketInfo.t.LocationName}
                    </div>
                  </div>

                  <div className="ticket_list--content--right">
                    <div className="ticket_list--content--right--text">
                      Active
                    </div>
                    <div className="ticket_list--content--right--next_button" />
                  </div>
                </div>
              ) : (
                <div
                  className="ticket_list--content"
                  id={ticketInfo.t.ReservationId}
                  onClick={() => this.props.onTicketClicked(ticketInfo.t)}
                >
                  <div className="ticket_list--content--left">
                    <div className="ticket_list--content--left--text">
                      {ticketInfo.t.AreaName}
                    </div>
                    <div className="ticket_list--content--left--text">
                      {ticketInfo.t.LocationName}
                    </div>
                  </div>

                  <div className="ticket_list--content--right">
                    <div className="ticket_list--content--right--text">
                      {this.displayCountdownTimer(ticketInfo.t)}
                    </div>
                    <div className="ticket_list--content--right--next_button" />
                  </div>
                </div>
              )
            )}
        </div>
      );
    } else if (this.props.type === "PAST") {
      return (
        <div className="ticket_list">
          <div className="ticket_list--scroll_list" style = { {height : scrollListHeight}}>
            {ticketArray &&
              ticketArray.map((ticketInfo) => (
                <div
                  className="ticket_list--content"
                  id={ticketInfo.t.ReservationId}
                  onClick={() => this.props.onTicketClicked()}
                >
                  <div className="ticket_list--content--left">
                    <div
                      className="ticket_list--content--left--text"
                      style={{ color: Colors.text_grey }}
                    >
                      {ticketInfo.t.AreaName}
                    </div>

                    <div
                      className="ticket_list--content--left--text"
                      style={{ color: Colors.text_grey }}
                    >
                      {ticketInfo.t.LocationName}
                    </div>

                    <div
                      className="ticket_list--content--left--text"
                      style={{ color: Colors.text_grey }}
                    >
                    {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(ticketInfo.t.ReservationTimestamp)}
                    </div>

                    <div
                      className="ticket_list--content--left--text"
                      style={{ color: Colors.text_grey }}
                    >
                    {new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit'}).format(ticketInfo.t.ReservationTimestamp)}
                    </div>
                    
                  </div>

                  

                  <div className="ticket_list--content--right">
                    <div
                      className="ticket_list--content--right--text"
                      style={{ color: Colors.text_grey }}
                    >
                      {ticketInfo.t.Status}
                    </div>
                  </div>

                </div>
              ))}
          </div>

         
        </div>
      );
    }
  }
}

export default TicketList;
