import React, { Component } from "react";
import "./CancelReservationFeedbackPopup.css";
import Checkbox from "react-simple-checkbox";
import TextField, { Input } from "@material/react-text-field";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  feedbackCancelReservationAction,
} from "../../../store/actions/ReservationPostActions";
import { Redirect } from "react-router-dom";


class CancelReservationFeedbackPopup extends Component {
  state = {
    width: window.innerWidth,
    isCheckbox1Ticked: false,
    isCheckbox2Ticked: false,
    isCheckbox3Ticked: false,
    isCheckbox4Ticked: false,
    isCheckbox5Ticked: false,
    isCheckbox6Ticked: false,
    isCheckbox7Ticked: false,
    feedbacks: [],
    otherReason:'',
    isDoneButtonClicked:false,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleCheckboxChanged = (isChecked, index) => {
    this.updateCheckboxTickState(isChecked, index);
    
  };

  updateCheckboxTickState = (isChecked, index) => {
    if (index === 0) {
      this.setState({ isCheckbox1Ticked: isChecked });
    } else if (index === 1) {
      this.setState({ isCheckbox2Ticked: isChecked });
    } else if (index === 2) {
      this.setState({ isCheckbox3Ticked: isChecked });
    } else if (index === 3) {
      this.setState({ isCheckbox4Ticked: isChecked });
    } else if (index === 4) {
      this.setState({ isCheckbox5Ticked: isChecked });
    } else if (index === 5) {
      this.setState({ isCheckbox6Ticked: isChecked });
    } else if (index === 6) {
      this.setState({ isCheckbox7Ticked: isChecked });
    }
  };

  renderSwitch(index) {
    if (index === 0) {
      return this.state.isCheckbox1Ticked;
    } else if (index === 1) {
      return this.state.isCheckbox2Ticked;
    } else if (index === 2) {
      return this.state.isCheckbox3Ticked;
    } else if (index === 3) {
      return this.state.isCheckbox4Ticked;
    } else if (index === 4) {
      return this.state.isCheckbox5Ticked;
    } else if (index === 5) {
      return this.state.isCheckbox6Ticked;
    } else if (index === 6) {
      return this.state.isCheckbox7Ticked;
    }
  }

  handleOtherReasonChanged = (e) => {
    this.setState({ otherReason: e.target.value });
  }

  handleDoneButtonClicked = () => {

    this.setState({isDoneButtonClicked:true});
   
  }

  updateFeedbackList = (reasons) =>{
    if (this.state.isCheckbox1Ticked) {
      this.state.feedbacks.push(reasons[0]);
    } 
    
    if (this.state.isCheckbox2Ticked) {
      this.state.feedbacks.push(reasons[1]);
    } 
    
    if (this.state.isCheckbox3Ticked) {
      this.state.feedbacks.push(reasons[2]);
    }
    
    if (this.state.isCheckbox4Ticked) {
      this.state.feedbacks.push(reasons[3]);
    }
    
    if (this.state.isCheckbox5Ticked) {
      this.state.feedbacks.push(reasons[4]);
    } 
    
    if (this.state.isCheckbox6Ticked) {
      this.state.feedbacks.push(reasons[5]);
    } 

    if(this.state.otherReason.length > 0){
      this.state.feedbacks.push(this.state.otherReason);
    }

    console.log("feedbacks in array : " + this.state.feedbacks);
  
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    var isCheckboxTicked;
    

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      mainContainerTop = "15%";
      mainContainerBottom = "15%";
    } else {
      mainContainerLeft = "38%";
      mainContainerRight = "38%";
      mainContainerTop = "10%";
      mainContainerBottom = "10%";
    }

    const reasons = [
      "Cannot unlock bay",
      "Could not find bay",
      "Hard to use",
      "Someone parked at my spot",
      "Bad location",
      "Just Testing",
    ];

    let routeToRequestParkingPage;

    if(this.state.isDoneButtonClicked){
      this.setState({isDoneButtonClicked:false});

      this.updateFeedbackList(reasons);
      
      //post to server
      this.props.feedbackCancelReservation(this.props.reservationId, this.state.feedbacks);
  
      //route back to request parking page
      routeToRequestParkingPage = <Redirect to='/RequestParking' />;
    }

    if(this.props.show){
      return (
        <div className="cancellation_feedback_popup">
        {routeToRequestParkingPage}
          <div
            className="cancellation_feedback_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
            <div className="cancellation_feedback_popup--title_container">
              <div className="cancellation_feedback_popup--title_container--title">
                Comments:
              </div>
            </div>
  
            <div className="cancellation_feedback_popup--feedback_container">
              {reasons.map((reason, index) => (
                <div className="cancellation_feedback_popup--feedback_container--feedback">
                  <div className="cancellation_feedback_popup--feedback_container--feedback--label">
                    {reason}
                  </div>
                  {(isCheckboxTicked = this.renderSwitch(index))}
                  <Checkbox
                    checked={isCheckboxTicked}
                    onChange={(isChecked) =>
                      this.handleCheckboxChanged(isChecked, index)
                    }
                  />
                </div>
              ))}
  
              <div className="cancellation_feedback_popup--feedback_container--feedback">
                <div className="cancellation_feedback_popup--feedback_container--feedback--label">
                  Other
                </div>
  
                <Checkbox
                  checked={this.state.isCheckbox7Ticked}
                  onChange={(isChecked) =>
                    this.handleCheckboxChanged(isChecked, 6)
                  }
                />
  
              
              </div>
  
              {this.state.isCheckbox7Ticked && (
                <TextField>
                  <Input
                    style={{
                      borderBottom: "1px solid #BBBDC0",
                      width: "100%",
                      padding: "0.5em",
                    }}
                    value={this.state.otherReason}
                    onChange={this.handleOtherReasonChanged}
                  />
                </TextField>
              )}
            </div>
  
            <div
              className="cancellation_feedback_popup--done_button"
              onClick={this.handleDoneButtonClicked}
            >
              <div className="cancellation_feedback_popup--done_button--label">
                Done
              </div>
            </div>
          </div>
        </div>
      );
    }else{
      return null;
    }

    
  }
}

const mapStateToProps = (state) => {
  return {
    activateReservationSuccessResult:
      state.reservationPost.activateReservationSuccessResult,

    activateReservationErrorResult:
      state.reservationPost.activateReservationErrorResult,

    cancelReservationSuccessResult:
      state.reservationPost.cancelReservationSuccessResult,

    cancelReservationErrorResult:
      state.reservationPost.cancelReservationErrorResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    feedbackCancelReservation: (reservationId, feedbacks) =>
      dispatch(feedbackCancelReservationAction(reservationId, feedbacks))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CancelReservationFeedbackPopup);
