import React, { Component } from "react";
import "./SignIn.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  requestSmsVerificationAction,
  resetRequestSmsVerificationStateAction,
} from "../../store/actions/AuthActions";
import { connect } from "react-redux";
import Toolbar from "../layout/toolbar/Toolbar";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import Backdrop from "../layout/backdrop/Backdrop";
import { Redirect } from "react-router-dom";
import LoadingPopup from "../layout/popup/LoadingPopup";
import Colors from "../../constants/Colors";
import OneButtonPopup from "../layout/popup/OneButtonPopup";


class SignIn2 extends Component {
  state = {
    phone: null,
    idToken: null,
    isSignInButtonClicked: false,
    isSideDrawerOpened: false,
    appVerifier: null,
    isDismissErrorPopupButtonClicked: false,
    isAuthErrorPopupShown: false,
    isTimeoutErrorPopupShown: false,
    isSignInErrorPopupShown: false,
    signInResult: null,
    isLoadingPopupShown: false,
  };


 

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.isSideDrawerOpened };
    });
  };

  handleBackdropClicked = () => {
    //no need function form
    //cox dont care the prev state
    //false, always close it
    this.setState({ isSideDrawerOpened: false });
  };

  handleSignInButtonOnClicked = () => {
    console.log(this.state.phone);

    this.setState((prevState) => {
      return { isSignInButtonClicked: !prevState.isSignInButtonClicked };
    });

    
  };

  handleTermsHyperlinkOnClicked = () => {
    var url = "https://www.parkeasy.co/terms";
    var win = window.open(url, "_blank");
    win.focus();
  };

  handleDismissErrorPopupButtonClicked = () => {
    this.setState({ isDismissErrorPopupButtonClicked: true });
    this.setState({ isTimeoutErrorPopupShown: false });
    this.setState({ isAuthErrorPopupShown: false });
    this.setState({ isSignInErrorPopupShown: false });
  };

  render() {
    let backdrop;

    let routeToCodeConfirmationPage;
    let routeToRequestParkingPage;

    let loadingPopup = (
      <LoadingPopup
        show={this.state.isLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Signing in ... "
      />
    );

    let authErrorPopup = (
      <OneButtonPopup
        show={this.state.isAuthErrorPopupShown}
        title="Error"
        message="Failed to sign in, please try again later."
        buttonText="OK, got it."
        onButtonClick={this.handleDismissErrorPopupButtonClicked}
      />
    );

    let signInErrorPopup = (
      <OneButtonPopup
        show={this.state.isSignInErrorPopupShown}
        title="Error"
        message="Sorry, please try again."
        buttonText="OK, got it."
        onButtonClick={this.handleDismissErrorPopupButtonClicked}
      />
    );

    let timeoutErrorPopup = (
      <OneButtonPopup
        show={this.state.timeoutErrorPopup}
        title="Timeout"
        message="Sorry, please try again."
        buttonText="OK, got it."
        onButtonClick={this.handleDismissErrorPopupButtonClicked}
      />
    );

    const {
      requestSmsVerificationSuccessResult,
      requestSmsVerificationErrorResult,
    } = this.props;

   
  

    if (this.state.isSignInButtonClicked) {
      this.setState({ isSignInButtonClicked: false });
      this.setState({ isLoadingPopupShown: true });
      this.setState({isDismissErrorPopupButtonClicked : false});
    
      //post to sinch to create sms verification session
      this.props.requestSmsVerification(this.state.phone);
    }

    if(requestSmsVerificationSuccessResult){

      //dismiss loading popup
      this.setState({ isLoadingPopupShown: false });

      //navigate to code confirmation page
      routeToCodeConfirmationPage = (
        <Redirect
          to={{
            pathname: "/CodeConfirmation",
            state: {
              phoneNumber: this.state.phone,
            }
          }}
        />
      );

      this.props.resetRequestSmsVerificationState();
    }
    
    if(requestSmsVerificationErrorResult){
        //error pop up
        this.setState({ isLoadingPopupShown: false });
        this.setState({ isSignInErrorPopupShown: true });

      this.props.resetRequestSmsVerificationState();
    }


    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

  



    return (
      <div className="sign_in">
        <Toolbar
          title="Sign In"
          leftNavItemType="toggle-button"
          drawerClickHandler={this.handleDrawerToggleClicked}
        />
        <SideDrawer show={this.state.isSideDrawerOpened} />
        {backdrop}

        {routeToCodeConfirmationPage}
        {routeToRequestParkingPage}
       

        {loadingPopup}
        {timeoutErrorPopup}
        {authErrorPopup}
        {signInErrorPopup}

        
        

        <div className="sign_in--label">Enter your phone number:</div>

        <div className="sign_in--phone_number_container">
          <PhoneInput
            international
            defaultCountry="MY"
            placeholder=""
            value={this.state.phone}
            onChange={(phone) => this.setState({ phone })}
          />
        </div>

        <div
          className="sign_in--submit_button"
          onClick={this.handleSignInButtonOnClicked}
        >
          <div className="sign_in--submit_button--label">Submit</div>
        </div>

        <div className="sign_in--tnc">
          <div className="sign_in--tnc--label">
            By signing in, you agree to the
          </div>
          <div
            className="sign_in--tnc--hyperlink"
            onClick={this.handleTermsHyperlinkOnClicked}
          >
            Terms and Conditions
          </div>
        </div>

        <div id="sign-in-button" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
 
    requestSmsVerificationSuccessResult: state.auth.requestSmsVerificationSuccessResult,
    requestSmsVerificationErrorResult: state.auth.requestSmsVerificationErrorResult,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestSmsVerification: (phoneNumber) =>
      dispatch(requestSmsVerificationAction(phoneNumber)),
  
      resetRequestSmsVerificationState: () => dispatch(resetRequestSmsVerificationStateAction()),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn2);
