import React, { Component } from 'react';
import './HourlyTicketParkingSummary.css'
import Toolbar from "../layout/toolbar/Toolbar";
import { Redirect } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import PriceInfoList from '../layout/list/PriceInfoList'
import {msToTime } from '../../utility/DataConverter'
class HourlyTicketParkingSummary extends Component {

  state = {
    isDoneButtonClicked: false,
  };

  ratingChanged = (newRating) => {
    console.log(newRating);
  };

  render() {
   
    //the latest reservation ticket
    const reservationInfo = this.props.location.reservationInfo;

    let routeToRequestParkingPage;

    if (this.state.isDoneButtonClicked) {
      this.setState({ isDoneButtonClicked: false });
      
      routeToRequestParkingPage = (
        <Redirect
          to={{
            pathname: "/RequestParking"
          }}
        />
      );
    }

    //get parking duration
    //end session time - activation time
    var parkingDurationTimestamp = Number(reservationInfo.EndSessionTimestamp) - Number(reservationInfo.ActivationTimestamp);
    console.log('parkingDurationTimestamp : ' + parkingDurationTimestamp);

    return (
      <div className="hourly_parking_summary">
      {routeToRequestParkingPage}
      <Toolbar title="Parking Summary" />

      <div className='hourly_parking_summary--area_name'>
      {reservationInfo.AreaName}
      </div>
      <div className='hourly_parking_summary--zone_name'>
      {reservationInfo.LocationName}
      </div>

      <div className='hourly_parking_summary--parking_duration--title'>
        Parking Duration:
      </div>
      <div className='hourly_parking_summary--parking_duration--time'>
      {msToTime(parkingDurationTimestamp)}
      </div>

      <div className="hourly_parking_summary--price_table">
      <div className="hourly_parking_summary--price_table--title">
        Charges Info :
      </div>

      <PriceInfoList
        isExpandableMode={false}
        reservationInfo={reservationInfo}
        selectedZoneId={reservationInfo.MallId}
        totalCharges={reservationInfo.TotalCharges}
      />
    </div>

      <div className="hourly_parking_summary--button" onClick={()=> this.setState({ isDoneButtonClicked: true })}>
        <div className="hourly_parking_summary--button--label">
          Done
        </div>
      </div>

      <div className='hourly_parking_summary--rating_container'>
    
      <div className='hourly_parking_summary--rating_container--title'>
      Rate your experience!
      </div>

      <ReactStars
      className={'hourly_parking_summary--rating_container--star'}
      count={5}
      onChange={this.ratingChanged}
      half={true}
      size={30}
      color2={"#4A156A"}
      />
    
     
      </div>
     
     

    </div>
    );
  }
}

export default HourlyTicketParkingSummary;