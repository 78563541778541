import React, { Component } from "react";
import "./ReportParkingSuccessPopup.css";
import SequenceAnimator from "react-sequence-animator/SequenceAnimator";

class ReportParkingSuccessPopup extends Component {
  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    } else {
      mainContainerLeft = "25%";
      mainContainerRight = "25%";
      mainContainerTop = "20%";
      mainContainerBottom = "20%";
    }

    if(this.props.show){
      return (
        <div className="report_parking_success_popup">
          <div
            className="report_parking_success_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
            <div className="report_parking_success_popup--title">
              <div className="report_parking_success_popup--title--label">
                Unblocking your bay...
              </div>
            </div>
  
            <div className="report_parking_success_popup--message">
              Please be patient.
            </div>
  
            <div
              className="report_parking_success_popup--bay_signage"
              style={{ backgroundImage: `url(${this.props.baySignage})` }}
            ></div>
  
            <div className="report_parking_success_popup--animation_container">
              <SequenceAnimator duration={3000}>
                <img
                  src={"./assets/unblocking_bay_animation_1.png"}
                  alt="barrier1"
                
                  class="report_parking_success_popup--image--center"
                />
                <img
                  src={"./assets/unblocking_bay_animation_2.png"}
                  alt="barrier2"
                 
                  class="report_parking_success_popup--image--center"
                />
                <img
                  src={"./assets/unblocking_bay_animation_3.png"}
                  alt="barrier3"
                  
                  class="report_parking_success_popup--image--center"
                />
              </SequenceAnimator>
            </div>
  
            <div className="report_parking_success_popup--message">
              You may park your car once the way is clear!
            </div>
  
            <div className="report_parking_success_popup--buttons_container">
              <div className="report_parking_success_popup--buttons_container--cancel_button" onClick={this.props.onCancelButtonClicked}>
                Try again.
              </div>
              <div className="report_parking_success_popup--buttons_container--vertical_separator_line" />
              <div className="report_parking_success_popup--buttons_container--i_have_parked_button" onClick={this.props.onParkedButtonClicked}>
                I've parked!
              </div>
            </div>
          </div>
        </div>
      );
    }else{
      return null;
    }
    
    
  }
}

export default ReportParkingSuccessPopup;
