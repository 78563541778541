import React, { Component } from "react";
import "./AddCredits.css";
import Toolbar from "../layout/toolbar/Toolbar";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import Backdrop from "../layout/backdrop/Backdrop";
import {
  createBillAction,
  resetCreateBillResultStateAction,
  createPaymentIntentAction,
  resetCreatePaymentIntentResultStateAction,
  getSSTRateAction,
  resetGetSSTRateResultStateAction
} from "../../store/actions/PaymentActions.js";

import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import LoadingPopup from "../layout/popup/LoadingPopup";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import Colors from "../../constants/Colors";
import PaymentMethodOptions from "../../constants/PaymentMethodOptions";




import PaymentMethods from "./PaymentMethods";


class AddCredits extends Component {
  state = {
    isBackButtonClicked: false,
    width: window.innerWidth,
    selectedCreditsAmount: "0",
    subtotalAmount:0,
    sstAmountToBeDisplayed:0,
    totalTopupAmount: 0,

    sstRate: 8,

    selectedPaymentMethodOption: null,

    isCreateBillLoadingPopupShown: false,

    isAddCreditSuccessPopupShown: false,
    isAddCreditErrorPopupShown: false,
    isAddCreditLoadingPopupShown: false,
    isChangeOptionButtonClicked: false,
    isPaymentWithCreditCardConfirmed: false,
    isBillplzPaymentResultUrlParamFound: false,

    isTopupAmountSelected: false,

    newWindowUrl: "",

    createBillUrl: "",

    
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  
    this.props.getSSTRate();
    
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleBackdropClicked = () => {
    this.setState({ isSideDrawerOpened: false });
  };

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.sideDrawerOpen };
    });
  };

  handleCreditsAmountButtonClicked = (amount) => {
    console.log("credits amount clicked : ", amount);
  
    this.setState({ selectedCreditsAmount: amount });
    this.setState({isTopupAmountSelected : true});
  };

  handlePayNowButtonClicked = (selectedPaymentMethod) => {
    console.log(
      "handlePayNowButtonClicked --- selectedPaymentMethod: " +
        selectedPaymentMethod
    );
    this.setState({ isAddCreditLoadingPopupShown: true });
    if (selectedPaymentMethod === PaymentMethodOptions.ONLINE_BANKING) {
      this.props.createBill(this.state.totalTopupAmount, "Billplz");
    } else{
      //this.setState({isPaymentWithCreditCardConfirmed:true});
      //post to request payment intent client secret from server
      this.props.createPaymentIntent(
        this.state.totalTopupAmount,
        "Stripe"
      );
    }
  };

  render() {
    //from reducers
    const {
      createBillSuccessResult,
      createBillErrorResult,
      createPaymentIntentSuccessResult,
      createPaymentIntentErrorResult,
      stripe,
      getSSTRateSuccessResult,
      getSSTRateErrorResult,
    } = this.props;

    const { screenWidth } = this.state;
    const isMobile = screenWidth <= 500;

    const creditsAmountRow1 = ["10", "20", "30"];
    const creditsAmountRow2 = ["50", "100", "200"];

    //initial value
    var paymentMethodOptionImage = "/assets/credit_card.png";

    //from Redirect --- data from PaymentMethods page
    var selectedPaymentMethodOptionFromRedirect;
    var selectedPaymentMethodInfoFromRedirect;

    try {
      selectedPaymentMethodOptionFromRedirect =
        this.props.location.state.selectedPaymentMethodOption;
      selectedPaymentMethodInfoFromRedirect =
        this.props.location.state.selectedPaymentMethodInfo;
    } catch (e) {}

    if (selectedPaymentMethodOptionFromRedirect) {
      if (
        selectedPaymentMethodOptionFromRedirect ===
        PaymentMethodOptions.ONLINE_BANKING
      ) {
        paymentMethodOptionImage = "/assets/online_banking.png";
      } else if (
        selectedPaymentMethodOptionFromRedirect ===
        PaymentMethodOptions.CREDIT_CARDS
      ) {
        paymentMethodOptionImage = "/assets/credit_card.png";
      }
    } else {
      //check with local storage
      paymentMethodOptionImage = "/assets/online_banking.png";
      selectedPaymentMethodOptionFromRedirect =
        PaymentMethodOptions.ONLINE_BANKING;
    }

    if (selectedPaymentMethodInfoFromRedirect === PaymentMethodOptions.CREDIT_CARDS) {
      paymentMethodOptionImage =
        selectedPaymentMethodInfoFromRedirect.card_image_url;
      selectedPaymentMethodOptionFromRedirect =
        selectedPaymentMethodInfoFromRedirect.last_4_digits;
    }

    let backdrop;

    let toolbar = (
      <Toolbar
        title="Add Credits"
        leftNavItemType="toggle-button"
        drawerClickHandler={this.handleDrawerToggleClicked}
      />
    );

    let createBillLoadingPopup = (
      <LoadingPopup
        show={this.state.isCreateBillLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Processing payment ... "
      />
    );

    let topupSuccessfulPopup = (
      <OneButtonPopup
        show={this.state.isAddCreditSuccessPopupShown}
        title="TopUp was successful"
        message=""
        buttonText="Ok, got it."
        onButtonClick={() => {
          this.setState({ isAddCreditSuccessPopupShown: false });
        }}
      />
    );

    let topupErrorPopup = (
      <OneButtonPopup
        show={this.state.isAddCreditErrorPopupShown}
        title="Error"
        message=""
        buttonText="Ok, got it."
        onButtonClick={() => {
          this.setState({ isAddCreditErrorPopupShown: false });
        }}
      />
    );

    let newQueryParams = new URLSearchParams(this.props.location.search);
    try{
      if (
        newQueryParams.get("billplz_payment_result").length  > 0 
       && !this.state.isBillplzPaymentResultUrlParamFound
     ) {
       this.setState({ isBillplzPaymentResultUrlParamFound: true });
      let billplzPaymentResult = newQueryParams.get("billplz_payment_result");
       if(billplzPaymentResult === "true"){
          this.setState({isAddCreditSuccessPopupShown:true});

       }else{
          this.setState({isAddCreditErrorPopupShown : true});
       }
       
     }
    }catch(ex){

    }

    try {
      let detectedUrl = window.location.href;
      let params = window.location.pathname;
      //let params = queryString.parse(detectedUrl);
      console.log("detectedUrl : " + detectedUrl);
      console.log("params : " + params);
    } catch (e) {
      console.log("error : " + e);
    }

    var boxBackgroundColor, textColor, boxWidth;

    boxWidth = (screenWidth - 16 * 4) / 3;

    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

    if(getSSTRateSuccessResult){
      if(getSSTRateSuccessResult.Status === "Success"){
        this.setState({sstRate : getSSTRateSuccessResult.Message});
       
      }

      this.props.resetGetSSTRate();
    }

    if(getSSTRateErrorResult){
      this.props.resetGetSSTRate();
    }

    if (createBillSuccessResult) {
      if (createBillSuccessResult.Status === "Success") {
        this.setState({ isCreateBillLoadingPopupShown: false });
        //redirectBillplzPaymentResult?billplz%5Bid%5D=yo0ofkhp&billplz%5Bpaid%5D=false&billplz%5Bpaid_at%5D=&billplz%5Bx_signature%5D=dcc2e3f31df184267c020fff36b91d4ad0dba5096ab077033bec6c2dcb28d8be
        //open billplz redirect url
        var url = createBillSuccessResult.BillplzCreateBillUrl;

        this.setState({ createBillUrl: url });

        var windowpop = window.open(url, "self");
        windowpop.focus();
      } else {
        this.setState({ isCreateBillLoadingPopupShown: false });
      }

      this.props.resetCreateBillResultState();
    }

    if (this.state.createBillUrl.length > 0) {
      this.setState({ createBillUrl: "" });
    }

    if (createBillErrorResult) {
      this.props.resetCreateBillResultState();
    }

    if (createPaymentIntentSuccessResult) {
      this.setState({ isCreateBillLoadingPopupShown: false });
      if (createPaymentIntentSuccessResult.Status === "Success") {
        var paymentIntentClientSecret =
          createPaymentIntentSuccessResult.ClientSecret;

        console.log("paymentIntentClientSecret : " + paymentIntentClientSecret);

          
          stripe.confirmCardPayment(paymentIntentClientSecret, {
            payment_method: selectedPaymentMethodInfoFromRedirect.id,
          })
          .then(function (result) {
            // Handle result.error or result.paymentIntent

            console.log(
              "confirmCardPayment error ---- result.error : " + result.error
            );
            console.log(
              "confirmCardPayment error ---- result.paymentIntent : " +
                result.paymentIntent
            );
          });
       
        
      } else {
        console.log(
          "createPaymentIntentSuccessResult failed : " +
            createPaymentIntentSuccessResult.Message
        );
      }

      this.props.resetCreatePaymentIntentResultState();
    }

    if (createPaymentIntentErrorResult) {
      this.props.resetCreatePaymentIntentResultState();
    }

    let routeToPaymentMethodsPage;
    if (this.state.isChangeOptionButtonClicked) {
      this.setState({ isChangeOptionButtonClicked: false });

     
      
        routeToPaymentMethodsPage = (
          <Redirect
            to={{
              pathname: "/PaymentMethods",
            }}
          />
        );

       
      
     
    }

    
   
    if(this.state.selectedCreditsAmount !== "0" && this.state.isTopupAmountSelected){
      this.setState({isTopupAmountSelected : false});
      let selectedAmountInt = parseInt(this.state.selectedCreditsAmount);
      //console.log('selectedAmountInt : ' + selectedAmountInt);

      this.setState({subtotalAmount:this.state.selectedCreditsAmount});
      //console.log('subtotalAmount : ' + this.state.subtotalAmount);

      console.log("sstRate : " + this.state.sstRate);
      this.setState({sstAmountToBeDisplayed: (selectedAmountInt * (this.state.sstRate)).toFixed(2)});
      //console.log('sstAmountToBeDisplayed : ' + this.state.sstAmountToBeDisplayed);

      this.setState({totalTopupAmount:((selectedAmountInt * (this.state.sstRate)) + selectedAmountInt).toFixed(2)});
      //console.log('totalTopupAmount : ' + this.state.totalTopupAmount);
    }
    

    return (
      <div className="add_credits">
        {toolbar}
        {backdrop}
        {createBillLoadingPopup}
        {routeToPaymentMethodsPage}

        <SideDrawer show={this.state.isSideDrawerOpened} />

        <div className="add_credits--label">
          Select amount of credit(s) to add:
        </div>

        <div className="add_credits--amount_box--container">
          {creditsAmountRow1.map((amount) => (
            <div style={{ color: "transparent" }}>
              {this.state.selectedCreditsAmount === amount
                ? ((boxBackgroundColor = Colors.purple), (textColor = "white"))
                : ((boxBackgroundColor = "white"), (textColor = Colors.purple))}

              <div
                className="add_credits--amount_box"
                id={amount}
                style={{
                  width: boxWidth,
                  backgroundColor: boxBackgroundColor,
                  color: textColor,
                }}
                onClick={() => this.handleCreditsAmountButtonClicked(amount)}
              >
                {amount}
              </div>
            </div>
          ))}
        </div>

        <div className="add_credits--amount_box--container">
          {creditsAmountRow2.map((amount) => (
            <div style={{ color: "transparent" }}>
              {this.state.selectedCreditsAmount === amount
                ? ((boxBackgroundColor = Colors.purple), (textColor = "white"))
                : ((boxBackgroundColor = "white"), (textColor = Colors.purple))}

              <div
                className="add_credits--amount_box"
                id={amount}
                style={{
                  width: boxWidth,
                  backgroundColor: boxBackgroundColor,
                  color: textColor,
                }}
                onClick={() => this.handleCreditsAmountButtonClicked(amount)}
              >
                {amount}
              </div>
            </div>
          ))}
        </div>

        <div className="add_credits--section_line" />

        <div className="add_credits--label">Payment method:</div>

        <div className="add_credits--payment_method--container">
          <div className="add_credits--payment_method--selected_option_container">
            <div
              className="add_credits--payment_method--selected_option--image"
              style={{ backgroundImage: `url(${paymentMethodOptionImage})` }}
            />
            <div className="add_credits--payment_method--selected_option--label">
              {selectedPaymentMethodOptionFromRedirect}
            </div>
          </div>

          <div
          className="add_credits--payment_method--change_option_button"
          onClick={() => {
            this.setState({ isChangeOptionButtonClicked: true });
          }}
        >
          Change option
        </div>
           {/* <div
            className="add_credits--payment_method--change_option_button"
            onClick={() => {
              this.setState({ isChangeOptionButtonClicked: true });
            }}
          >
            Change option
          </div> */}
          
        </div>

        <div className="add_credits--subtotal_amount">
        Subtotal: RM {this.state.subtotalAmount}
      </div>
      <div className="add_credits--sst_amount">
      SST: RM {this.state.sstAmountToBeDisplayed}
    </div>
        <div className="add_credits--total_amount">
          Total: RM {this.state.totalTopupAmount}
        </div>

        <div
          className="add_credits--pay_now_button"
          onClick={() =>
            this.handlePayNowButtonClicked(
              selectedPaymentMethodOptionFromRedirect
            )
          }
        >
          <div className="add_credits--pay_now_button--label">Pay now</div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createBillSuccessResult: state.payment.createBillSuccessResult,
    createBillErrorResult: state.payment.createBillErrorResult,
    createPaymentIntentSuccessResult:
      state.payment.createPaymentIntentSuccessResult,
    createPaymentIntentErrorResult:
      state.payment.createPaymentIntentErrorResult,
      getSSTRateSuccessResult:
      state.payment.getSSTRateSuccessResult,
      getSSTRateErrorResult:
      state.payment.getSSTRateErrorResult
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBill: (transactionAmount, paymentGateway) =>
      dispatch(createBillAction(transactionAmount, paymentGateway)),

    resetCreateBillResultState: () =>
      dispatch(resetCreateBillResultStateAction()),

    createPaymentIntent: (transactionAmount, paymentGateway) =>
      dispatch(createPaymentIntentAction(transactionAmount, paymentGateway)),

    resetCreatePaymentIntentResultState: (transactionAmount, paymentGateway) =>
      dispatch(
        resetCreatePaymentIntentResultStateAction(
          transactionAmount,
          paymentGateway
        )
      ),

      getSSTRate:() =>
      dispatch(getSSTRateAction()),

      resetGetSSTRate:() =>
      dispatch(resetGetSSTRateResultStateAction()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddCredits
);
