import React, { Component } from 'react';
import './NotificationBar.css'


class NotificationBar extends Component {

    state={
        displayMode : 'block'
    }

    async componentDidMount (){
       
        setTimeout(() => {
            this.setState({displayMode : 'none'})
            }, 2000)
    }

    render() {
        return (
            <div className='notification_bar_outer_container' style={{display:this.state.displayMode}}>
                <div className='notification_bar_inner_container' style={{backgroundColor:this.props.barColor}}>
                    <div className='notification_content'>
                        {this.props.message}
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationBar;

