const initState = {
    token: null,
};

const appCheckReducer = (state = initState, action) => {
    switch (action.type) {
        case "APP_CHECK_SUCCESS":
            return {
                ...state,
                token: action.result,
            };
        default:
            return state;
    }
};

export default appCheckReducer;
