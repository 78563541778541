import React, { Component } from "react";
import "./NavigateToEntrancePopup.css";

class NavigateToEntrancePopup extends Component {
  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;
   

    if (isMobile) {
      mainContainerLeft = "10%";
      mainContainerRight = "10%";
      mainContainerTop = "30%";
      mainContainerBottom = "30%";
  
    } else {
      mainContainerLeft = "30%";
      mainContainerRight = "30%";
      mainContainerTop = "28%";
      mainContainerBottom = "28%";
     
    }

    if(this.props.show){
      return (
        <div className="navigate_entrance_popup">
          <div
            className="navigate_entrance_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
            <div className="navigate_entrance_popup--title_container">
              <div className="navigate_entrance_popup--title_container--title">
                Parking Entrance
              </div>
            </div>
  
            
              <div
                className="navigate_entrance_popup--entrance_image"
                style={{
                  backgroundImage: `url(${this.props.reservationInfo.EntranceImageUrl})`,
                }}
              />
           
  
            <div className="navigate_entrance_popup--buttons_container">

              <div className="navigate_entrance_popup--buttons_container--navigate_button" onClick={this.props.onNavigateMeButtonClicked}>
                <div className='navigate_entrance_popup--buttons_container--navigate_button--label'>
                Navigate me
                </div>  
              </div>

              <div className="navigate_entrance_popup--buttons_container--cancel_button" onClick={this.props.onDismissButtonClicked}>
                
              <div className="navigate_entrance_popup--buttons_container--cancel_button--label">
              I know the way
              </div>
             
              </div>
  
            
              

            </div>
          </div>
        </div>
      );
    }else{
      return null;
    }

    
  }
}

export default NavigateToEntrancePopup;
