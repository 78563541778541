const initState = {
  authError: null,
  confirmationResult: null,
  signInSuccessResult: null,
  signInErrorResult: null,
  signUpSuccessResult: null,
  signUpFailedError: null,
  signOutSuccessResult: null,
  signOutErrorResult: null,
  requestSmsVerificationSuccessResult: null,
  requestSmsVerificationErrorResult: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "REQUEST_AUTH_CODE_ERROR":
      console.log("request auth code error", action.error);
      return {
        ...state,
        authError: action.error,
      };

    case "REQUEST_AUTH_CODE_SUCCESS":
      console.log("request auth code success", action.confirmationResult);

      return {
        ...state,
        confirmationResult: action.confirmationResult,
      };

    case "SIGN_IN_ERROR":
      console.log("login error");
      return {
        ...state,
        signInErrorResult: action.error,
      };

    case "SIGN_IN_SUCCESS":
      console.log("login success", action.result);
      return {
        ...state,
        signInSuccessResult: action.result,
      };

    case "SIGN_UP_SUCCESS":
      console.log("signup success", action.result);
      return {
        ...state,
        signUpSuccessResult: action.result,
      };

    case "SIGN_UP_ERROR":
      console.log("signup error", action.error);
      return {
        ...state,
        signUpFailedError: action.error,
      };

    case "SIGN_OUT_SUCCESS":
      console.log("signout success");
      return {
        ...state,
        signOutSuccessResult: action.result,
      };

    case "SIGN_OUT_ERROR":
      console.log("signout error");
      return {
        ...state,
        signOutErrorResult: action.error,
      };

    case "RESET_SIGN_IN_STATE":
      return {
        signInSuccessResult: null,
        signInFailedError: null,
      };

    case "RESET_SIGN_UP_STATE":
      return {
        signUpSuccessResult: null,
        signUpFailedError: null,
      };

    case "RESET_SIGN_OUT_STATE":
      return {
        authError: null,
        confirmationResult: null,
        signInSuccessResult: null,
        signInFailedError: null,
        signUpSuccessResult: null,
        signUpFailedError: null,
        signOutSuccessResult: null,
        signOutErrorResult: null,
      };
    case "RESET_AUTH_CODE_STATE":
      return {
        authError: null,
        confirmationResult: null,
      };

    case "REQUEST_SMS_VERIFICATION_SUCCESS":
      return {
        ...state,
        requestSmsVerificationSuccessResult: action.result,
      };

    case "REQUEST_SMS_VERIFICATION_ERROR":
     
      return {
        ...state,
        requestSmsVerificationErrorResult: action.error,
      };

    case "RESET_REQUEST_SMS_VERIFICATION_STATE":
      return {
        requestSmsVerificationSuccessResult: null,
        requestSmsVerificationErrorResult: null,
      };

     
    default:
      return state;
  }
};

export default authReducer;
