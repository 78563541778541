import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { compose } from "redux";
import { connect } from "react-redux";

import "./MyReservations.css";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import Toolbar from "../layout/toolbar/Toolbar";
import Backdrop from "../layout/backdrop/Backdrop";
import TicketList from "../layout/list/TicketList";
import StorageKeys from "../../constants/LocalStorageKeys";
import LoadingPopup from "../layout/popup/LoadingPopup";
import Colors from "../../constants/Colors";

import { syncReservedTicketsAction } from "../../store/actions/ReservationPostActions";

class MyReservations extends Component {
  state = {
    isReserveButtonClicked: false,
    isDatabaseLoadingPopupShown: true,
    isDatabaseLoaded: false,
    isSideDrawerOpened: false,

    currentPresentTicketToShow: [],
    currentPastTicketToShow: [],

    presentTicketArray: [],
    pastTicketArray: [],

    selectedTicket: null,
  };

  async componentDidMount() {
    

    //post to server to sync tickets
    this.props.syncReservedTickets();

    //set timer for database to loaded
    // setTimeout(() => {
    //   this.setState({ isDatabaseLoaded: true });
    //   this.setState({ isDatabaseLoadingPopupShown: false });
    // }, 3000);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleBackdropClicked = () => {
    this.setState({ isSideDrawerOpened: false });
  };

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.sideDrawerOpen };
    });
  };

  handleReserveBayButtonClicked = () => {
    this.setState({ isReserveButtonClicked: true });
  };

  handleTicketClicked = (selectedTicket) => {
    //invoked by TicketList.js
    
    this.setState({selectedTicket : selectedTicket});
    
  };

  render() {

    const { syncTicketsSuccessResult, syncTicketsErrorResult } = this.props;

    let backdrop;

    let toolbar = (
      <Toolbar
        title="My Reservations"
        leftNavItemType="toggle-button"
        drawerClickHandler={this.handleDrawerToggleClicked}
      />
    );

    let routeToRequestParkingPage, routeToTicketActivationPage, routeToHourlyChargingPage;
  

    var ticketArray = [];
    if (
      (syncTicketsSuccessResult || syncTicketsErrorResult) &&
      !this.state.isDatabaseLoaded
    ) {
      this.setState({ isDatabaseLoaded: true });
      this.setState({ isDatabaseLoadingPopupShown: false });

      //retrieve ticket list from local storage
      ticketArray = reactLocalStorage.getObject(StorageKeys.TICKET_LIST);

      //separate tickets to present and past section
      ticketArray &&
        ticketArray.map((t) => {
          var bookingTimeInMilliseconds = Number(t.ReservationTimestamp);
          var arriveWithinInMilliseconds = Number(t.ArriveWithin);
          const remainingTime =
          bookingTimeInMilliseconds + arriveWithinInMilliseconds - Date.now();

          if ((remainingTime > 0 &&
            (t.Status === "unclaimed" || t.Status === "activated")) 
            || t.Status === "hourly_charging") {
            this.state.presentTicketArray.push({ t });
          } else {
            this.state.pastTicketArray.push({ t });
          }
        });
    }

   


    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

    if (this.state.isReserveButtonClicked) {
      routeToRequestParkingPage = <Redirect to="/RequestParking" />;
    }


    if(this.state.selectedTicket){
      if(this.state.selectedTicket.Status === 'hourly_charging'){
        routeToHourlyChargingPage = <Redirect to={{
          pathname: "/HourlyCharging",
          state: { ticketInfo: this.state.selectedTicket },
        }} />;
      }else if (this.state.selectedTicket.Status === 'unclaimed' || this.state.selectedTicket.Status === 'activated'){
        routeToTicketActivationPage = <Redirect to={{
          pathname: "/ActivateReservation",
          state: { ticketInfo: this.state.selectedTicket },
        }} />;
      }
    }

    return (
      <div className="my_reservations">
        <SideDrawer show={this.state.isSideDrawerOpened} />
        <LoadingPopup
          show={this.state.isDatabaseLoadingPopupShown}
          type="spinningBubbles"
          color={Colors.blue}
          message="Please wait ... "
        />
        {toolbar}
        {backdrop}
        {routeToRequestParkingPage}
        {routeToTicketActivationPage}
        {routeToHourlyChargingPage}

        {this.state.isDatabaseLoaded &&
        this.state.presentTicketArray &&
        this.state.presentTicketArray.length > 0 ? (
          <div className="my_reservations--present">
            <div className="my_reservations--present--title">
              <h2>
                <span>Present Reservations</span>
              </h2>
            </div>
            <TicketList
              tickets={this.state.presentTicketArray}
              type="PRESENT"
              onTicketClicked={this.handleTicketClicked}
            />
          </div>
        ) : (
          <div className="my_reservations--present">
            <div className="my_reservations--present--title">
              <h2>
                <span>Present Reservations</span>
              </h2>
            </div>

            <div className="my_reservations--present--description">
              You haven't made a reservation!
            </div>
            <div
              className="my_reservations--present--reserve_button"
              onClick={this.handleReserveBayButtonClicked}
            >
              Reserve a bay
            </div>
          </div>
        )}

        <div className="my_reservations--past">
          <div className="my_reservations--past--title">
            <h2>
              <span>Past Reservations</span>
            </h2>
          </div>

          <TicketList
            tickets={this.state.pastTicketArray}
            type="PAST"
            onTicketClicked={this.handleTicketClicked}
          />
        </div>

        


      </div>

          
    );
  }
}

const mapStateToProps = (state) => {
  return {
    syncTicketsSuccessResult:
      state.reservationPost.syncReservedTicketsSuccessResult,

    syncTicketsErrorResult:
      state.reservationPost.syncReservedTicketsErrorResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    syncReservedTickets: () => dispatch(syncReservedTicketsAction()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyReservations
);
