import "./PriceInfoList.css";

import React from "react";

const PriceInfoList = (props) => {
 
  var priceInfoArray = [];
  var description = null;
  var tableTitle = null;


  if (props.isExpandableMode) {
    //for zone selected to show in request parking page

    Object.values(props.zonePriceInfo).forEach((pair) => {
      Object.keys(pair).forEach((key) => {
        var priceInfo = pair[key];
        var zoneId = key;

    

        if (props.selectedZoneId === zoneId) {

          //table title
          tableTitle = priceInfo.TableTitle;

          //description text
          description = priceInfo.PromotionalText;

          //table rows
          priceInfoArray.push({[priceInfo.TitleRow1]: priceInfo.ValueRow1});
          priceInfoArray.push({[priceInfo.TitleRow2]: priceInfo.ValueRow2});
          priceInfoArray.push({[priceInfo.TitleRow3]: priceInfo.ValueRow3});
          priceInfoArray.push({[priceInfo.TitleRow4]: priceInfo.ValueRow4});


          
            
         
        }
      });
    });

    return (
      <div className="price_info_main_container">
        {/* case sensitive for "onClick" must use this word, 
              else the onclick wont work */}
        {props.isPriceInfoPanelShown ? (
          <div
            className="price_info_top_container"
            onClick={props.OnCancelIconClick}
          >
            <div className="price_info_title">{tableTitle}</div>
            <div className="cancel_icon" />
          </div>
        ) : (
          <div
            className="price_info_top_container"
            onClick={props.OnExpandIconClick}
          >
            <div className="price_info_title">{tableTitle}</div>
            <div className="expand_icon" />
          </div>
        )}

        {props.isPriceInfoPanelShown && (
          <div className="price_content_container">
            <div className="price_info_description">{description}</div>

            <div className="price_breakdown_container">
              {Object.values(priceInfoArray).map((pair, i) =>
                Object.keys(pair).map(function (key) {
                

                  return (
                    <div className="price_breakdown_row">
                      <div
                        className="price_breakdown_left_column"
                       
                      >
                        {key}
                      </div>
                      <div className="price_breakdown_right_column">
                        {pair[key]}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    //table form only
    //for hourly charging page and hourly ticket parking summary page

    var reservationInfo = props.reservationInfo;


     //table rows
     priceInfoArray.push({[reservationInfo.TitleRow1]: reservationInfo.ValueRow1});
     priceInfoArray.push({[reservationInfo.TitleRow2]: reservationInfo.ValueRow2});
     priceInfoArray.push({[reservationInfo.TitleRow3]: reservationInfo.ValueRow3});
     priceInfoArray.push({[reservationInfo.TitleRow4]: reservationInfo.ValueRow4});

   

    if (props.totalCharges) {
      priceInfoArray.push({
        "Total charges": props.totalCharges + " credits",
      });
    }

    return (
      <div className="price_breakdown_container">
        {Object.values(priceInfoArray).map((pair, i) =>
          Object.keys(pair).map(function (key) {
          

            return (
              <div className="price_breakdown_row">
                <div
                  className="price_breakdown_left_column"
                  
                >
                  {key}
                </div>
                <div className="price_breakdown_right_column">{pair[key]}</div>
              </div>
            );
          })
        )}
      </div>
    );
  }
};

export default PriceInfoList;
