import React, { Component } from "react";
import "./TwoButtonsPopup.css";

class TwoButtonsPopup extends Component {
  state = {
    width: window.innerWidth,
    height : window.innerHeight,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width, height } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if(isMobile){
      mainContainerLeft = '15%';
      mainContainerRight = '15%';
     

    }else{
      mainContainerLeft = '30%';
      mainContainerRight = '30%';
     

    }


    if(height > 600 && height < 700){
      mainContainerTop = "30%";
      mainContainerBottom = "30%";

  }else if(height > 700 && height < 800){
    mainContainerTop = "35%";
    mainContainerBottom = "35%";

  }else if(height > 800){
    mainContainerTop = "30%";
    mainContainerBottom = "30%";

  }else{
    mainContainerTop = "22%";
    mainContainerBottom = "22%";
  }

    if(this.props.show){
      return (
        <div className='two_buttons_popup'>
          <div
            className='two_buttons_popup--main_container'
            style={{ left: mainContainerLeft, right: mainContainerRight, top: mainContainerTop, bottom: mainContainerBottom }}
          >
            <div className='two_buttons_popup--title_container'>
              <div className='two_buttons_popup--title_container--title'>{this.props.title}</div>
            </div>

            <div className='two_buttons_popup--message'>{this.props.message}</div>

            <div className='two_buttons_popup--buttons_container'>
              <div
                className='two_buttons_popup--buttons_container--left_button'
                onClick={this.props.onLeftButtonClick}
              >
                {this.props.leftButtonText}
              </div>
              <div className='two_buttons_popup--buttons_container--vertical_separator_line' />
              <div
                className='two_buttons_popup--buttons_container--right_button'
                onClick={this.props.onRightButtonClick}
              >
                {this.props.rightButtonText}
              </div>
            </div>
          </div>
        </div>
      );
    }else{
      return null;
    }
  
      
    
  }
}

export default TwoButtonsPopup;
