import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux'
import RootReducer from './store/reducers/RootReducer'
import { Provider } from 'react-redux'
import Thunk from 'redux-thunk'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentAPIKeys from './constants/StripePaymentAPIKeys';

import { config as firebaseConfig } from './config/FirebaseConfig';
import {getApp, initializeApp} from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider, getLimitedUseToken} from 'firebase/app-check';


const stripePromise = loadStripe(StripePaymentAPIKeys.PUBLISHABLE_KEY_TEST)

//import {receiveMessageInForegroundAction} from './store/actions/CloudMessagingActions'


//thunk is a middleware, this return store enhancers (reduxFirestore, reactReduxFirebase)
//'compose' method combine multiple store enhancers together
// const store = createStore(RootReducer, 
//     compose(
//         applyMiddleware(Thunk.withExtraArgument({ getFirebase , getFirestore })), //withExtraArgument allow to pass the function to ReservationActions.js
//         reactReduxFirebase(FirebaseConfig), // redux binding for firebase
//         reduxFirestore(FirebaseConfig), // redux bindings for firestore
//     )
// );


const store = createStore(RootReducer, 
    compose(
        applyMiddleware(Thunk),
       
        )
);

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN;
}

// Initialize Firebase
initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(getApp(), {
    provider: new ReCaptchaV3Provider('6LeXmIUqAAAAAEtfsEfNivXM3YlsIYVfrdxbIR6g'),
    isTokenAutoRefreshEnabled: false,
});


getLimitedUseToken(appCheck).then(({ error, internalError, token }) => {
    if (!error && !internalError && token) {
        store.dispatch({ type: 'APP_CHECK_SUCCESS', result: token });
    }
    console.error(error ?? internalError)
}).catch(error => {
    console.error('Failed App Check', error);
});
const rootEl = document.getElementById('root');



let render = () => {
    
    ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            
        <Elements stripe={stripePromise}>
            <App />

        </Elements>
            
           
        </BrowserRouter>
    </Provider>
    , 
    
    rootEl);
}

if(module.hot){
    module.hot.accept('./App', () =>{
        setTimeout(render);
    })
}

 

render();

//receiveMessageInForegroundAction();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
