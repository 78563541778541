import React, { Component } from "react";
import "./LoadingPopup.css";
import ReactLoading from "react-loading";

class LoadingPopup extends Component {
  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;
    var spinnerContainerHeight;
    var spinnerContainerWidth;

    if(isMobile){
      mainContainerLeft = '15%';
      mainContainerRight = '15%';
      mainContainerTop = '40%';
      mainContainerBottom = '40%';
     spinnerContainerHeight = '10%';
     spinnerContainerWidth = '10%';

    }else{
      mainContainerLeft = '30%';
      mainContainerRight = '30%';
      mainContainerTop = '28%';
      mainContainerBottom = '28%';
     spinnerContainerHeight = '20%';
     spinnerContainerWidth = '20%';

    }

      if(this.props.show){
        return (
          <div className='loading_popup'>
            <div
              className='loading_popup--main_container'
              style={{ left: mainContainerLeft, right: mainContainerRight, top: mainContainerTop, bottom: mainContainerBottom }}
            >
              <div className='loading_popup--spinner_container'>
                <ReactLoading
                  type={this.props.type}
                  color={this.props.color}
                  height={spinnerContainerHeight}
                  width={spinnerContainerWidth}
                />
              </div>
  
              <div className='loading_popup--message'>{this.props.message}</div>
            </div>
          </div>
        );
      }else{
        return null;
      }
    
     
    
  }
}

export default LoadingPopup;
