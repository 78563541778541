import React, { Component } from "react";
import "./NewbieFreeCreditsPopup.css";

class NewbieFreeCreditsPopup extends Component {
  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    } else {
      mainContainerLeft = "38%";
      mainContainerRight = "38%";
      mainContainerTop = "20%";
      mainContainerBottom = "20%";
    }

    
    if (this.props.show) {
      return (
        <div className="newbie_free_credits_popup">
          <div
            className="newbie_free_credits_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
            <div className="newbie_free_credits_popup--title">
              <div className="newbie_free_credits_popup--title--label">
                Welcome Newbie!
              </div>
            </div>

            <div className="newbie_free_credits_popup--image_container">
              <div className='newbie_free_credits_popup--image_container--credit_label'>
              4
              </div>
            </div>

            <div className="newbie_free_credits_popup--info">
              Welcome to ParkEasy!
            </div>

            <div className="newbie_free_credits_popup--info">
              Here is free credits to start you off.
            </div>

            
              <div
                className="newbie_free_credits_popup--dismiss_button"
                onClick={this.props.onDismissButtonClicked}
              >
                <div className="newbie_free_credits_popup--dismiss_button--label">
                  Ok, got it!
                </div>
              </div>

             

           
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default NewbieFreeCreditsPopup;
