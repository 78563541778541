import React, { Component } from "react";
import "./FreeCredits.css";
import { connect } from "react-redux";

import Colors from "../../constants/Colors";

import Toolbar from "../layout/toolbar/Toolbar";
import SideDrawer from "../layout/side-drawer/SideDrawer";
import Backdrop from "../layout/backdrop/Backdrop";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import LoadingPopup from "../layout/popup/LoadingPopup";

import {resetClaimPromoCodeStateAction, claimPromoCodeAction} from '../../store/actions/UserActions';

import TextField, { Input } from "@material/react-text-field";

class FreeCredits extends Component {
  state = {
    promoCode: "",
    claimSuccessMessage : '',
    claimErrorMessage : '',
    
    isSideDrawerOpened: false,
    isLoadingPopupShown: false,
    isClaimSuccessPopupShown: false,
    isClaimErrorPopupShown: false,

    
  };

  handleDrawerToggleClicked = () => {
    //better practice - function form
    //pass a function to set state
    this.setState((prevState) => {
      return { isSideDrawerOpened: !prevState.isSideDrawerOpened };
    });
  };

  handleBackdropClicked = () => {
    //no need function form
    //cox dont care the prev state
    //false, always close it
    this.setState({ isSideDrawerOpened: false });
  };

  handleSubmitButtonClicked = () => {
    this.setState({isLoadingPopupShown : true});
    //post to server
    this.props.claimPromoCode(this.state.promoCode);
  };

  handleDismissSuccessPopupButtonClicked = () =>{
      this.setState({isClaimSuccessPopupShown : false});
      this.setState({claimSuccessMessage : ''});
  }

  handleDismissErrorPopupButtonClicked = () => {
    this.setState({isClaimErrorPopupShown : false});
    this.setState({claimErrorMessage : ''});
  }

  render() {

    const {claimPromoCodeSuccessResult, claimPromoCodeErrorResult} = this.props;

    let loadingPopup = (
      <LoadingPopup
        show={this.state.isLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Please wait ... "
      />
    );

    let backdrop;
    if (this.state.isSideDrawerOpened) {
      backdrop = <Backdrop onBackdropClick={this.handleBackdropClicked} />;
    }

    let claimSuccessPopup ;

    let claimErrorPopup   ;



    if(claimPromoCodeSuccessResult){

        this.setState({isLoadingPopupShown : false});

        if(claimPromoCodeSuccessResult.Status === 'Success'){
            this.setState({claimSuccessMessage : claimPromoCodeSuccessResult.Message});
           this.setState({isClaimSuccessPopupShown:true});

           

        }else{
            this.setState({claimErrorMessage : claimPromoCodeSuccessResult.Message});
            this.setState({isClaimErrorPopupShown:true});
           
            
        }


        

        this.props.resetClaimPromoCodeState();
    }

    

   

    if(claimPromoCodeErrorResult){
        this.setState({isLoadingPopupShown : false});
        this.setState({claimErrorMessage : claimPromoCodeSuccessResult.Message});
        this.setState({isClaimErrorPopupShown:true});
        this.props.resetClaimPromoCodeState();
    }

    claimSuccessPopup =  <OneButtonPopup
            show={this.state.isClaimSuccessPopupShown}
            title="Redemption was successful"
            message={this.state.claimSuccessMessage}
            buttonText="Yay!"
            onButtonClick={this.handleDismissSuccessPopupButtonClicked}
            />;

    claimErrorPopup =  <OneButtonPopup
    show={this.state.isClaimErrorPopupShown}
    title="Redemption was failed"
    message={this.state.claimErrorMessage}
    buttonText="Ok, got it."
    onButtonClick={this.handleDismissErrorPopupButtonClicked}
    />;

    return (
      <div className="free_credits">
        <Toolbar
          title="Free Credits"
          leftNavItemType="toggle-button"
          drawerClickHandler={this.handleDrawerToggleClicked}
        />
        <SideDrawer show={this.state.isSideDrawerOpened} />
        {backdrop}
        {loadingPopup}
        {claimSuccessPopup}
        {claimErrorPopup}

        <div className="free_credits--text_field_container">
          <h4 style={{ color: Colors.purple }}>
            Enter your promotional code:
          </h4>
          <TextField>
            <Input
              style={{
                borderRadius: "2px",
                border: "1px solid #BBBDC0",
                width: "100%",
                padding: "0.5em",
              }}
              value={this.state.promoCode}
              onChange={(e) =>
                this.setState({ promoCode: e.currentTarget.value })
              }
            />
          </TextField>
        </div>

        <div
          className="free_credits--submit_button"
          onClick={this.handleSubmitButtonClicked}
        >
          <div className="free_credits--submit_button--label">Submit</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      claimPromoCodeErrorResult: state.user.claimPromoCodeErrorResult,
      claimPromoCodeSuccessResult: state.user.claimPromoCodeSuccessResult,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      claimPromoCode: (promoCode) =>
        dispatch(claimPromoCodeAction(promoCode)),
      resetClaimPromoCodeState: () => dispatch(resetClaimPromoCodeStateAction()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(FreeCredits);
