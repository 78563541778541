const config = {
    apiKey: "AIzaSyBDyF4gtM4ekSPdffbDsZVopivii-L5rdA",
    authDomain: "parkeasy.firebaseapp.com",
    databaseURL: "https://parkeasy.firebaseio.com",
    projectId: "project-8536030961712949141",
    storageBucket: "project-8536030961712949141.appspot.com",
    messagingSenderId: "707546407946",
    appId: "1:707546407946:web:feb714af793e67efe76bf3",
    measurementId: "G-THZ9TCRSJ4"
};

export { config };
