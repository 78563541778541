import RawDatabase from '../../raw-database/LocationProduction.json'

var initState = {
  //locations: RawDatabase.Database,
  locations:[],
  bayAvailableNumber: [],
  priceInfo: [],
  operationInfo: [],
  barrierState: '',
  evChargingState: '',
  getGeneralLocationsErrorResult:null,
  getGeneralLocationsSuccessResult:null,
  getCustomLocationsErrorResult:null,
  getCustomLocationsSuccessResult:null,

};

const RealtimeDatabaseReducer = (state = initState, action) => {
  
  switch (action.type) {

    case 'GET_GENERAL_LOCATION_SUCCESS':
      state.getGeneralLocationsSuccessResult = action.result;
      if(action.result.Database){
        state.locations = action.result.Database;
      }
      //console.log("general locations : " + state.locations);
      return state;

    case 'GET_GENERAL_LOCATION_ERROR':
      state.getGeneralLocationsErrorResult = action.error;
      return state;

    case 'GET_CUSTOM_LOCATION_SUCCESS':
      state.getCustomLocationsSuccessResult = action.result;
      if(action.result.Database){
        state.locations = action.result.Database;
      }
      //console.log("custom locations : " + state.locations);
      return state;

    case 'GET_CUSTOM_LOCATION_ERROR':
      state.getCustomLocationsErrorResult = action.error;
      return state;

    case 'RESET_GET_CUSTOM_LOCATIONS_STATE':
      state.getCustomLocationsErrorResult = null;
      state.getCustomLocationsSuccessResult = null;
      
      return state;

    case 'RESET_GET_GENERAL_LOCATIONS_STATE':
      state.getGeneralLocationsErrorResult = null;
      state.getGeneralLocationsSuccessResult = null;
      
      return state;

    case 'MONITOR_OPERATION_INFO':
      //console.log('monitor operation info', action.operationInfoArray);
      state.operationInfo = action.operationInfoArray;
      return state;

    case 'MONITOR_OPERATION_INFO_ERROR':
      //console.log('monitor operation info ERROR', action.error);
      return state;

    case 'MONITOR_BAY_AVAILABLE_NUMBER':
      //console.log('monitor bay available number', action.bayAvailableNumberArray);
      state.bayAvailableNumber = action.bayAvailableNumberArray;
      return state;

    case 'MONITOR_BAY_AVAILABLE_NUMBER_ERROR':
      //console.log('monitor bay available number ERROR', action.error);
      return state;

    case 'MONITOR_PRICE_INFO':
      console.log('monitor price info', action.priceInfoArray);
      state.priceInfo = action.priceInfoArray;
      return state;

    case 'MONITOR_PRICE_INFO_ERROR':
      //console.log('monitor price info ERROR', action.error);
      return state;


    case 'MONITOR_BARRIER_STATUS':
        state.barrierState = action.barrierState;
      return state;

    case 'MONITOR_EV_CHARGING_STATUS':
        state.evChargingState = action.evChargingState;
      return state;

    default:
      return state;
  }

};

export default RealtimeDatabaseReducer;
