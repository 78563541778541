import React, { Component } from "react";
import "./OneOffTicketParkingSummary.css";
import Toolbar from "../layout/toolbar/Toolbar";
import { Redirect } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

class OneOffTicketParkingSummary extends Component {
  state = {
    isDoneButtonClicked: false,
  };

  

  ratingChanged = (newRating) => {
    console.log(newRating);
  };

  render() {
    //from Redirect
    const reservationInfo = this.props.location.state.reservationInfo;
    let routeToRequestParkingPage;

    if (this.state.isDoneButtonClicked) {
      this.setState({ isDoneButtonClicked: false });
      
      routeToRequestParkingPage = (
        <Redirect
          to={{
            pathname: "/RequestParking",
          }}
        />
      );
    }

    return (
      <div className="one_off_parking_summary">
        {routeToRequestParkingPage}
        <Toolbar title="Parking Summary" />

        <div className='one_off_parking_summary--area_name'>
        {reservationInfo.AreaName}
        </div>
        <div className='one_off_parking_summary--zone_name'>
        {reservationInfo.LocationName}
        </div>


        <div className="one_off_parking_summary--credit_spent">
          Credit Spent
        </div>

        <div className="one_off_parking_summary--credits_container">
          <div className="one_off_parking_summary--credits_container--image" />
          <div className="one_off_parking_summary--credits_container--text" style={{fontSize:"28px" }}>
            {reservationInfo.LocationPrice}
          </div>

          <div
            className="one_off_parking_summary--credits_container--text"
            style={{ marginTop: "1.5em" }}
          >
            credit(s)
          </div>
        </div>

        <div className="one_off_parking_summary--button" onClick={()=> this.setState({ isDoneButtonClicked: true })}>
          <div className="one_off_parking_summary--button--label">
            Done
          </div>
        </div>

        <div className='one_off_parking_summary--rating_container'>
      
        <ReactStars
        className={'one_off_parking_summary--rating_container--star'}
        count={5}
        onChange={this.ratingChanged}
        half={true}
        size={30}
        color2={"#4A156A"}
        />
      
       
        </div>
       
       

      </div>
    );
  }
}

export default OneOffTicketParkingSummary;
