import axios from "axios";
import url from "../../constants/Url";
import { reactLocalStorage } from "reactjs-localstorage";
import StorageKeys from "../../constants/LocalStorageKeys";
import { getDatabase, ref, onValue} from "firebase/database";

/* AWS Database */
export const retrieveGeneralLocationListAction = () => {
  return (dispatch, getState) => {
    //post to parkeasy server
    axios
      .get(url.GET_GENERAL_LOCATION_LIST, { crossDomain: true })
      .then((response) => {
        console.log("GET_GENERAL_LOCATION_LIST : ", response.data);
        const result = response.data;

        dispatch({ type: "GET_GENERAL_LOCATION_SUCCESS", result});
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "GET_GENERAL_LOCATION_ERROR", error });
      });
  };
};

export const retrieveCustomLocationListAction = () => {
  return (dispatch, getState) => {
    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );

    //post to parkeasy server
    axios
      .post(url.GET_CUSTOM_LOCATION_LIST, params, { crossDomain: true })
      .then((response) => {
        console.log("GET_CUSTOM_LOCATION_LIST : ", response.data);
        const result = response.data;

        dispatch({ type: "GET_CUSTOM_LOCATION_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "GET_CUSTOM_LOCATION_ERROR", error });
      });
  };
};

export const resetGetGeneralLocationsResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_GET_GENERAL_LOCATIONS_STATE"});
  };
}

export const resetGetCustomLocationsResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_GET_CUSTOM_LOCATIONS_STATE"});
  };
}

/* Firebase Realtime Database */
export const retrieveOperationStatusAction = (zoneId) => {
  //getFirebase is from declaration in index.js
  //as an object to interact with firebase
  //passed firebaseConfig into the store enhancers in index.js,
  //so getFirebase here know which project to connect to
  return (dispatch, getState) => {
    // make async call to database
    var path = "operation/" + zoneId + "/OperationEntity/Operating";
    const db = getDatabase();
    const operationStatusRef = ref(db, path);

    //var firebase = getFirebase().database().ref(path);

    try {
      //listen for operation status node 
      onValue(operationStatusRef, (snapshot) => {
        var operationStatus = snapshot.val();
        //type and operation status will pass as action object to LocationReducer.js
        dispatch({ type: "RETRIEVE_OPERATION_STATUS", operationStatus });
      });
        
      
    } catch (error) {
      dispatch({ type: "RETRIEVE_OPERATION_STATUS_ERROR", error });
    }
  };
};

export const retrieveBayAvailableNumberAction = (zoneId) => {
  return (dispatch, getState) => {
    // make async call to database
    var path = zoneId + "/TotalAvailableBay/Amount";
    //var firebase = getFirebase().database().ref(path);
    const db = getDatabase();
    const bayAvailableRef = ref(db, path);
    

    try {
      onValue(bayAvailableRef, (snapshot) => {
        //console.log(snapshot.val());
        var bayAvailableNumber = snapshot.val();
        dispatch({ type: "RETRIEVE_BAY_AVAILABLE_NUMBER", bayAvailableNumber });
      });
    } catch (error) {
      dispatch({ type: "RETRIEVE_BAY_AVAILABLE_NUMBER_ERROR", error });
    }
  };
};

export const retrievePriceInfoAction = (zoneId) => {
  return (dispatch, getState) => {
    // make async call to database
    var path = "price/" + zoneId;
    //var firebase = getFirebase().database().ref(path);
    const db = getDatabase();
    const priceInfoRef = ref(db, path);

    try {
      onValue(priceInfoRef, (snapshot) => {
        //console.log(snapshot.val());
        var priceInfo = snapshot.val();
        dispatch({ type: "RETRIEVE_PRICE_INFO", priceInfo });
      });
    } catch (error) {
      dispatch({ type: "RETRIEVE_PRICE_INFO_ERROR", error });
    }
  };
};

export const monitorOperationInfoAction = (allLocations) => {
  var operationInfoArray = [];

  return (dispatch, getState) => {
    allLocations.forEach((location) => {
      location.ZoneList.forEach((zone) => {
        // make async call to database
        var zoneId = zone.LocationId;


        if(zone.ReferenceLocationId.length > 0)
          zoneId = zone.ReferenceLocationId;

        var path = "operation/" + zoneId + "/OperationEntity";
        const db = getDatabase();
        const operationInfoRef = ref(db, path);

        try {
          onValue(operationInfoRef, (snapshot) => {
            //console.log("monitorOperationInfoAction -- Changes caught : " + zoneId + " : " + snapshot.val());
            if (operationInfoArray.length > 0) {

              //replace the value for the same key
              if (
                operationInfoArray.some((item) => 
                  item.hasOwnProperty(zoneId))
              ) {
                Object.values(operationInfoArray).forEach((pair) => {
                  Object.keys(pair).forEach((key) => {
                    var existingZoneId = key;

                    if (existingZoneId === zoneId) {

                       //get the index of current pair
                       const index = operationInfoArray.indexOf(pair);

                       //remove index with splice
                       operationInfoArray.splice(index,1);

                      // //delete existing zone operation info pair key value
                      // operationInfoArray = Object.keys(pair).filter(
                      //   (info) => info !== existingZoneId
                      // );

                      //add new changes of zone operation info pair key value
                      operationInfoArray.push({
                        [zoneId]: snapshot.val(),
                      });
                    }
                  });
                });

                
              } else {
                operationInfoArray.push({ [zoneId]: snapshot.val() });
              }
            } else {
              operationInfoArray.push({ [zoneId]: snapshot.val() });
            }

            var currentIndex_area = allLocations.indexOf(location);
            var currentIndex_zone = location.ZoneList.indexOf(zone);
            //console.log("currentIndex_area : " , currentIndex_area , "currentIndex_zone : ", currentIndex_zone ," --- allLocations length : ", allLocations.length);
            if(currentIndex_area === allLocations.length-1 && 
              currentIndex_zone === location.ZoneList.length-1){
              dispatch({
                type: "MONITOR_OPERATION_INFO",
                operationInfoArray,
              });
            }
           
          });
        } catch (error) {
          dispatch({ type: "MONITOR_OPERATION_INFO_ERROR", error });
        }
      });
    });
  };
};

export const monitorBayAvailableNumberAction = (allLocations) => {
  var bayAvailableNumberArray = [];

  return (dispatch, getState) => {
    allLocations.forEach((location) => {
      location.ZoneList.forEach((zone) => {
        // make async call to database
        var zoneId = zone.LocationId;
      

        if(zone.ReferenceLocationId.length > 0)
            zoneId = zone.ReferenceLocationId;

       

        var path = zoneId + "/TotalAvailableBay/Amount";
        const db = getDatabase();
        const totalAvailableBayRef = ref(db, path);

        try {
          onValue(totalAvailableBayRef, (snapshot) => {
            //console.log("monitorBayAvailableNumberAction -- Changes caught : " + zoneId + " : " + snapshot.val());
            if (bayAvailableNumberArray.length > 0) {
              
              //replace the value for the same key
              if (
                bayAvailableNumberArray.some((item) =>
                  item.hasOwnProperty(zoneId)
                )
              ) {

               
                
                Object.values(bayAvailableNumberArray).forEach((pair) => {
                  Object.keys(pair).forEach((key) => {
                    var existingZoneId = key;
                   // var availableAmount = pair[key];
                    //console.log("existingZoneId : " + existingZoneId + " --- availableAmount : " + availableAmount);

                  
                     
                    if (existingZoneId === zoneId) {
                     
                      //get the index of current pair
                      const index = bayAvailableNumberArray.indexOf(pair);

                      //remove index with splice
                      bayAvailableNumberArray.splice(index,1);

                      //console.log("after remove : " + bayAvailableNumberArray);

                      //add new changes of bay available pair key value
                      bayAvailableNumberArray.push({
                        [zoneId]: snapshot.val(),
                      });
                    }
                  });
                });
              } else {
                
                bayAvailableNumberArray.push({ [zoneId]: snapshot.val() });
              }
            } else {
              
              bayAvailableNumberArray.push({ [zoneId]: snapshot.val() });
            }

            var currentIndex_area = allLocations.indexOf(location);
            var currentIndex_zone = location.ZoneList.indexOf(zone);
            //console.log("currentIndex_area : " , currentIndex_area , "currentIndex_zone : ", currentIndex_zone ," --- allLocations length : ", allLocations.length);
            if(currentIndex_area === allLocations.length-1 && 
              currentIndex_zone === location.ZoneList.length-1){
                dispatch({
                  type: "MONITOR_BAY_AVAILABLE_NUMBER",
                  bayAvailableNumberArray,
                });
            }

            
            
          });
        } catch (error) {
          dispatch({ type: "MONITOR_BAY_AVAILABLE_NUMBER_ERROR", error });
        }
      });
    });
  };
};

export const monitorPriceInfoAction = (allLocations) => {
  //console.log("monitorPriceInfoAction , allLocations size : " + allLocations.length);
  var priceInfoArray = [];
  return (dispatch, getState) => {
    allLocations.forEach((location) => {
      location.ZoneList.forEach((zone) => {
        // make async call to database
        var zoneId = zone.LocationId;
        var path = "price/" + zoneId;
      
        const db = getDatabase();
        const priceInfoRef = ref(db, path);

        //console.log("zone id : " + zoneId);
        //console.log("priceInfoArray size : " + priceInfoArray.length);
        try {
          onValue(priceInfoRef, (snapshot) => {
            //console.log(zoneId + " : " + snapshot.val());
            
            priceInfoArray.push({ [zoneId]: snapshot.val() });
              //replace the value for the same key
              //if (priceInfoArray.some((item) => item.hasOwnProperty(zoneId))) {
                
              //}
              // } else {
              //   priceInfoArray.push({ [zoneId]: snapshot.val() });
              // }
          
            
            
           
          });

          
          var currentIndex_priceInfo = allLocations.indexOf(location);
          //console.log("currentIndex_priceInfo : " + currentIndex_priceInfo);
          if(currentIndex_priceInfo === allLocations.length-1){
            //console.log("priceInfoArray size : " + priceInfoArray.length);
            dispatch({ type: "MONITOR_PRICE_INFO", priceInfoArray });
          }
        } catch (error) {
          dispatch({ type: "MONITOR_PRICE_INFO_ERROR", error });
        }
      });
    });
  };
};

export const monitorBarrierStatusAction = (zoneId, bayId, level) => {
  return (dispatch, getState) => {
    console.log(
      "zoneId--bayId--level : " + zoneId + ", " + bayId + ", " + level
    );
    var path = zoneId + "/BarrierStatus/" + level + "/" + bayId + "/Status";
    const db = getDatabase();
    const barrierStatusRef = ref(db, path);

  
     

    try {
      let listener =  onValue(barrierStatusRef, (snapshot) => {
        const barrierState = snapshot.val();
        console.log(
          zoneId + "-" + bayId + " - barrier status : " + barrierState
        );

        dispatch({
          type: "MONITOR_BARRIER_STATUS",
          barrierState,
        });

        if (barrierState === "up") {
          listener.off();
          //barrierStatusRef.off();
          //firebase.off("value", listener);
          console.log(
            zoneId +
              "-" +
              bayId +
              " - barrier status listener has been removed."
          );
        }
      });
    } catch (error) {
      const barrierState = "x";
      dispatch({ type: "MONITOR_BARRIER_STATUS", barrierState });
    }
  };
};

export const monitorEVChargingStatusAction = (zoneId, bayId, level) => {
  return (dispatch, getState) => {
    console.log(
      "zoneId--bayId--level : " + zoneId + ", " + bayId + ", " + level
    );
    var path = zoneId + "/BarrierStatus/" + level + "/" + bayId + "/IsChargingAllowed";
    const db = getDatabase();
    const evChargingStatusRef = ref(db, path);

  
     

    try {
      let listener =  onValue(evChargingStatusRef, (snapshot) => {
        const evChargingState = snapshot.val();
        console.log(
          zoneId + "-" + bayId + " - ev charging status : " + evChargingState
        );

        dispatch({
          type: "MONITOR_EV_CHARGING_STATUS",
          evChargingState,
        });

        if (evChargingState === "false") {
          // listener.off();
          // console.log(
          //   zoneId +
          //     "-" +
          //     bayId +
          //     " - ev charging status listener has been removed."
          // );
        }
      });
    } catch (error) {
      const evChargingState = "false";
      dispatch({ type: "MONITOR_EV_CHARGING_STATUS", evChargingState });
    }
  };
};
