import axios from "axios";
import url from "../../constants/Url";
import { reactLocalStorage } from "reactjs-localstorage";
import StorageKeys from "../../constants/LocalStorageKeys";

/* 
Billplz --- methods
*/ 
export const resetCreateBillResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_CREATE_BILL_RESULT_STATE"});
  };
};

export const createBillAction = (transactionAmount, paymentGateway) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("transaction_amount", transactionAmount);
    params.append("payment_gateway", paymentGateway);

    //post to parkeasy server
    axios
      .post(url.MAKE_PAYMENT, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        dispatch({ type: "CREATE_BILL_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "CREATE_BILL_ERROR", error });
      });


  };
};

/* 
Stripe --- methods
*/ 

export const resetRetrievePaymentMethodsResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_RETRIEVE_PAYMENT_METHODS_RESULT_STATE"});
  };
};

export const retrievePaymentMethodsAction = () => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    

    //post to parkeasy server
    axios
      .post(url.RETRIEVE_PAYMENT_METHODS, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;



        dispatch({ type: "RETRIEVE_PAYMENT_METHODS_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "RETRIEVE_PAYMENT_METHODS_ERROR", error });
      });


  };
};


//to create stripe payment intent and get client secret by creating a payment intent in server side
export const createPaymentIntentAction = (transactionAmount, paymentGateway) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("transaction_amount", transactionAmount);
    params.append("payment_gateway", paymentGateway);

    //post to parkeasy server
    axios
      .post(url.MAKE_PAYMENT, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;

        //client secret = result.ClientSecret
        dispatch({ type: "CREATE_PAYMENT_INTENT_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "CREATE_PAYMENT_INTENT_ERROR", error });
      });


  };
};

export const resetCreatePaymentIntentResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_CREATE_PAYMENT_INTENT_RESULT_STATE"});
  };
};

 //save card 
 export const saveCardAction = (paymentMethodId) => {
  return (dispatch, getState) => {

    //Set parameters to post
    const params = new URLSearchParams();
    params.append(
      "parkeasy_user_id",
      reactLocalStorage.get(StorageKeys.USER_ID)
    );
    params.append("session_id", reactLocalStorage.get(StorageKeys.SESSION_ID));
    params.append(
      "session_token",
      reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
    );
    params.append("payment_method_id", paymentMethodId);

    //post to parkeasy server
    axios
      .post(url.SAVE_CARD, params, { crossDomain: true })
      .then((response) => {
        console.log(response.data);
        const result = response.data;
        dispatch({ type: "SAVE_CARD_SUCCESS", result });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SAVE_CARD_ERROR", error });
      });


  };
};

export const resetSaveCardResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_SAVE_CARD_RESULT_STATE"});
  };
};

//retrieve SST amount 
export const getSSTRateAction = () =>{
  return (dispatch, getState) =>{
    axios.get(url.GET_SST_RATE, {crossDomain:true})
    .then((response) => {
      console.log(response.data);
      const result = response.data;
      dispatch({ type: "GET_SST_RATE_SUCCESS", result });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: "GET_SST_RATE_ERROR", error });
    });
  };

};

export const resetGetSSTRateResultStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_GET_SST_RATE_RESULT_STATE"});
  };
};
