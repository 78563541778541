import React, { Component } from 'react'

class Feedback extends Component {
    render() {
        return (
            <div>
                Feedback
            </div>
        )
    }
}
export default Feedback;
