import React, { Component } from "react";
import "./Toolbar.css";
import DrawerToggleButton from "../side-drawer/DrawerToggleButton";

class Toolbar extends Component {
  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    var backButtonSize;
    if(isMobile){
      backButtonSize = '20px';
    }else{
      backButtonSize = '30px'
    }
    return (
      <header className="toolbar">
        <div className="toolbar--navigation">
          {this.props.leftNavItemType === "toggle-button" ? (
            <div>
              <DrawerToggleButton click={this.props.drawerClickHandler} />
            </div>
          ) : (
            <div
              className="toolbar-back_button"
              onClick={this.props.toolbarBackButtonOnClick}
              style={{backgroundSize:backButtonSize}}
            />
          )}

          <div className="navigation--logo">
            <a href="/">{this.props.title}</a>
          </div>
          <div
            className="navigation--spacer"
            style={{ display: "invisible" }}
          ></div>
        </div>
      </header>
    );
  }
}

export default Toolbar;
