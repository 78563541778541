import React, { useEffect } from 'react';
import { Route } from 'react-router';
import RequestParking from './components/pages/RequestParking';
import AboutUs from './components/pages/AboutUs';
import AccountSettings from './components/pages/AccountSettings';
import AddCredits from './components/pages/AddCredits';
import FreeCredits from './components/pages/FreeCredits';
import MyReservations from './components/pages/MyReservations';
import Feedback from './components/pages/Feedback';
import Help from './components/pages/Help';
import SignIn from './components/pages/SignIn2';
import CodeConfirmation from './components/pages/CodeConfirmation';
import SignUp from './components/pages/SignUp';
import ActivateReservation from './components/pages/ActivateReservation';
import HourlyChargingReservation from './components/pages/HourlyChargingReservation';
import OneOffTicketParkingSummary from './components/pages/OneOffTicketParkingSummary';
import HourlyTicketParkingSummary from './components/pages/HourlyTicketParkingSummary';
import PaymentMethods from './components/pages/PaymentMethods'

import {reactLocalStorage} from 'reactjs-localstorage';
import StorageKeys from './constants/LocalStorageKeys';

function App() {
    // useEffect = componentDidMount + componentDidUpdate + componentWillUnmount
    useEffect(()=>{
      //reactLocalStorage.set(StorageKeys.IS_LOGGED_IN, false);
      reactLocalStorage.set(StorageKeys.GENERAL_LOCATION_LIST,null);
      reactLocalStorage.set(StorageKeys.CUSTOM_LOCATION_LIST,null);
    }, []);

    return (
       <div style={{height:'100%'}}>

       
        <Route exact path='/' component={RequestParking} />
        <Route path='/SignIn' component={SignIn}/>
        <Route path='/RequestParking/:location_id?' component={RequestParking}/>
        <Route path='/AboutUs' component={AboutUs}/>
        <Route path='/AccountSettings' component={AccountSettings}/>
        <Route path='/AddCredits' component={AddCredits}/>
        <Route path='/FreeCredits' component={FreeCredits}/>
        <Route path='/MyReservations' component={MyReservations}/>
        <Route path='/Feedback' component={Feedback}/>
        <Route path='/Help' component={Help}/>
        <Route path='/CodeConfirmation' component={CodeConfirmation}/>
        <Route path='/SignUp' component={SignUp}/>
        <Route path='/ActivateReservation' component={ActivateReservation}/>
        <Route path='/HourlyCharging' component={HourlyChargingReservation} />
        <Route path='/OneOffTicketParkingSummary' component={OneOffTicketParkingSummary}/>
        <Route path='/HourlyTicketParkingSummary' component={HourlyTicketParkingSummary}/>
        <Route path='/PaymentMethods' component={PaymentMethods} />
      
        

        
        </div>
    );
}

export default App;
