export const msToTime = (t) => {

  const SEC = 1000;
  const MIN = SEC * 60;
  const HOUR = MIN * 60;
  const DAY = HOUR * 24;

  var d = Math.floor(t / DAY),
    h = Math.floor((t - d * DAY) / HOUR),
    m = Math.round((t - d * DAY - h * HOUR) / MIN),
    s = Math.round((t - d * DAY - h * HOUR - m * MIN) / SEC);
    // pad = function (n) {
    //   return n < 10 ? "0" + n : n;
    // };

  

    if(s === 60){
      m++;
      s = 0;
    }

  if (m === 60) {
    h++;
    m = 0;
  }

  if (h === 24) {
    d++;
    h = 0;
  }

  //return [d, pad(h), pad(m), pad(s)].join(' ');
  console.log("day : " + d);
  console.log("hour : " + h);
  console.log("minute : " + m);
  console.log("second : " + s);
  const formattedTime = d + 'd ' + h + 'h ' + m + 'm ' + s + 's';
  return formattedTime;
};
