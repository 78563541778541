import React, { Component } from 'react';
import './SaveCardPopup.css';
import CreditCardInput from 'react-credit-card-input';

import { CardElement } from '@stripe/react-stripe-js';

class SaveCardPopup extends Component {

  state = {
    width: window.innerWidth,
    cardNumber: "",
    expiry:"",
    cvc:"",
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleCardNumberChange = (cardNumber) =>{
    this.setState({cardNumber:cardNumber});
    this.props.cardNumber(cardNumber);
  }

  handleCardExpiryChange = (expiry) =>{
    this.setState({expiry:expiry})
    this.props.cardExpiry(expiry);
  }

  handleCardCVCChange = (cvc) =>{
    this.setState({cvc:cvc})
    this.props.cardCvv(cvc);
  }

  render() {

    const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    var cardNumber;
    var expiry;
    var cvc;

    if (isMobile) {
      mainContainerLeft = "5%";
      mainContainerRight = "5%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    } else {
      mainContainerLeft = "28%";
      mainContainerRight = "28%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    }

    console.log("state card number : ", this.state.cardNumber);
    console.log("local card number : ", cardNumber);

    if (this.props.show) {
    return (
      <div className='save_card_popup'>
        <div className='save_card_popup--main_container'
        style={{
          left: mainContainerLeft,
          right: mainContainerRight,
          top: mainContainerTop,
          bottom: mainContainerBottom,
        }}
        >

          <div className='save_card_popup--title'>
            <div className='save_card_popup--title--label'>
            Input credit card
            </div>
          </div>

          <div className="save_card_popup--credit_card_input--container">
              <CardElement />
            {/* <CreditCardInput
            cardNumberInputProps={{ value: cardNumber, onChange: this.handleCardNumberChange }}
            cardExpiryInputProps={{ value: expiry, onChange:this.handleCardExpiryChange}}
            cardCVCInputProps={{ value: cvc, onChange: this.handleCardCVCChange }}
            fieldClassName="input"
      />*/}
          </div>
          
          

          <div className='save_card_popup--buttons_container--confirm' onClick={this.props.onConfirmButtonClicked}>
            <div className='save_card_popup--buttons_container--confirm--label'>
              Save card
            </div>
          </div>

        <div className='save_card_popup--buttons_container--cancel' onClick={this.props.onCancelButtonClicked}>
          <div className='save_card_popup--buttons_container--cancel--label'>
            Cancel
          </div>
        </div>

        </div>
      </div>
    );
    }else{
      return null;
    }
  }
}

export default SaveCardPopup;