import React, { Component } from "react";
import './SideDrawer.css'
import {reactLocalStorage} from 'reactjs-localstorage';
import StorageKeys from '../../../constants/LocalStorageKeys'
import { compose } from "redux";
import { connect } from "react-redux";
 
import {syncCreditsBalanceAction, resetSyncCreditsBalanceStateAction} from '../../../store/actions/UserActions'



class SideDrawer extends Component {

   componentDidMount = () =>{
    if(reactLocalStorage.get(StorageKeys.IS_LOGGED_IN) === 'true'){
            //sync credits balance from server
        this.props.syncCreditsBalance();
    }
   }

   initializeIsLoggedIn = () =>{
    try{
        if(reactLocalStorage.get(StorageKeys.IS_LOGGED_IN) === 'undefined'){
            reactLocalStorage.set(StorageKeys.IS_LOGGED_IN, false);
        }
       }catch(e){
        reactLocalStorage.set(StorageKeys.IS_LOGGED_IN, false);
       }
   }

   handlePostResult = (successResult, errorResult) => {
    if(successResult){
        if(successResult.Status === 'Success'){
            reactLocalStorage.set(StorageKeys.CREDIT_BALANCE, successResult.Message);
        }
        this.props.resetSyncCreditsBalanceState();
    }

    if(errorResult){
        this.props.resetSyncCreditsBalanceState();
    }
   }

    render() {

        this.initializeIsLoggedIn();

        let drawerClasses = 'side_drawer';
        if(this.props.show){
            drawerClasses = 'side_drawer open';
        }

        const {syncCreditsBalanceErrorResult, syncCreditsBalanceSuccessResult} = this.props;

        

        this.handlePostResult(syncCreditsBalanceSuccessResult,syncCreditsBalanceErrorResult);
               
          
        
            
        
            if(reactLocalStorage.get(StorageKeys.IS_LOGGED_IN) === 'true'){
                //console.log('logged in is true');
        
                
        
                return(
                    <nav className={drawerClasses}>
                        <div className='side_drawer--account_summary_info'>
                            <div className='account_summary_info--greeting' style={{paddingBottom:'1em'}}>
                                 Hello,
                            </div>
                            <div className='account_summary_info--user_name'>
                            {reactLocalStorage.get(StorageKeys.USER_NAME)}
                            </div>
                            <div className='account_summary_info--credit_balance'>
                            {reactLocalStorage.get(StorageKeys.CREDIT_BALANCE)} credit(s)
                            </div>
                        </div>
            
                        <ul>   
                        <li><a href="/">Request Parking</a></li>
                        <li><a href="/MyReservations">My Reservations</a></li>
                        <li><a href="/AddCredits">Add Credits</a></li>
                        <li><a href="/FreeCredits">Free Credits</a></li>
                        <li><a href="/AccountSettings">Account Settings</a></li>
                        <li><a href="/Help">Help</a></li>
                        <li><a href="/AboutUs">About Us</a></li>
                        </ul>
                    </nav>
                );
            }else{
                //console.log('logged in is false');
        
        
                return(
                    <nav className={drawerClasses}>
                        <div className='side_drawer--account_summary_info'>
                            <div className='account_summary_info--greeting'>
                                Hello there!
                            </div>
                        </div>
            
                        <ul>
                        
                        <li><a href="/SignIn">Sign In / Sign Up</a></li>    
                        <li><a href="/">Request Parking</a></li>
                        <li><a href="/Help">Help</a></li>
                        <li><a href="/AboutUs">About Us</a></li>
                        </ul>
                    </nav>
                );
            }
    }
}


const mapStateToProps = (state) => {
    return {
      syncCreditsBalanceErrorResult: state.user.syncCreditsBalanceErrorResult,
      syncCreditsBalanceSuccessResult: state.user.syncCreditsBalanceSuccessResult,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      syncCreditsBalance: () =>
        dispatch(syncCreditsBalanceAction()),
        resetSyncCreditsBalanceState:() =>
        dispatch(resetSyncCreditsBalanceStateAction())
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);

  


