var initState = {
  makeReservationSuccessResult: null,
  makeReservationErrorResult: null,
  activateReservationSuccessResult: null,
  activateReservationErrorResult: null,
  cancelReservationSuccessResult: null,
  cancelReservationErrorResult: null,
  reportParkingSuccessfulSuccessResult: null,
  reportParkingSuccessfulErrorResult: null,
  feedbackCancelReservationSuccessResult: null,
  feedbackCancelReservationErrorResult: null,
  endSessionSuccessResult: null,
  endSessionErrorResult: null,
  syncReservedTicketsSuccessResult: null,
  syncReservedTicketsErrorResult: null,
  changeEVChargingStateSuccessResult: null,
  changeEVChargingStateErrorResult: null
};

const ReservationPostReducer = (state = initState, action) => {
  switch (action.type) {
    case "MAKE_RESERVATION_SUCCESS":
      state.makeReservationSuccessResult = action.result;
      return state;

    case "MAKE_RESERVATION_ERROR":
      state.makeReservationErrorResult = action.error;
      return state;

    case "ACTIVATE_RESERVATION_SUCCESS":
      state.activateReservationSuccessResult = action.result;
      return state;

    case "ACTIVATE_RESERVATION_ERROR":
      state.activateReservationErrorResult = action.error;
      return state;

    case "CANCEL_RESERVATION_SUCCESS":
      state.cancelReservationSuccessResult = action.result;
      return state;

    case "CANCEL_RESERVATION_ERROR":
      state.cancelReservationErrorResult = action.error;
      return state;

    case "REPORT_PARKING_SUCCESSFUL_SUCCESS":
      state.reportParkingSuccessfulSuccessResult = action.result;
      return state;

    case "REPORT_PARKING_SUCCESSFUL_ERROR":
      state.reportParkingSuccessfulErrorResult = action.error;
      return state;

    case "FEEDBACK_CANCEL_RESERVATION_SUCCESS":
      state.feedbackCancelReservationSuccessResult = action.result;
      return state;

    case "FEEDBACK_CANCEL_RESERVATION_ERROR":
      state.feedbackCancelReservationErrorResult = action.error;
      return state;

    case "END_SESSION_SUCCESS":
      state.endSessionSuccessResult = action.result;
      return state;

    case "END_SESSION_ERROR":
      state.endSessionErrorResult = action.error;
      return state;

    case "RESET_MAKE_RESERVATION_STATE":
      state.makeReservationSuccessResult = null;
      state.makeReservationErrorResult = null;
      return state;

      case "RESET_ACTIVATE_RESERVATION_STATE":
        state.activateReservationSuccessResult = null;
        state.activateReservationErrorResult = null;
        return state;

        case "RESET_CANCEL_RESERVATION_STATE":
          state.cancelReservationSuccessResult = null;
          state.cancelReservationErrorResult = null;
          return state;

    case "RESET_END_SESSION_STATE":
      state.endSessionSuccessResult = null;
      state.endSessionErrorResult = null;
      return state;

      case "RESET_CHANGE_EV_CHARGING_STATE":
        state.changeEVChargingStateSuccessResult = null;
        state.changeEVChargingStateErrorResult = null;
        return state;

    case 'SYNC_RESERVED_TICKETS_SUCCESS':
      state.syncReservedTicketsSuccessResult = action.result;
      return state;

    case 'SYNC_RESERVED_TICKETS_ERROR':
      state.syncReservedTicketsErrorResult = action.error;
      return state;

      case 'CHANGE_EV_CHARGING_STATE_SUCCESS':
        state.changeEVChargingStateSuccessResult = action.result;
        return state;
  
      case 'CHANGE_EV_CHARGING_STATE_ERROR':
        state.changeEVChargingStateErrorResult = action.error;
        return state;

    default:
      return state;
  }
};

export default ReservationPostReducer;
