import React, { Component } from "react";
import "./PaymentMethods.css";

import Toolbar from "../layout/toolbar/Toolbar";
import LoadingPopup from "../layout/popup/LoadingPopup";

import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  retrievePaymentMethodsAction,
  resetRetrievePaymentMethodsResultStateAction,
  //saveCardAction,
  //resetSaveCardResultStateAction
} from "../../store/actions/PaymentActions.js";

import PaymentMethodOptions from "../../constants/PaymentMethodOptions";
import Colors from "../../constants/Colors";
import LocalStorageKeys from "../../constants/LocalStorageKeys.js";

import SaveCardPopup from "../../components/layout/popup/SaveCardPopup";
import OneButtonPopup from "../layout/popup/OneButtonPopup";

//import { CardElement } from "@stripe/react-stripe-js";


 


class PaymentMethods extends Component {
  state = {
    isBackButtonClicked: false,
    isSelectButtonClicked: false,
    isAddCardButtonClicked: false,

    isSaveCardPopupShown: false,
    isSaveCardErrorResultPopupShown: false,
    isSaveCardSuccessResultPopupShown: false,
    isSaveCardButtonClicked: false,
    isSaveCardLoadingPopupShown: false,

    isRetrievePaymentMethodsLoadingPopupShown: true,
    isCardSelected: false,
    

    paymentMethodsArray: [],
    recentPaymentMethodId: null,
    selectedPaymentMethod:null,

   
  };

  

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);

    this.props.retrievePaymentMethods();

   
   
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleSelectCardOnClick = (paymentMethod) =>{
    console.log("payment method id " + paymentMethod.id);
    console.log("payment method card image " + paymentMethod.card_image_url);
    console.log("payment method card last 4 digits " + paymentMethod.last_4_digits);
    this.setState({selectedPaymentMethod: paymentMethod});
    this.setState({isCardSelected:true});
  }

  handleSaveCardOnClick = () => {
    this.setState({isSaveCardButtonClicked:true});
    this.setState({isSaveCardPopupShown:false});
  this.setState({isSaveCardLoadingPopupShown:true});
    
  }


  

  render() {
    //from reducers
    const {
      retrievePaymentMethodsSuccessResult,
      retrievePaymentMethodsErrorResult,
      //saveCardSuccessResult,
      //saveCardErrorResult, 
      //stripe, elements
    } = this.props;

   // const cardElement = elements.getElement(CardElement);

    const { width } = this.state;
    const isMobile = width <= 500;

    let backdrop;

    let toolbar = (
      <Toolbar
        title="Payment Methods"
        leftNavItemType="back-button"
        toolbarBackButtonOnClick={() =>
          this.setState({ isBackButtonClicked: true })
        }
      />
    );

    try {
      let detectedUrl = window.location.href;
      let params = window.location.pathname;
      //let params = queryString.parse(detectedUrl);
      console.log("detectedUrl : " + detectedUrl);
      console.log("params : " + params);
    } catch (e) {
      console.log("error : " + e);
    }

    var boxBackgroundColor, textColor, boxWidth;

    if (isMobile) {
      boxWidth = "100px";
    } else {
      boxWidth = "200px";
    }

    //loading pop up
    let retrievePaymentMethodsLoadingPopup = (
      <LoadingPopup
        show={this.state.isRetrievePaymentMethodsLoadingPopupShown}
        type="spinningBubbles"
        color={Colors.blue}
        message="Loading ... "
      />
    );

    let saveCardLoadingPopup = (
      <LoadingPopup
      show={this.state.isSaveCardLoadingPopupShown}
      type="spinningBubbles"
      color={Colors.blue}
      message="Loading ... "
    />
    );

    //back button in toolbar
    let routeToAddCreditsPage;
    if (this.state.isBackButtonClicked) {
      this.setState({ isBackButtonClicked: false });

      routeToAddCreditsPage = (
        <Redirect
          to={{
            pathname: "/AddCredits",
          }}
        />
      );
    }

     //selected card
    if(this.state.isCardSelected){
      this.setState({isCardSelected:false});
      routeToAddCreditsPage = (
        <Redirect
          to={{
            pathname: "/AddCredits",
            state: {selectedPaymentMethodOption:PaymentMethodOptions.CREDIT_CARDS, selectedPaymentMethodInfo:this.state.selectedPaymentMethod }
          }}
        />
      );
    }

    //select online banking button
    if (this.state.isSelectButtonClicked) {
      this.setState({ isSelectButtonClicked: false });
      routeToAddCreditsPage = (
        <Redirect
          to={{
            pathname: "/AddCredits",
            state: { selectedPaymentMethodOption: PaymentMethodOptions.ONLINE_BANKING },
          }}
        />
      );
    }

   

    if (this.state.isAddCardButtonClicked) {
      this.setState({ isAddCardButtonClicked: false });
      this.setState({ isSaveCardPopupShown: true });
     
      
    }

    
    //save card button
    let saveCardPopup = (
      <SaveCardPopup
        show={this.state.isSaveCardPopupShown}
        onConfirmButtonClicked={this.handleSaveCardOnClick}
        onCancelButtonClicked={() =>
          this.setState({ isSaveCardPopupShown: false })
        }
      />
    );
   
    if(this.state.isSaveCardButtonClicked){
      this.setState({isSaveCardButtonClicked: false});
      
      // if(stripe){
      //   const result = stripe.createPaymentMethod({
      //     type: 'card',
      //     card: cardElement,
      //   });
    
      //   // Handle the result and make appropriate API calls
      //   if (result.error) {
      //     // Handle error
      //     console.log("stripe createPaymentMethod error : " + result.error)
      //   } else {
      //     // Payment method successfully created, handle success
      //   }
      // }else{
      //   console.log("stripe is undefined")
      // }
     
      //this.props.saveCard(paymentMethodId);
    }


    //save card error popup
    var saveCardErrorMessage;
    let saveCardErrorPopup = (
      <OneButtonPopup
        show={this.state.isSaveCardErrorResultPopupShown}
        title="Error"
        message={saveCardErrorMessage}
        buttonText="Okay, got it."
        onButtonClick={() => {
          this.setState({ isSaveCardErrorResultPopupShown: false });
        }}
      />
    );

    //save card result --- success
    // if(saveCardSuccessResult){
    //   this.setState({isSaveCardLoadingPopupShown:false});
    //   this.setState({isSaveCardPopupShown: false});
    //   if(saveCardSuccessResult.Status === "Success"){
         


    //   }else{
    //     this.setState({isSaveCardErrorResultPopupShown: true});
    //     saveCardErrorMessage = saveCardSuccessResult.Message;
    //   }

    //   this.props.resetSaveCardResultState();
    // }


    //save card result --- error
    // if(saveCardErrorResult){
    
    //   this.setState({isSaveCardErrorResultPopupShown: true});
    //   this.setState({isSaveCardLoadingPopupShown:false});

    //   saveCardErrorMessage = saveCardErrorResult;
    //   this.props.resetSaveCardResultState();
    // }

    //retrieve payment methods result --- success
    if (retrievePaymentMethodsSuccessResult) {
      if (retrievePaymentMethodsSuccessResult.Status === "Success") {
        this.setState({ isRetrievePaymentMethodsLoadingPopupShown: false });

        var paymentMethodList =
          retrievePaymentMethodsSuccessResult.PaymentMethods;

        //set image url for display
        if (paymentMethodList.length > 0) { 
          paymentMethodList.map((p) => {
            console.log("card brand : " + p.brand);
            if (p.brand === "visa") {
              p.card_image_url = "/assets/card_visa.png";
            } else if (p.brand === "mastercard") {
              p.card_image_url = "/assets/card_master.png";
            } else {
              p.card_image_url = "/assets/credit_card.png";
            }
          });

          this.setState({ paymentMethodsArray: paymentMethodList });

          //set recent payment method to local pref
          try {
            var recentPaymentMethodId =
              retrievePaymentMethodsSuccessResult.PaymentMethodId;

            paymentMethodList.map((p) => {
              if (p.id === recentPaymentMethodId) {
                reactLocalStorage.set(LocalStorageKeys.PaymentMethodId, p.id);
                reactLocalStorage.set(
                  LocalStorageKeys.CardLast4Digits,
                  p.last_4_digits
                );
                reactLocalStorage.set(LocalStorageKeys.CardBrand, p.brand);

                this.setState({ recentPaymentMethodId: p.id });
              }
            });
          } catch (e) {}
        }
      } else {
        this.setState({ isRetrievePaymentMethodsLoadingPopupShown: false });
      }

      this.props.resetRetrievePaymentMethodsResultState();
    }

    //retrieve payment methods result --- error
    if (retrievePaymentMethodsErrorResult) {
      this.setState({ isRetrievePaymentMethodsLoadingPopupShown: false });
      this.props.resetRetrievePaymentMethodsResultState();
    }

   
 

    return (
      <div className="payment_methods">
        {toolbar}
        {backdrop}
        {routeToAddCreditsPage}
       
        {retrievePaymentMethodsLoadingPopup}

         {/* {saveCardPopup}
        {saveCardErrorPopup}
        {saveCardLoadingPopup} */}
        

      

        {/* section --- credit cards */}
        <div className="payment_methods--credit_cards_container">
          <div className="payment_methods--section">
            <div className="payment_methods--section--title_container">
              <div
                className="payment_methods--section--image"
                style={{
                  backgroundSize: "25px",
                  backgroundImage: `url(${"/assets/credit_card.png"})`,
                }}
              ></div>
              <div className="payment_methods--section--label">
                Credit Cards
              </div>
            </div>
          
            {/*<div
            className="payment_methods--section--button"
            
          >
            Add card
            </div>*/}
           {/*<div
              className="payment_methods--section--button"
              onClick={() => this.setState({ isAddCardButtonClicked: true })}
            >
              Add card
              </div>*/}
          </div>

          <div className="payment_methods--scroll_list">
            {this.state.paymentMethodsArray &&
              this.state.paymentMethodsArray.map((paymentMethod) => (
                <div
                  className="payment_methods--card_info_container"
                  id={paymentMethod.id}
                  key={paymentMethod.id}
                  onClick={() => this.handleSelectCardOnClick(paymentMethod)}
                >
                  <div
                    className="payment_methods--card_info_container--image"
                    style={{
                      backgroundImage: `url(${paymentMethod.card_image_url})`,
                    }}
                  />
                  <div className="payment_methods--card_info_container--last_4_digits">
                    {paymentMethod.last_4_digits}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* section --- online banking */}
        <div className="payment_methods--section">
          <div className="payment_methods--section--title_container">
            <div
              className="payment_methods--section--image"
              style={{
                backgroundSize: "29px",
                backgroundImage: `url(${"/assets/online_banking.png"})`,
              }}
            ></div>
            <div className="payment_methods--section--label">
              Online Banking
            </div>
          </div>

          <div
            className="payment_methods--section--button"
            onClick={() => this.setState({ isSelectButtonClicked: true })}
          >
            Select
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    retrievePaymentMethodsSuccessResult:
      state.payment.retrievePaymentMethodsSuccessResult,
    retrievePaymentMethodsErrorResult:
      state.payment.retrievePaymentMethodsErrorResult,
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    retrievePaymentMethods: () => dispatch(retrievePaymentMethodsAction()),

    resetRetrievePaymentMethodsResultState: () =>
      dispatch(resetRetrievePaymentMethodsResultStateAction()),

    //saveCard:(paymentMethodId) => dispatch(saveCardAction(paymentMethodId)),

    //resetSaveCardResultState: () => dispatch(resetSaveCardResultStateAction()),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PaymentMethods
);
