import axios from "axios";
import url from "../../constants/Url";
import { reactLocalStorage } from "reactjs-localstorage";

import StorageKeys from '../../constants/LocalStorageKeys';



export const resetSyncCreditsBalanceStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_SYNC_CREDITS_BALANCE_STATE"});
  };
};

export const syncCreditsBalanceAction = () =>{
  return (dispatch, getState) => {
  const params = new URLSearchParams();
  params.append(
    "parkeasy_user_id",
    reactLocalStorage.get(StorageKeys.USER_ID)
  );
  params.append(
    "session_id",
    reactLocalStorage.get(StorageKeys.SESSION_ID)
  );
  params.append(
    "session_token",
    reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
  );

  //post to parkeasy server
  axios
    .post(url.SYNC_CREDITS_BALANCE, params, { crossDomain: true })
    .then((response) => {
      console.log(response.data);
      const result = response.data;

      
      dispatch({ type: "SYNC_CREDITS_BALANCE_SUCCESS", result });
    })
    .catch((error) => {
     
      dispatch({ type: "SYNC_CREDITS_BALANCE_ERROR", error });
    });

    



  };

 
};

export const resetClaimPromoCodeStateAction = () => {
  return (dispatch, getState) => {
    dispatch( {type: "RESET_CLAIM_PROMO_CODE_STATE"});
  };
};

export const claimPromoCodeAction = (promoCode) =>{
  return (dispatch, getState) => {
  const params = new URLSearchParams();
  params.append(
    "parkeasy_user_id",
    reactLocalStorage.get(StorageKeys.USER_ID)
  );
  params.append(
    "session_id",
    reactLocalStorage.get(StorageKeys.SESSION_ID)
  );
  params.append(
    "session_token",
    reactLocalStorage.get(StorageKeys.SESSION_TOKEN)
  );

  params.append(
    "promo_code",
    promoCode
  );

  //post to parkeasy server
  axios
    .post(url.CLAIM_PROMO_CODE, params, { crossDomain: true })
    .then((response) => {
      console.log(response.data);
      const result = response.data;

      
      dispatch({ type: "CLAIM_PROMO_CODE_SUCCESS", result });
    })
    .catch((error) => {
     
      dispatch({ type: "CLAIM_PROMO_CODE_ERROR", error });
    });

    



  };

 
};