const LocalStorageKeys = {
  IS_LOGGED_IN : 'IsLoggedIn',
  USER_ID : 'UserId',
  USER_NAME : 'UserName',
  SESSION_ID : 'SessionId',
  SESSION_TOKEN : 'SessionToken',
  EMAIL : 'Email',
  PHONE_NUMBER : 'PhoneNumber',
  CREDIT_BALANCE : 'CreditBalance',
  TICKET_LIST : 'TicketList',
  GENERAL_LOCATION_LIST : 'GeneralLocationList',
  CUSTOM_LOCATION_LIST : 'CustomLocationList',
  IS_NEW_USER : 'IsNewUser',
  PREVIOUS_PAGE_PATH : 'PreviousPagePath',
  PAYMENT_METHOD_ID : 'PaymentMethodId',
  CARD_LAST_4_DIGITS : 'CardLast4Digits',
  CARD_BRAND : 'CardBrand'
};

export default LocalStorageKeys;