import React, { Component } from 'react';
import "./ConfirmChargingGunPlugInPopup.css";

export default class ConfirmChargingGunPlugInPopup extends Component {

  state = {
    width: window.innerWidth,
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
     const { width } = this.state;
    const isMobile = width <= 500;

    var mainContainerLeft;
    var mainContainerRight;
    var mainContainerTop;
    var mainContainerBottom;

    if (isMobile) {
      mainContainerLeft = "15%";
      mainContainerRight = "15%";
      mainContainerTop = "25%";
      mainContainerBottom = "25%";
    } else {
      mainContainerLeft = "38%";
      mainContainerRight = "38%";
      mainContainerTop = "20%";
      mainContainerBottom = "20%";
    }

    if (this.props.show) {
      return (
        <div className="confirm_charging_gun_plug_in_popup">
          <div
            className="confirm_charging_gun_plug_in_popup--main_container"
            style={{
              left: mainContainerLeft,
              right: mainContainerRight,
              top: mainContainerTop,
              bottom: mainContainerBottom,
            }}
          >
            <div className="confirm_charging_gun_plug_in_popup--title_container">
              <div className="confirm_charging_gun_plug_in_popup--title_container--title">
                Plug In?
              </div>
            </div>

            <div className="confirm_charging_gun_plug_in_popup--image" />

            <div className="confirm_charging_gun_plug_in_popup--info">
              Please make sure you have plugged in the charging gun.
            </div>


            <div className="confirm_charging_gun_plug_in_popup--buttons_container">
              <div
                className="confirm_charging_gun_plug_in_popup--buttons_container--confirm_button"
                onClick={this.props.onConfirmButtonClicked}
              >
                <div className="confirm_charging_gun_plug_in_popup--buttons_container--confirm_button--label">
                  I've plugged in
                </div>
              </div>

              <div
                className="confirm_charging_gun_plug_in_popup--buttons_container--cancel_button"
                onClick={this.props.onCancelButtonClicked}
              >
                <div className="confirm_charging_gun_plug_in_popup--buttons_container--cancel_button--label">
                  Cancel
                </div>
              </div>

            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  
}
