import React, { Component } from "react";
import "./CodeConfirmation.css";
//import OtpInput from "react-otp-input";
import OtpInput from '@onefifteen-z/react-input-otp';
import Toolbar from "../layout/toolbar/Toolbar";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import OneButtonPopup from "../layout/popup/OneButtonPopup";
import LoadingPopup from "../layout/popup/LoadingPopup";
import StorageKeys from "../../constants/LocalStorageKeys";
import Colors from "../../constants/Colors";

import {
  signInWithSinchAction,
  resetSignInStateAction
} from "../../store/actions/AuthActions";

class CodeConfirmation extends Component {
  state = {
    otp: "",
    isBackButtonClicked: false,
    isSubmitButtonClicked: false,
    isDismissErrorPopupButtonClicked: false,
    isLoadingPopupShown:false,
    isCodeVerified:false,
    isSignInErrorPopupShown:false,
  };

  handleToolbarBackButtonClicked = () => {
    this.setState({ isBackButtonClicked: true });
  };

  handleOtpChange = (smsCode) => {
    this.setState({ otp: smsCode });
    
    console.log(smsCode);
  };

  handleSubmitButtonClicked = () => {
    this.setState((prevState) => {
      return { isSubmitButtonClicked: !prevState.isSubmitButtonClicked };
    });
  };

  handleDismissErrorPopupButtonClicked = () => {
    
    this.setState({isSignInErrorPopupShown:false});

    this.setState((prevState) => {
      return {
        isDismissErrorPopupButtonClicked: !prevState.isDismissErrorPopupButtonClicked,
      };
    });
  };

  
  render() {
    //from <Redirect> props
    const phoneNumber = this.props.location.state.phoneNumber;

    //from auth reducer
    const {  signInSuccessResult, signInErrorResult } = this.props;

    let routeToRequestParkingPage;
    let routeToSignInPage;
    let routeToSignUpPage;

    let   errorLoginPopup = (
      <OneButtonPopup
        show={this.state.isSignInErrorPopupShown}
        title="Error"
        message="Sorry, please try again."
        buttonText="OK, got it."
        onButtonClick={this.handleDismissErrorPopupButtonClicked}
      />
    ); 
    let errorCodeVerificationPopup;
    let loadingPopup =  <LoadingPopup show={this.state.isLoadingPopupShown} type='spinningBubbles' color={Colors.blue} message='Verifying ... ' />

    //this.props.location.state is from <Redirect>
    let toolbar = (
      <Toolbar
        title={phoneNumber}
        leftNavItemType="back-button"
        toolbarBackButtonOnClick={() => this.handleToolbarBackButtonClicked()}
      />
    );

   

    if (this.state.isBackButtonClicked) {
      routeToSignInPage = <Redirect to="/SignIn" />;
    }

    if (this.state.isSubmitButtonClicked) {

      this.setState({isSubmitButtonClicked:false});
     
      //show loading popup
      this.setState({isLoadingPopupShown:true});
        
      //post to sinch , to verify otp
      this.props.signInWithSinch(phoneNumber, this.state.otp);

       
    }

   
      
      if (signInSuccessResult) {

        this.setState({isLoadingPopupShown:false});
       
        if (signInSuccessResult.Status === "Success") {
          if (signInSuccessResult.IsNewUser === "False") {
            //existing user
   
            //save user data to local storage
            reactLocalStorage.set(StorageKeys.IS_LOGGED_IN, true);
            reactLocalStorage.set(
              StorageKeys.USER_ID,
              signInSuccessResult.ParkeasyUserId
            );
            reactLocalStorage.set(StorageKeys.USER_NAME, signInSuccessResult.UserName);
            reactLocalStorage.set(StorageKeys.SESSION_ID, signInSuccessResult.SessionId);
            reactLocalStorage.set(
              StorageKeys.SESSION_TOKEN,
              signInSuccessResult.SessionToken
            );
            reactLocalStorage.set(StorageKeys.EMAIL, signInSuccessResult.Email);
            reactLocalStorage.set(
              StorageKeys.PHONE_NUMBER,
              signInSuccessResult.PhoneNumber
            );
            reactLocalStorage.set(
              StorageKeys.CREDIT_BALANCE,
              signInSuccessResult.CreditBalance
            );
            //redirect to home page
            routeToRequestParkingPage = (
              <Redirect
                to={{
                  pathname: "/RequestParking",
                 
                }}
              />
            );
  
            //update
          } else {
            //new user
            //redirect to account sign up page
            routeToSignUpPage = (
              <Redirect
                to={{
                  pathname: "/SignUp",
                  state: { phoneNumber: phoneNumber},
                }}
              />
            );
          }
        } else {

          //error pop up
          this.setState({isSignInErrorPopupShown:true});
        }
  
        this.props.resetSignInState();
       
      } else {
        reactLocalStorage.set(StorageKeys.IS_LOGGED_IN, false);
        this.props.resetSignInState();
      }


      if(signInErrorResult){
        this.setState({isLoadingPopupShown:false});

        //error pop up
        errorLoginPopup = (
          <OneButtonPopup
            title="Error"
            message="Sorry, please try again."
            buttonText="OK, got it."
            onButtonClick={this.handleDismissErrorPopupButtonClicked}
          />
        );

        this.props.resetSignInState();
      }

    return (
      <div className="code_confirmation">
        {toolbar}
        {routeToSignInPage}
        {routeToRequestParkingPage}
        {routeToSignUpPage}
        {errorLoginPopup}
        {errorCodeVerificationPopup}
        {loadingPopup}

        <div className="code_confirmation--code_container">
          <div className="code_container--label">
            Enter your verification code:
          </div>
          <OtpInput
          value={this.state.otp}
          onChange={(otp) => this.handleOtpChange(otp)}
          otpLength={4}
         
        />
        </div>
        <div
          className="code_confirmation--submit_button_container"
          onClick={() => this.handleSubmitButtonClicked()}
        >
          <div className="submit_button_container--label">Submit</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
 
    signInSuccessResult: state.auth.signInSuccessResult,
    signInErrorResult: state.auth.signInErrorResult,
    


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
    signInWithSinch: (phoneNumber, otp) => 
    dispatch(signInWithSinchAction(phoneNumber,otp)),

    resetSignInState:() => dispatch(resetSignInStateAction()),

    
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeConfirmation);
